import { useState } from "react";
import { useSnapshot } from "valtio";
import { Agmongofile } from "../components/AgFile";
import TaskDatetime from "./task-datetime";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import { EntityV2Store } from "../state/CommonState";
import Utils from "../util/Utils";
let server = process.env.REACT_APP_FILE_SERVER
export default function Taskfiletable1(props) {
    useSnapshot(taskStore)
    const [newLabel, setNewLabel] = useState('')
    const [newInvoiceNo, setNewInvoiceNo] = useState('')
    const [newInvoiceDate, setNewInvoiceDate] = useState('')
    let creditCustomer = EntityV2Store.EntityRec.isCreditCustomer;
    let paymentTerms
    if(creditCustomer === 'Yes'){
    paymentTerms = ['No Credit','As per Credit Facility Agreed','Custom']
    }else{
    paymentTerms = ['No Credit','Custom']
    }
    const [newButtonEnabled, setNewButtonEnabled] = useState(false)
    let task = taskStore.current
    let docs = task.files
    let removeadd = ["Carrier Release Order","AWB Upload","DO Release (Air)","DO Release (Ocean)","BL Draft Approval","BL Upload"]
    if (!docs) docs = []
    return (
        <div>
            <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
                <tr>
                    
                    <th>Document</th>
                    {docs.some(e=>e.label === 'Others')?<th>Charge Description</th>:<th></th>}
                    {docs.some(e=>e.label === 'Others')?<th>Payment Terms</th>:<th></th>}
                    {docs.some(e=>e.label === 'Others'&&e.paymentTerms==='Custom')?<th>Credit (1-45 Days)</th>:<th></th>}
                   {props?.additionalField?<th>Document No</th>:<th></th>} 
                   {props?.additionalField?<th>Document Date</th>:<th></th>}
                   <th>Link</th>
                    <th>Upload</th>
                    {
                        (props.showRejectReason) ?
                            <th>Reject Reason</th>
                            : <></>
                    }
                    {
                        (removeadd.indexOf(task.actionName)==-1)?<th></th>:<></>
                    }
                </tr>
                {
                    docs.map((e, index) => <tr style={{ padding: '0.5rem' }}>
                        {/* {
                        (removeadd.indexOf(task.actionName)==-1)?<td>
                                <p className="control" style={{ padding: '0px' }}>
                                    <button className="button is-success  is-inverted"
                                        onClick={() => {
                                            taskStore.current.files.splice(index+1, 0, {
                                                label: '',
                                                filename: '',
                                                ...(props?.additionalField ? { invoiceNo: '' } : {}), 
                                                ...(props?.additionalField ? { invoiceDate: '' } : {}),
                                                approved: '',
                                                rejectReason: ''
                                            })
                                        }}>
                                        <span className="icon is-small">
                                            <i className="fas fa-plus"></i>
                                        </span>
                                    </button>
                                </p>
                    </td>:<></>
                        } */}


                        {/* <td >{e.label}</td> */}
                        {/* {
                            props.showRejectReason?<td >{e.label}</td>: */}
                        {
                           props?.commercialInvoice?<td>
                            Commercial Invoice
                           </td>:
                           <td>
                           <div className="select">
                           <select value={e.label} onChange={(e)=>{
                               docs[index].label = e.target.value;
                               taskStore.currencyData[index].label = e.target.value;
                           }}>
                           <option value="">Select</option>
                               {['Invoice','Credit Note','Custom Duties & Taxes Invoice','Others'].map((e) =>
                                   <option  value={e}>
                                   {e}
                               </option>
                               )}
                           </select>
                           </div>
                       </td>
                        }
                         {/* } */}
                         {e.label === 'Others'?<td>
                        <input value={e.chargeDescription} type="text" placeholder="Enter Description" className="input is-small" maxLength={30} onChange={(event)=> docs[index].chargeDescription  = event.target.value}/>
                        </td>:<td></td>}
                        {e.label === 'Others'?<td>
                            <div className="select">
                           <select value={e.paymentTerms} onChange={(e)=>{
                               docs[index].paymentTerms = e.target.value;
                               if(e.target.value==='No Credit'||e.target.value==='As per Credit Facility Agreed') docs[index].creditDays=''
                           }}>
                           <option value="">Select</option>
                               {paymentTerms.map((e) =>
                                   <option  value={e}>
                                   {e}
                               </option>
                               )}
                           </select>
                           </div>
                        </td>:<td></td>}
                        {e.label === 'Others' && e.paymentTerms === 'Custom'?<td>
                            
                            <input value={e.creditDays} min={1} max={45}  placeholder="Enter Value 1-45Days"  onChange={(event)=>{
                            if(event.target.value >= 1 &&event.target.value<= 45){
                                docs[index].creditDays = Utils.toNum(event.target.value);
                            }else{
                                docs[index].creditDays =""
                            }
                            }} className="input is-small" />
                           </td>:<td></td>}
                        {props?.additionalField?<td><input placeholder="Enter Invoice No" className={(props?.validation && (e.invoiceNo==undefined || e.invoiceNo.length==0))?"input is-small is-danger":"input is-small"}
                            value={e.invoiceNo} onChange={(e) => {
                                docs[index].invoiceNo = e.target.value
                                setNewInvoiceNo(e.target.value)
                            }} /></td>:<></>}
                        
                        {props?.additionalField?<td>
                            <input placeholder="Enter Invoice Date" type="date" className={(props?.validation && (e.invoiceDate==undefined || e.invoiceDate.length==0))?"input is-small is-danger":"input is-small"}
                            value={e.invoiceDate} onChange={(e) => {
                                docs[index].invoiceDate = e.target.value
                                setNewInvoiceDate(e.target.value)
                            }} /></td>
                        :<></>}
                        <td className={(props?.validation && (e.filename==undefined || e.filename.length==0))?"is-danger-border":""}>
                            {
                                (e.filename) ?<>
                                    <a target='_blank' href={server + '/api/v1/filestore/getfile/' + task.bookingNum + '/' + e.filename}>
                                        {e.filename}
                                    </a>{
                                        (e.approved != 'Approve')?<>&nbsp;<button class="button is-danger" onClick={()=>e.filename=""}><i class="fa fa-trash" aria-hidden="true"></i></button></>:<></>
                                    } </> : <></>
                            }
                        </td>
                        <td id={e.label.replaceAll(" ","")+"___"+index} className={(props?.validation && (e.filename==undefined || e.filename.length==0))?"is-danger-border":""}>
                            {
                                (e.approved !== 'Approve') ?
                                    <Agmongofile disabled={(e.approved === 'Approve') ? true : false} parent={e.label.replaceAll(" ","")+"___"+index}
                                        label={e.label} filekey={task.bookingNum}
                                        callback={(data,id) => {
                                            console.log(id)
                                            docs[id].filename = data.key.filename
                                            // e.filename = data.key.filename
                                            //Taskapi.updateTask(taskStore.current)
                                        }} />
                                    : <></>
                            }
                        </td>
                        {
                            (props.showRejectReason) ?
                                <td>
                                    {
                                        (e.approved === 'Reject') ? e.rejectReason : ''
                                    }
                                </td>
                                : <></>
                        }
                        {
                            (removeadd.indexOf(task.actionName) == -1) ? <td>
                                <p className="control" key={index} style={{ padding: '0px' }}>
                                    {index === taskStore.current.files.length - 1 ? (<><button
                                        className="button is-success is-inverted"
                                        onClick={() => {
                                            if(props?.commercialInvoice){
                                                taskStore.current.files.splice(index + 1, 0, {
                                                    label: 'Commercial Invoice',
                                                    filename: '',
                                                    ...(props?.additionalField ? { invoiceNo: '' } : {}),
                                                    ...(props?.additionalField ? { invoiceDate: '' } : {}),
                                                    approved: '',
                                                    rejectReason: ''
                                                });
                                            }else{
                                                taskStore.currencyData.splice(index+1,0,{
                                                        label: '',
                                                        currencyType: "",
                                                        remarks: "",
                                                        netAmount: 0,
                                                        gstAmount: 0,
                                                        totalAmount: 0,
                                                      })
                                                taskStore.current.files.splice(index + 1, 0, {
                                                    label: '',
                                                    filename: '',
                                                    ...(props?.additionalField ? { invoiceNo: '' } : {}),
                                                    ...(props?.additionalField ? { invoiceDate: '' } : {}),
                                                    approved: '',
                                                    rejectReason: '',
                                                    chargeDescription: '',
                                                    paymentTerms: '',
                                                    creditDays: ''
                                                });
                                            }
                                        }}
                                    >
                                        <span className="icon is-small">
                                            <i className="fas fa-plus"></i>
                                        </span>
                                    </button>
                                    {taskStore.current.files.length !== 1?
                                    <button
                                    className="button is-danger is-inverted"
                                    onClick={() => {
                                        taskStore.current.files.splice(index, 1);
                                        taskStore.currencyData.splice(index, 1);
                                    }}
                                >
                                    <span className="icon is-small">
                                        <i className="fas fa-trash"></i>
                                    </span>
                                </button>:<></>}
                                    </>
                                    ) : (
                                        <button
                                            className="button is-danger is-inverted"
                                            onClick={() => {
                                                taskStore.current.files.splice(index, 1);
                                                taskStore.currencyData.splice(index, 1);
                                            }}
                                        >
                                            <span className="icon is-small">
                                                <i className="fas fa-trash"></i>
                                            </span>
                                        </button>
                                    )}
                                </p>
                            </td> : <></>
                        }
                    </tr>)
                }
                
                {/* {
                        (removeadd.indexOf(task.actionName)==-1)?<tr>
                        <td></td>
                        <td>
                            <input placeholder="Name for new Document" className="input is-small"
                                value={newLabel} onChange={(e) => {
                                    if (e.target.value.length > 0) setNewButtonEnabled(true)
                                    else setNewButtonEnabled(false)
                                    setNewLabel(e.target.value)
                                }} />
                        </td>
                        <td>
                            <button disabled={!newButtonEnabled} className="button is-small islink"
                                onClick={() => {
                                    taskStore.current.files.push({
                                        label: newLabel,
                                        filename: '',
                                        ...(props?.additionalField ? { invoiceNo: '' } : {}), 
                                        ...(props?.additionalField ? { invoiceDate: '' } : {}),
                                        approved: '',
                                        rejectReason: ''
                                    })
                                    setNewLabel('')
                                    setNewButtonEnabled(false)
                                }}>Add Document Type to Table</button>
                        </td>
                    </tr>:<></>
                } */}

            </table>
            <hr />
        </div>
    )
}