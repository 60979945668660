import { useSnapshot } from "valtio"
import { configStore, contractsStore, entityStore, loginStore, bookingsStore, EntityV2Store } from "../state/CommonState"
import { $c, $cg, $cr, AgInput, AgSelect } from '../components/AgComponents'
import EntityApi from "../state/entity-api"
import AguserApi from "../state/AguserApi"
import { useEffect, useRef, useState } from "react"
import Api from "../state/Api"
import { Roles, ServerLists, incoTermsListAirPricing } from "../StaticData"
import stateList from "../static-data/states"
import AgFile, { AgCustomFile } from "../components/AgFile"
import BookingsApi from "../state/bookings-api"
import { incoTermsList, commodityList, hazardousList } from "../StaticData"
import Agswitch, { GenericSwitch } from "../components/ag-switch"
import { Agyesno, Agyesno1 } from "../components/Agyesno"
import BookingConfirmation from "../schedules/booking-confirmation"
import LCLPricingSchedule from "./lcl.schedule"
import { rtpStore } from "./pricing-store"
import AirPricingSchedule from "./air.schedule"
import FCLPricingSchedule from "./fcl.schedule"
import BranchApi from "../state/branch-api"
import EntityV2Api from "../state/entities-api"
import { isArray } from "lodash"
import ShipperApi from "../state/shipper-api"
import { CitySelect, StateSelect } from "../entity-v2/components/Country.js"
import Utils from "../util/Utils.js"
import MblConfirmationPopup from "../enquiryv3/mblConfirmationPopup.js"

export default function Pricingcwf(props) {
    let { hideme, contract } = props
    const [space, setSpace] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [branches, setbranches] = useState([]);
    const [addressObj, setaddressObj] = useState({})
    const [entityData, setentityData] = useState({})
    const [hasToAutoPopulateEntityDetail, setHasToAutoPopulateEntityDetail] = useState(false);
    const [buttonClickedAtleastOnce, setbuttonClickedAtleastOnce] = useState(false);
    const [isButtonClicked, setButtonClicked] = useState(false);
    const [validIIPCertificate, setValidIIPCertificate] = useState(true)
    const [validCommercialInvoice, setValidCommercialInvoice] = useState(true)
    const [validPackingList, setValidPackingList] = useState(true)
    const [cecOc, setCecOc] = useState(null)
    const [cecOd, setCecOd] = useState(null)
    const [newShipper, setNewShipper] = useState(false)
    const [consigneeForm, setConsigneeForm] = useState(true)
    const [shipperSearch, setShipperSearch] = useState('')
    const [EntityList, setEntityList] = useState([])
    const [validbranch, setvalidbranch] = useState(true)
    const [showList, setshowList] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false);
    const [pendingValue, setPendingValue] = useState(false);
    const [validAddress, setvalidAddress] = useState(true)
    const searchListRef = useRef(null);
    const [shipperList, setShipperList] = useState([])
    const [showSearchList, setShowSearchList] = useState(false);
    const [milestoneAlert, setMilestoneAlert] = useState(true)
    const [shipperDetails, setShipperDetails] = useState({_id:"",firstName: "", lastName: "", emailId:"", mobileNumber:"", companyName:"", address:"", pincode:"", country:"", role:"", city: "",state: "",newShipper:false, linkedEntity:""})
    useSnapshot(loginStore)
    useSnapshot(bookingsStore)
    useSnapshot(contractsStore)
    useSnapshot(configStore)
    useSnapshot(entityStore)
    useSnapshot(rtpStore)
let pricingRec
    if (rtpStore.routeSubscript >= 0) pricingRec = rtpStore.records[rtpStore.routeSubscript]
    let brec = bookingsStore.current
    brec.entityId = pricingRec.dummyContract?.entityId
    const aguser = loginStore.userRec.aguserRec
    let pincodeRegex;

    if (shipperDetails.country === "India") {
        pincodeRegex = /^\d{0,6}$/;
    } else {
        pincodeRegex = /^\d{0,10}$/;
    }

    // Function to handle pincode change
    const handlePincodeChange = (e) => {
        const { value } = e.target;
        // Check if pincode matches the regex for the selected country
        if (pincodeRegex.test(value)) {
            setShipperDetails({ ...shipperDetails, pincode: e.target.value })
        }
    };

useEffect(() => {
        rtpStore.doCaseType = 'Direct Master AWB'
        let brec = bookingsStore.current
        brec.entityId = pricingRec?.dummyContract?.entityId
        let entityId = pricingRec.dummyContract.entityId
        // let entityId = "CEN0665"
      BranchApi.getAllBranches(entityId, (data)=>
      {
        // if(loginStore.isClient()){
            
        // }
        console.log('bhn',data);
        if(isArray(data)){
            setbranches(data)
        }

      }
      )
    }, [])
    useEffect(() => {
        brec.originCount = brec.contract.shipmentType.toLowerCase().includes('air') ?
        Utils.extractCodeAndReturnCountry(brec.contract.originAirport)
        : Utils.extractCodeAndReturnCountry(brec.contract.portOfReceipt)
        brec.destinationCount = Utils.extractCodeAndReturnCountry(brec.contract?.finalPlaceOfDelivery)
        setCecOc(brec.country === brec.originCount);
        setCecOd(brec.country === brec.destinationCount);
        setShipperDetails({ ...shipperDetails, country: cecOc ? brec.destinationCount : brec.originCount })
        cecOc && setConsigneeForm(false)
    }, [brec.country, brec.contract.origin, brec.contract.destination, brec.entityId, cecOc, cecOd, brec]);
    useEffect(() => {
        if (shipperDetails.country) {
            const fetchShipperData = async () => {
                try {
                    const res = await ShipperApi.getShipper(brec.entityId, shipperDetails.country);
                    setShipperList(res.data);
                } catch (error) {
                    console.error(error);
                }
            };
            fetchShipperData();
        }
    }, [brec.entityId, shipperDetails.country]);
    useEffect(() => {
        async function setbookingdata () {
        EntityApi.getList()
        if (loginStore.isClient()) {
        brec.firstName = aguser.firstName
        brec.lastName = aguser.lastName
        brec.phone = aguser.phone
        brec.emailid = loginStore.email
        }else{
            brec.emailid = ""
        }
        brec.incoTerms = contractsStore.current.incoTerms
        brec.commodityType = contractsStore.current.commodityType
        brec.carrierMBL = 'No'
        //brec.company = brec.contract.gst
        console.log('GOT COMPANY AS ========', brec.company, entityStore.list.length)
        /*const ent = entityStore.list.filter(e1 => e1.gst === brec.company)
        if (ent.length > 0) {
            let entity = ent[0]
            console.log('entity found =', entity)
            setCompanyName(entity.entityName)
            brec.address = entity.address
            brec.country = entity.country
            brec.pin = entity.pinCode
        } else {
            setCompanyName(brec.contract.gst)
        }*/
        // const entity = entityStore.list.find(e1 => {
        //     return e1.gst === brec.contract.gst
        // })
        // brec.contract.entityId 
        let entityId = pricingRec.dummyContract.entityId
        let entity = await EntityV2Api.viewEntity(entityId, setentityData)
        console.log("entity" , entity);

        if (!entity) {
            // setCompanyName(brec.contract.gst);
            return;
        }
        let fromLoc = brec.contract.shipmentType.toLowerCase().includes('air') ?
            brec.contract.originAirport
            : brec.contract.portOfReceipt
        // let fpodCountry = brec.contract.finalPlaceOfDelivery.substring(brec.contract.portOfReceipt.indexOf(',')+1,brec.contract.portOfReceipt.indexOf("(")).trim();
        console.log("fromLoc" , fromLoc)
        // if (fromLoc.toLowerCase().includes(entity.entityCountry.toLowerCase())) {
            setHasToAutoPopulateEntityDetail(true);
            // brec.company = brec.contract.gst
            brec.company = entity.entityName
            setCompanyName(entity.entityName)
            // brec.branchId=entity.branch[0].branchId
            // brec.address = entity.branch[0].branchAddresses[0].address
            brec.country = entity.branch[0].country
            // brec.pin = entity.branch[0].branchAddresses[0].pincode
            // setaddressObj(JSON.stringify(entity.branch[0].branchAddresses[0]))
        // }
        }
        
    setbookingdata()
    }, [bookingsStore.current])
    const domain = loginStore.email.split('@')[1]
        useEffect(() => {
        const handleOutsideClick = (event) => {
            if (searchListRef.current && !searchListRef.current.contains(event.target)) {
                setShowSearchList(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
        }, []);

    let title = 'Carrier Web Form'
    useSnapshot(contractsStore)

    const entity1 = entityStore.entityRec
    const renderLink = (fileName) => {
        if (fileName && fileName.length > 3) {
            return (
                <a href={process.env.REACT_APP_FILE_SERVER + '/clientfiles/' + fileName} target="_blank">{fileName}</a>
            )
        } else return <></>
    }
    const filecat = '' + Date.now()
    const enableMBL = () => (contractsStore.current.shipmentType.indexOf('FCL') >= 0
        && contractsStore.current.shipmentScope.endsWith('ort')) ? {} : { disabled: 1 }
    let commList = commodityList
    if (brec.contract.hazardous === 'Yes') commList = hazardousList
    const stype = () => brec.contract.shipmentType.toUpperCase().substr(0, 3)

    const handleToggle = (val) => {
        if (val === "Yes") {
            setPendingValue(val);
            setShowConfirm(true);
        } else {
            brec.carrierMBL = val;
        }
    };

    const confirmToggle = () => {
        brec.carrierMBL = pendingValue;
        setShowConfirm(false);
    };

    const cancelToggle = () => {
        brec.carrierMBL = "No"
        setShowConfirm(false);
    };   
  
     
    const setBranchfn = (e)=> {
        brec.branchId = e.target.value
        brec.entityId = brec.contract.entityId
        brec.address=""
        brec.pin = ""
        setaddressObj({})
        setvalidbranch(true)
    }
    const handleSearchChange = (e) => {
        if (e.target.value) { setNewShipper(true) }
        else {
            setNewShipper(false)
            setShipperDetails({ ...shipperDetails, firstName: "", lastName: "", emailId: "", mobileNumber: "", companyName: "", address: "", pincode: "", city: "", state: "", newShipper: false, linkedEntity: "" })
        }
        setShipperSearch(e.target.value);
        setShowSearchList(true);
    };
    const filteredShipperList = shipperList?.filter(shipper =>
        shipper.companyName?.toLowerCase().includes(shipperSearch?.toLowerCase())
    );
    const handleEmailChange = ((e) => {
        setShipperDetails({ ...shipperDetails, emailId: e.target.value })
            getEntity(e.target.value)
            }
        )
        const getEntity = async (email) => {
            const emailRegex = /^[^\s@]+@(?!agraga\.com$)[^\s@]+\.[^\s@]+$/;
            const emailRegexOnlyAgraga = /^[^\s@]+@agraga\.com$/;

        if (emailRegexOnlyAgraga.test(email)) {
            setShipperDetails(prevState => ({ ...prevState, emailId: "" }));
        }
    
            if (!emailRegex.test(email)) {
                return;
            }
    
            try {
    
                      EntityV2Api.GetEntityListWithEmail(email,brec.entityId, (response) => {
                        if(response?.data?.length>0){
                            setEntityList(response?.data)
                            setshowList(true)
                        }else if(response?.errors){
                            setShipperDetails(prevState => ({ ...prevState, emailId: "" }));
                            configStore.setModalMessage(response?.errors)
                        }
                  
                      });
            } catch (error) {
                console.log(error);
            }
        }

       const setAddressFn = (r,i)=> {
        console.log(r,i);
        if(r.target.value !== ""){
            let data = JSON.parse(r.target.value)
            brec.address = data.address
            brec.pin = data.pincode|| data.zipcode
            setaddressObj(r.target.value)
        }
     
        }
        function validEmail(email) {
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailPattern.test(email);
        }
        const delFile = (a,ind)=>{
            let l = []
            for (let i = 0; i < a.length; i++) {
                if(i!=ind){
                    l.push(a[i])
                }
            }
            return l
        }
        const [faberror,setFbaerror] = useState([])
        const onSubmit = () => {
            shipperDetails.companyName = shipperSearch
            shipperDetails.newShipper =  newShipper
            setbuttonClickedAtleastOnce(true)
             setButtonClicked(true);
             let hasError = false
             if (((cecOd && !cecOc) || (!cecOd && !cecOc)) && !shipperDetails.companyName) {
                hasError = true
                setButtonClicked(false)
               }
            if(brec.commercialInvoice_filename_list.length>0 && brec.packingList_filename_list.length==0){
                if(validPackingList){
                    setValidPackingList(false)
                }
                setButtonClicked(false);
                return;
            }else{
                if(!validPackingList){
                    setValidPackingList(true)
                }
            }
            if(brec.commercialInvoice_filename_list.length==0 && brec.packingList_filename_list.length>0){
                if(validCommercialInvoice){
                    setValidCommercialInvoice(false)
                }
                setButtonClicked(false);
                return;
            }else{
                if(!validCommercialInvoice){
                    setValidCommercialInvoice(true)
                }
            }
             if (shipperDetails.companyName){
                if (!shipperDetails.address || !shipperDetails.city || !shipperDetails.companyName || !shipperDetails.country || !validEmail(shipperDetails.emailId) || !shipperDetails.firstName || !shipperDetails.state || !shipperDetails.pincode || !(shipperDetails.mobileNumber?.length >= 6 && shipperDetails.mobileNumber?.length <= 15)){
                    configStore.setModalMessage("Shipper / Container details should be filled completely or remove shipper name")
                    hasError = true
                    setButtonClicked(false)
                }
            }
             if (!brec.incoTerms || brec.incoTerms.length < 1) {
                 hasError = true
             } 
             if (!brec.commodityType || brec.commodityType.length < 1) {
                 hasError = true
             } 
             if (!brec.phone || brec.phone.length < 7) {
                 hasError = true
             } 
             if (brec.contract.hazardous === 'Yes') {
                 if (!brec.iipcert_filename || brec.iipcert_filename.length < 1) {
                     setValidIIPCertificate(false)
                     hasError = true
                 }else{
                     setValidIIPCertificate(true)
                 }
             }
             if (brec.contract.hazardous === 'Yes' || brec.contract.temperatureControlled === 'Yes') {
             if (!brec.packingList_filename_list || brec.packingList_filename_list.length < 1) {
                setValidPackingList(false)
                hasError = true
            }else{
                setValidPackingList(true)
            }
            if (!brec.commercialInvoice_filename_list || brec.commercialInvoice_filename_list.length < 1) {
                setValidCommercialInvoice(false)
                hasError = true
            }else{
                setValidCommercialInvoice(true)
            }
        }
             if (!brec.branchId || brec.branchId.length < 1) {
                setvalidbranch(false)
                 hasError= true
            
             }  else { setvalidbranch(true) }
             if (!brec.address || brec.address.length < 1) {
                setvalidAddress(false);
                 hasError= true
             } else { setvalidAddress(true) }
         
             if (!brec.firstName || brec.firstName.length < 1) {
                hasError= true
             }
             if (!brec.emailid || brec.emailid.length < 1) {
                hasError= true 
             }
             brec.sendMilestoneAlert = milestoneAlert
           
             if (!brec.company || brec.company.length < 1 
                 // || !brec.address || !brec.pin || !brec.country
             ) {
                hasError= true 
             }
             if (!brec.country || brec.country.length < 1 ) {
                hasError= true 
             }  
              if (!brec.pin || brec.pin.length < 1 ) {
                 // configStore.setModalMessage('Please enter all company details')
                //  if(entityData.entityCountry.toLowerCase() === 'india'){
                //      configStore.setModalMessage('Please Enter PinCode')
                //  }else {
                //      configStore.setModalMessage('Please Enter ZipCode')
                //  }
                hasError= true 
              }
              if (cecOc){
                shipperDetails.role = "consignee"
                brec.consignee = shipperSearch
                brec.shipper = brec.company
            }else{
                shipperDetails.role = "shipper"
                brec.shipper = shipperSearch
                cecOd?brec.consignee = brec.company: brec.consignee = ""
    
            }
            if(brec.fba=='Yes'){
                let e = []
                if(brec?.merchantID?.length>14){
                    configStore.setModalMessage(`Merchant ID 14 characters Only.`);
                    e.push("merchantID")
                    hasError = true;
                }
                for (let i = 0; i < brec?.fbaDetails.length; i++) {
                    if(brec?.fbaDetails[i].fbaID?.length>12){
                        configStore.setModalMessage(`FBA ID Maximum 12 characters Only.`);
                        e.push("fbaID"+i)
                        hasError = true;
                    }
                    if(brec?.fbaDetails[i].purchaseOrderID?.length>8){
                        configStore.setModalMessage(`Amazon Reference ID Maximum 8 characters Only.`);
                        e.push("purchaseOrderID"+i)
                        hasError = true;
                    }
                }
                if(JSON.stringify(e)!=JSON.stringify(faberror)){
                    setFbaerror(e)
                }
                if(hasError){
                    setButtonClicked(false);
                    return;
                }
            }
            let filteredCharges = bookingsStore.current.contract.chargesList.filter(e => e.isIncluded == 'Yes')
            if (pricingRec &&
                pricingRec.doCaseType == 1 &&
                pricingRec.hasHawb &&
                pricingRec.hasAgent &&
                rtpStore.doCaseType == 'Direct Master AWB') {   
                bookingsStore.current.contract.quoteValue = bookingsStore.current.contract.quoteValue - pricingRec.hawbAmt - pricingRec.agentAmt
                bookingsStore.current.contract.quoteValueDeduction = 0
                filteredCharges = filteredCharges.filter(e => e.chargeDescription != pricingRec.hawbChargeHead && e.chargeDescription != pricingRec.agentChargeHead)
            } else {
                bookingsStore.current.contract.quoteValueDeduction = 0
            }
            if (brec.contract.oih) {
                filteredCharges = filteredCharges.filter(e => e.chargeDescription != 'Origin Inland Haulage')
                brec.contract.quoteValue -= brec.contract.oih
                brec.contract.oih = 0
            }
            if (hasError) {
                setButtonClicked(false);
                return
            }
            console.log("jukn" ,bookingsStore.current.contract?.entityId , bookingsStore.current?.entityId , bookingsStore.current.contract?.entityId?.length === 0 && bookingsStore.current?.entityId?.length > 0 )
            if((bookingsStore.current.contract?.entityId?.length === 0 ||bookingsStore.current.contract?.entityId === undefined )&& bookingsStore.current?.entityId?.length > 0 ){
                bookingsStore.current.contract.entityId =  bookingsStore.current.entityId
            }
            if (pricingRec.oih && rtpStore.shipperpda == ' ') {
                let pdatc = `Origin Inland Charges from ${pricingRec.por} to ${pricingRec.pol} are on Shipper's account`
                if (pricingRec.oihScac != 'PRIVATE' && pricingRec.oihScac != 'CONCUR') pdatc += ', subject to Carrier Approval'
                bookingsStore.current.contract.tc.push(pdatc)
            }

            bookingsStore.current.contract.chargesList = [...filteredCharges]
            const callback = (d) =>{
                let url = window.location.href
                let toUsers = Utils.getRespectiveMember(EntityV2Store.EntityRec.customer)
                let rec = contractsStore.current
                let CustomerName = EntityV2Store.EntityRec.entityName
                let shipType = rec.shipmentType
                let shipScope = rec.shipmentScope
                let origin = rec.origin
                let destination = rec.destination
                let from = `Agraga <${process.env.REACT_APP_SUPPORTMAIL_ID}>`
                let to = toUsers
                if (url.indexOf('booking.agraga.com') >= 0) {
                    to = to + ',sanjeev.gupta@agraga.com'
                }
                let subject = 'Booking - ' + configStore.bookingModalNum
                let message = `Hi Team,<br />
                <br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; We have received a booking from ${CustomerName}.<br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;     Booking Number: ${d._id}.<br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;    Shipment Type: ${shipType}.<br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;    Scope :${shipScope}.<br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;    Origin: ${origin}.<br />
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;     Destination: ${destination}.</p>
                <br />
                Team Agraga</p>`
                Api.sendClientMailwithoutMessage(from, to, subject, message)
            }
            let role =[]
            //Ocean
            const entityDetails = contractsStore.current
            if(entityDetails.shipmentType !== 'Air' && cecOc){
                role.push('Ocean export Coordination')
            }
            if(entityDetails.shipmentType !== 'Air' && cecOd){
                role.push('Ocean Import Coordination')
            }
            if(entityDetails.shipmentType !== 'Air' && !cecOc && !cecOd ){
                role.push('Ocean export Coordination')
                role.push('Ocean Import Coordination')
            }
            //Air
            if(entityDetails.shipmentType === 'Air' && cecOc){
                role.push('Air export Coordination')
            }
            if(entityDetails.shipmentType === 'Air' && cecOd){
                role.push('Air Import Coordination')
            }
            if(entityDetails.shipmentType === 'Air' && !cecOc && !cecOd ){
                role.push('Air export Coordination')
                role.push('Air Import Coordination')
            }
            shipperDetails.toUsers = role
            BookingsApi.saveBooking(shipperDetails,callback)
            hideme(false)
        }

        

        function checkSelectedBranch(){
            let selectedbranch = entityData.branch.filter((r,i)=>r.branchId === brec.branchId)
            return selectedbranch[0]
         //  entityData.branch
         }
    // bre.schedule is passed to LCLPricingSchedule as the schedule selected is set into
    // the bookingstore.current.schedule variable when clicking on Proceed to booking on
    // lcl.schedule.js
    if(contractsStore.current.isFBA!=undefined && contractsStore.current.isFBA==true && ((contractsStore.current.fba!=undefined && contractsStore.current.fba=='No') || contractsStore.current.fba==undefined)){
        contractsStore.current.fba = 'Yes'
    }
    if(contractsStore.current.fba!=undefined && (brec.fba==undefined || contractsStore.current.fba!=brec.fba)){
        brec.fba=contractsStore.current.fba
    }
    return (
        <>
        <div style={{ fontSize: '0.8rem' }}>
{/* {
                pricingRec && <h1>POR={'' + pricingRec.doCaseType + '-' + pricingRec.hasMawb + ',' + pricingRec.hasHawb + ',' + pricingRec.hasAgent + ',' + pricingRec.mawbChargeHead + ',' + pricingRec.hawbChargeHead + ',' + pricingRec.agentChargeHead}</h1>
            } */}
            {
                props.activeProduct == 'LCL' &&
                <LCLPricingSchedule subscript={rtpStore.routeSubscript} data={brec.schedule} isDummy={brec.hasToShowDummySchedule}
                    contract={props.dummyContract} nobutton={true} />
            }
            {
                props.activeProduct == 'Air' &&
                <AirPricingSchedule subscript={rtpStore.routeSubscript}
                    data={brec.schedule}
                    isDummy={brec.hasToShowDummySchedule}
                    contract={props.dummyContract} nobutton={true} />
            }
            {
                props.activeProduct == 'FCL' &&
                <FCLPricingSchedule subscript={rtpStore.routeSubscript}
                    data={brec.schedule} cwfVisible={true}
                    isDummy={brec.hasToShowDummySchedule}
                    contract={props.dummyContract} nobutton={true} />
            }
             <h2 style={{ paddingTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>Shipper / Exporter details {cecOc && ' - Invoicing'}</h2>
            <div className="columns">
             <$cr n='2'>Shipper Name *</$cr>
                    <$c n='3'>
                    <div className={`select is-small ${(!buttonClickedAtleastOnce || (cecOc? true:shipperDetails.state)) ? '' : 'is-danger'}`} >
            <input 
                type="text" 
                className="input is-small" 
                placeholder="Search Shipper" 
                value={cecOc ? brec.company : shipperSearch} 
                onChange={handleSearchChange} 
                disabled={cecOc}
                onClick={()=>setShowSearchList(true)}
            />
              <ul>
                {showSearchList && !cecOc && (
                        <div className="select-menu"  style={{ position: 'absolute', top: '100%', left: 0, zIndex: 999, backgroundColor: 'white', border: '1px solid #ccc', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', padding: '0.5rem' }} ref={searchListRef}>
                            <ul>
                                {filteredShipperList?.map((shipper, index) => (
                                    <li key={index} onClick={() => {
                                        setNewShipper(false)
                                        if (cecOc) {
                                            brec.company = shipper.companyName;
                                        } else {
                                            setShipperDetails(shipper);
                                            setShipperSearch(shipper.companyName);
                                        }
                                        setShowSearchList(false); 
                                    }}>
                                        {shipper.companyName}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </ul>
        </div>
    </$c>
    </div>
    <div className="columns">
            <$cr n='2'>First Name  *{space}</$cr>
                    <$c n='3'>
                        <input 
                          className={`input is-small ${(!buttonClickedAtleastOnce || (cecOc? brec.firstName:shipperDetails.firstName)) ? '' : 'is-danger'}`} 
                            value={cecOc ? brec.firstName : shipperDetails.firstName} disabled={!cecOc && !newShipper}
                            onChange={cecOc ? ((e) => brec.firstName = e.target.value) : ((e) => setShipperDetails({ ...shipperDetails, firstName: e.target.value }))}
                        />
                    </$c>
                    <$cr n='2'>Last Name </$cr>
                    <$c n='3'>
                        <input 
                          className={`input is-small`} 
                            value={cecOc ? brec.lastName : shipperDetails.lastName} disabled={!cecOc && !newShipper}
                            onChange={cecOc ? ((e) => brec.lastName = e.target.value) : ((e) => setShipperDetails({ ...shipperDetails, lastName: e.target.value }))}
                        />
                    </$c>
            </div>
            
            
            <div className="columns">
            <$cr n='2'>Email ID  *</$cr>
                    <$c n='3'>
                        <input className={`input is-small ${(!buttonClickedAtleastOnce ||(cecOc? brec.emailid:shipperDetails.emailId)) ? '' : 'is-danger'}`}  disabled={!cecOc && !newShipper} value={cecOc ? brec.emailid : shipperDetails.emailId} 
                             onChange={cecOc ? ((e) => brec.emailid = e.target.value) : handleEmailChange}
                          />
                    </$c>
                    <$cr n='2'>Contact Number *</$cr>
                        <$c n='3'>
                            <input
                                className={`input is-small ${(!buttonClickedAtleastOnce || (cecOc ? brec.phone.length > 6 : shipperDetails.mobileNumber.length > 6)) ? '' : 'is-danger'}`}
                                value={cecOc ? brec.phone : shipperDetails.mobileNumber}
                                onChange={cecOc ? ((e) => brec.phone = e.target.value) : ((e) => setShipperDetails({ ...shipperDetails, mobileNumber: e.target.value }))}
                                min="10"
                                onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                disabled={!cecOc && !newShipper}
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/\D/g, '').slice(0, 15);
                                }}
                            />
                        </$c>
            </div>
            {cecOc ?
                (<div className="columns">
                    <$cr n='2'>Branch *{space}</$cr>
                <$c n='3'>
                <div className={`select wd_100 ${!buttonClickedAtleastOnce ||  brec.branchId ? '' : 'is-danger'}`}>
                    <select  className={`select is-fullwidth is-small ${!buttonClickedAtleastOnce || brec.branchId ? '' : 'is-danger'}`} value={brec.branchId} onChange={(e)=>setBranchfn(e)}
                         >
                           <option value=""  >Select Branch</option>
                            {
                                branches?.map((r,i) => {
                                    return (<option value={r.branchId}>{`${r.gst} - ${r?.state?.split("/")[0]}`}</option>)
                                })
                            }
                            {/* <option>hyh</option> */}
                         </select>
                         </div>
                </$c>
                </div>):null
                }
            <div className="columns">
                <$cr n='2'>Address *</$cr>
                    <$c n='3'>
                    {
                        (hasToAutoPopulateEntityDetail && cecOc) ? 
                        <div className={` select wd_100 ${!buttonClickedAtleastOnce ||brec.address ? '' : 'is-danger'}`}>
                        <select className="wd_100 p-1 select is-fullwidth is-small"  onChange={cecOc ? (r, i) => setAddressFn(r, i) : ((e) => setShipperDetails({ ...shipperDetails, address: e.target.value }))} disabled={!cecOc && !newShipper} value={addressObj}  
                        // brec.pin   = e.target.value 
                        // console.log(i)
                          >{<option  value=''>select address</option>}{checkSelectedBranch()?.branchAddresses?.map((r,i)=> <option key={i} value={JSON.stringify(r)} >{r.address}</option>)}</select></div>:  <textarea className={` textarea wd_100 ${!buttonClickedAtleastOnce ||cecOc?brec.address:shipperDetails.address ? '' : 'is-danger'}`} value={cecOc ? brec.address : shipperDetails.address}
                          disabled={!cecOc && !newShipper} onChange={cecOc ? ((e) => { brec.address = e.target.value; }) : ((e) => setShipperDetails({ ...shipperDetails, address: e.target.value }))}/>
                          
                    }
                </$c>
            </div>
            {!cecOc ? 
            <div>
            <div className="columns">
             <$cr n='2'>Send Milestone alerts ?</$cr>
                <$c n='3'>
                    <input className={`checkbox`} type = 'checkbox' checked={milestoneAlert}
                       onChange={() => setMilestoneAlert(!milestoneAlert)} 
                    />
                </$c>
            </div><div className="columns">
                        {shipperDetails.country !== "India" ? (
                            <>
                                <$cr n='2'>City</$cr>
                                <$c n='3'>
                                    <input
                                        value={shipperDetails.city}
                                        disabled={!cecOc && !newShipper}
                                        onChange={((e) => setShipperDetails({ ...shipperDetails, city: e.target.value }))}
                                    />
                                </$c>
                            </>
                        ) : (
                                <>
                                <$cr n='2'>City</$cr>  
                                <$c n='3'><CitySelect label={true} disabled={!cecOc && !newShipper} stateValue={shipperDetails.state} value={shipperDetails.city} callback={(e) => {
                                    setShipperDetails(prevShipperDetails => ({
                                        ...prevShipperDetails,
                                        city: e?.name
                                    }));

                                }}/></$c>
                                </>
                        )}
                        <$cr n='2'>State *</$cr>
                        <$c n='3'><StateSelect label={true} disabled={!cecOc && !newShipper} countryValue={shipperDetails.country} value={shipperDetails.state} callback={(e) => {
                            setShipperDetails(prevShipperDetails => ({
                                ...prevShipperDetails,
                                state: e?.name,
                                city: ""
                            }));

                        }}></StateSelect></$c>
                    </div></div> : null
                    }
            <div className="columns is-narrow">
               <$cr n='2'>PIN/ZIP Code</$cr>
                    <$c n='3'>
                        {
                            hasToAutoPopulateEntityDetail && cecOc ? brec.pin
                                : <input  className={`input is-small ${(!buttonClickedAtleastOnce || (cecOc? true:shipperDetails.pincode)) ? '' : 'is-danger'}`} disabled={!cecOc && !newShipper}type="number"  value={cecOc ? brec.pin : shipperDetails.pincode} onKeyDown={(e) => {
                                    if (e.key === 'e') {
                                        e.preventDefault(); 
                                    }
                                }}
                                onChange={cecOc ? ((e) => brec.pin = e.target.value) : handlePincodeChange}
                              />
                        }
                    </$c>
                    <$cr n='2'>Country</$cr>
                    <$c n='3'>
                    {
                                hasToAutoPopulateEntityDetail && cecOc
                                    ? brec.country
                                    : shipperDetails.country
                            }
                    </$c>
            </div>
            {!(!cecOc && !cecOd) ?
                    <div>
                        { cecOc && !cecOd?
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <$cr n='2'>
                         <h2 style={{ paddingTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>Do you want to add consignee details?</h2></$cr>
                         <$c n='3'><Agyesno label={''} record={''} name={''} callback={(val)=>{
                            setConsigneeForm(!consigneeForm)
                         }}/></$c>
                </div>:null
}
                        {consigneeForm && <div>
                        <h2 style={{ paddingTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>Consignee / Importer details {cecOd && ' - Invoicing'}</h2>
                        <div className="columns" style={{ paddingTop: "10px" }}>
                            <$cr n='2'>Consignee Name</$cr>
                            <$c n='3'>
                                <div className="select is-small">
                                    <input
                                        type="text"
                                        className="input is-small"
                                        placeholder="Search Consignee"
                                        value={cecOd ? brec.company : shipperSearch}
                                        onChange={handleSearchChange}
                                        disabled={cecOd}
                                        onClick={() => setShowSearchList(true)}
                                    />
                                    <ul>
                                        {showSearchList && !cecOd && (
                                            <div className="select-menu" style={{ position: 'absolute', top: '100%', left: 0, zIndex: 999, backgroundColor: 'white', border: '1px solid #ccc', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', padding: '0.5rem' }} ref={searchListRef}>
                                                <ul>
                                                    {filteredShipperList?.map((shipper, index) => (
                                                        <li key={index} onClick={() => {
                                                            setNewShipper(false)
                                                            if (cecOd) {
                                                                brec.company = shipper.companyName;
                                                            } else {
                                                                setShipperDetails(shipper);
                                                                setShipperSearch(shipper.companyName);
                                                            }
                                                            setShowSearchList(false); // Close search list on click
                                                        }}>
                                                            {shipper.companyName}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                    </ul>

                                </div>
                            </$c>
                        </div>


                        {cecOd ?
                            (<div className="columns">
                                <$cr n='2'>Branch *{space}</$cr>
                                <$c n='3'>
                                    <div className={`select wd_100 ${validbranch ? '' : 'is-danger'}`}>
                                        <select className={`select is-fullwidth is-small ${validbranch ? '' : 'is-danger'}`} value={brec.branchId} onChange={(e) => setBranchfn(e)}
                                        >
                                            <option value=""  >Select Branch</option>
                                            {
                                                branches?.map((r, i) => {
                                                    return (<option value={r.branchId}>{`${r.state?.split("/")[0]} - ${(r.gst.length > 0 ? r.gst : r.tinNum)}`}</option>)
                                                })
                                            }
                                            {/* <option>hyh</option> */}
                                        </select>
                                    </div>
                                </$c>
                            </div>) : null}
                        <div className="columns">
                            <$cr n='2'>Address {cecOd && '*'}</$cr>
                            <$c n='3'>
                                {
                                    (hasToAutoPopulateEntityDetail && cecOd) ?
                                        <div className={` select wd_100 ${!buttonClickedAtleastOnce || validAddress ? '' : 'is-danger'}`}>
                                            <select className={`wd_100 p-1 select is-fullwidth is-small ${cecOd ? validAddress : true ? '' : 'is-danger'}`} value={addressObj}
                                                onChange={cecOd ? (r, i) => setAddressFn(r, i) : ((e) => setShipperDetails({ ...shipperDetails, address: e.target.value }))} disabled={!cecOd && !newShipper}
                                            // brec.pin   = e.target.value 
                                            // console.log(i)
                                            >{<option value=''>select address</option>}{checkSelectedBranch()?.branchAddresses?.map((r, i) => <option key={i} value={JSON.stringify(r)} >{r.address}</option>)}</select></div> : <textarea className={`textarea wd_100 ${!buttonClickedAtleastOnce || cecOd ? validAddress : true ? '' : 'is-danger'}`} value={cecOd ? brec.address : shipperDetails.address}
                                                disabled={!cecOd && !newShipper} onChange={cecOd ? ((e) => { brec.address = e.target.value; setvalidAddress(true); }) : ((e) => setShipperDetails({ ...shipperDetails, address: e.target.value }))}
                                        />
                                }
                            </$c>
                        </div>
                        <div className="columns" style={{ paddingTop: "10px" }}>
                            <$cr n='2'>First Name {cecOd && '*'}{space}</$cr>
                            <$c n='3'>
                                <input
                                    className={`input is-small ${(!buttonClickedAtleastOnce || (cecOd ? brec.firstName : true)) ? '' : 'is-danger'}`}
                                    value={cecOd ? brec.firstName : shipperDetails.firstName} disabled={!cecOd && !newShipper}
                                    onChange={((e) => cecOd ? (brec.firstName = e.target.value) : setShipperDetails({ ...shipperDetails, firstName: e.target.value }))}
                                />
                            </$c>
                            <$cr n='2'>Last Name{cecOd && '*'}</$cr>
                            <$c n='3'>
                                <input
                                    className={`input is-small`}
                                    value={cecOd ? brec.lastName : shipperDetails.lastName} disabled={!cecOd && !newShipper}
                                    onChange={((e) => cecOd ? (brec.lastName = e.target.value) : setShipperDetails({ ...shipperDetails, lastName: e.target.value }))}
                                />
                            </$c>
                        </div>
                        <div className="columns">
                            <$cr n='2'>Email ID {cecOd && '*'}</$cr>
                            <$c n='3'>
                                <input className={`input is-small ${(!buttonClickedAtleastOnce || (cecOd ? validEmail(brec.emailid) : true)) ? '' : 'is-danger'}`} disabled={!cecOd && !newShipper} value={cecOd ? brec.emailid : shipperDetails.emailId}
                                    onChange={cecOd ? ((e) => brec.emailid = e.target.value) : handleEmailChange}
                                />
                            </$c>
                            <$cr n='2'>Contact Number {cecOd && '*'}</$cr>
                            <$c n='3'>
                                <input
                                    className={`input is-small ${(!buttonClickedAtleastOnce || (cecOd ?  (brec.country !== "India"?(brec.phone.length > 6):(brec.phone.length === 10)) : true)) ? '' : 'is-danger'}`}
                                    value={cecOd ? brec.phone : shipperDetails.mobileNumber} disabled={!cecOd && !newShipper}
                                    onChange={cecOd ? ((e) => brec.phone = e.target.value) : ((e) => setShipperDetails({ ...shipperDetails, mobileNumber: e.target.value }))}
                                    min="10"
                                    onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/\D/g, '').slice(0, 15);
                                    }}
                                />
                            </$c>

                        </div>
                        {!cecOd ? 
                        <div>
                        <div className="columns">
                         <$cr n='2'>Send Milestone alerts ?</$cr>
                            <$c n='3'>
                                <input className={`checkbox`} type = 'checkbox' checked={milestoneAlert}
                                   onChange={() => setMilestoneAlert(!milestoneAlert)} 
                                />
                            </$c>
                        </div><div className="columns">
                            <$cr n='2'>State *</$cr>
                            <$c n='3'><StateSelect label={true} disabled={!cecOd && !newShipper} countryValue={shipperDetails.country} value={shipperDetails.state} callback={(e) => {
                                setShipperDetails(prevShipperDetails => ({
                                    ...prevShipperDetails,
                                    state: e?.name,
                                    city: ""
                                }));

                            }}></StateSelect></$c>
                            {shipperDetails.country !== "India" ? (
                                <>
                                    <$cr n='2'>City</$cr>
                                    <$c n='3'>
                                        <input
                                        className="input is-small"
                                            value={shipperDetails.city}
                                            disabled={!cecOd && !newShipper}
                                            onChange={((e) => setShipperDetails({ ...shipperDetails, city: e.target.value }))}
                                        />
                                    </$c>
                                </>
                            ) : (
                                <>
                                <$cr n='2'>City *</$cr>  
                                <$c n='3'><CitySelect label={true} disabled={!cecOd && !newShipper} stateValue={shipperDetails.state} value={shipperDetails.city} callback={(e) => {
                                    setShipperDetails(prevShipperDetails => ({
                                        ...prevShipperDetails,
                                        city: e?.name
                                    }));

                                }}/></$c>
                                </>
                            )}
                        </div></div> : null
                        }
                        <div className="columns is-narrow">
                            <$cr n='2'>PIN/ZIP Code</$cr>
                            <$c n='3'>
                                {
                                    hasToAutoPopulateEntityDetail && cecOd ? brec.pin
                                        : <input className={`input is-small`} disabled={!cecOd && !newShipper} type="number" value={cecOd ? brec.pin : shipperDetails.pincode} onKeyDown={(e) => {
                                            if (e.key === 'e') {
                                                e.preventDefault();
                                            }
                                        }}
                                            onChange={cecOd ? ((e) => brec.pin = e.target.value) : handlePincodeChange}
                                        />
                                }
                            </$c>
                            <$cr n='2'>Country</$cr>
                            <$c n='3'>
                                {
                                    hasToAutoPopulateEntityDetail && cecOd
                                        ? brec.country
                                        : brec.destinationCount
                                }
                            </$c>
                        </div>
                        </div>}
                    </div> : null
                }


                {!cecOc && !cecOd ?
                    (<div><h2 style={{ paddingTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>Invoice Details</h2><div className="columns">
                        <$cr n='2'>Branch *{space}</$cr>
                        <$c n='3'>
                            <div className={`select wd_100 ${validbranch ? '' : 'is-danger'}`}>
                                <select className={`select is-fullwidth is-small ${validbranch ? '' : 'is-danger'}`} value={brec.branchId} onChange={(e) => setBranchfn(e)}
                                >
                                    <option value=""  >Select Branch</option>
                                    {
                                        branches?.map((r, i) => {
                                            return (<option value={r.branchId}>{`${r.state?.split("/")[0]} - ${(r.gst.length > 0 ? r.gst : r.tinNum)}`}</option>)
                                        })
                                    }
                                    {/* <option>hyh</option> */}
                                </select>
                            </div>
                        </$c>
                        <$cr n='2'>Address *</$cr>
                        <$c n='3'>
                            {
                                (hasToAutoPopulateEntityDetail) ?
                                    <div className={` select wd_100 ${validAddress ? '' : 'is-danger'}`}>
                                        <select className={`wd_100 p-1 select is-fullwidth is-small ${validAddress ? '' : 'is-danger'}`} value={addressObj}
                                            onChange={(r, i) => setAddressFn(r, i)}
                                        >{<option value=''>select address</option>}{checkSelectedBranch()?.branchAddresses?.map((r, i) => <option key={i} value={JSON.stringify(r)} >{r.address}</option>)}</select></div> : <textarea className={`textarea wd_100 ${validAddress ? '' : 'is-danger'}`} value={brec.address}
                                            onChange={cecOd ? ((e) => { brec.address = e.target.value; setvalidAddress(true); }) : ((e) => setShipperDetails({ ...shipperDetails, address: e.target.value }))}
                                    />

                            }
                        </$c>
                    </div><div className="columns">
                            <$cr n='2'>PIN/ZIP Code</$cr>
                            <$c n='3'>
                                {
                                    hasToAutoPopulateEntityDetail ? brec.pin
                                        : <input className={`input is-small`} type="number" value={brec.pin} onKeyDown={(e) => {
                                            if (e.key === 'e') {
                                                e.preventDefault();
                                            }
                                        }}
                                            onChange={(e) => brec.pin = e.target.value}
                                        />
                                }
                            </$c>
                            <$cr n='2'>Country</$cr>
                            <$c n='3'>
                                {
                                    hasToAutoPopulateEntityDetail
                                        ? brec.country
                                        : <div class="control">
                                            <div class={`select is-fullwidth is-small ${!buttonClickedAtleastOnce || brec.country ? '' : 'is-danger'}`}>
                                                <select className={`select is-fullwidth is-small ${!buttonClickedAtleastOnce || brec.country ? '' : 'is-danger'}`} value={brec.country}
                                                    onChange={(e) => brec.country = e.target.value}
                                                >
                                                    {ServerLists.countryNameList.map(e => <option value={e}>{e}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                }
                            </$c>

                        </div></div>
                    ) : null
                }
                <div>
                  <h2 style={{ paddingTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>Shipment details</h2>
            <div className="columns">
                <$cr n='2'>Incoterms</$cr>
                <$c n='3'>
                <div className={`select ${!buttonClickedAtleastOnce ||brec.incoTerms ? '' : 'is-danger'}`}>
                    <select className="select is-fullwidth is-small" value={brec.incoTerms}
                        onChange={(e) => {
                            brec.incoTerms = e.target.value
                        }} >
                        <option value=''></option>
                        {
                            (brec.contract.shipmentType == 'Air') ?
                                incoTermsListAirPricing.map(e => <option value={e}>{e}</option>)
                                :
                                incoTermsList.map(e => <option value={e}>{e}</option>)
                        }
                    </select>
                </div>
                </$c>
                <$cr n='2'>Commodity Type</$cr>
                <$c n='3'>
                     {/* <div className={`input ${!buttonClickedAtleastOnce || brec.commodityType ? '' : 'is-danger'}`}> */}
                        <input defaultValue={brec.commodityType} list='commodities'
                            onBlur={(e) => brec.commodityType = e.target.value}
                            className={`input is-fullwidth is-small ${!buttonClickedAtleastOnce || brec.commodityType ? '' : 'is-danger'}`}
                            />
                        <datalist id='commodities'>
                            {
                                commList.map(e => <option value={e} />)
                            }
                        </datalist>
                        {/* </div> */}
                </$c>
            </div>
{
                (pricingRec && pricingRec.doCaseType == 1 &&
                    pricingRec.hasHawb &&
                    pricingRec.hasAgent) &&
                <div className="columns">
                    <$cr n='2'></$cr>
                    <$c n='6'>
                        <GenericSwitch store={rtpStore} property='doCaseType' leftValue='Direct Master AWB' rightValue='House AWB' />
                    </$c>
                </div>
            }
            {/* <div className="columns is-narrow">      
                <$cr n='2'>FBA</$cr>       
                <$c n='2'>
                {
                    (contractsStore.current.fba!=undefined)?<Agyesno1 disabled={true} label='' record={brec} name='fba' />:
                    <Agyesno1 label='' record={brec} name='fba' />
                }
                </$c>
                <$c n='8'>
                </$c>
            </div> */}
            {
                (brec.fba=='Yes')? <>
                <h2 style={{ paddingTop: "10px", fontSize: "1rem", fontWeight: "bold" }}>FBA Details</h2>
                <div className="columns is-narrow">    
                        <$cr n='2'>Merchant ID</$cr>
                        <$c n='3'>
                            <input className={`input is-small ${faberror.indexOf("merchantID")!=-1?"is-danger":""}`} value={brec.merchantID}
                                onChange={(e)=>{
                                    brec.merchantID=Utils.removeSpecialCharacters(e.target.value.trim(),14)
                                }}
                            />
                        </$c>
                    </div>
                    {
                        brec.fbaDetails.map((e,i)=>{
                            return <>
                            {
                                brec.fbaDetails.length>1?<div className="columns is-narrow">    
                                <$cr n='2'><h2 style={{ paddingTop: "10px", fontSize: "0.7rem", fontWeight: "bold" }}>Destination {i+1}</h2></$cr>
                            </div>:""
                            }
                            <div className="columns is-narrow">   
                        <$cr n='2'>FBA ID</$cr>
                        <$c n='3'>
                                <input class="input is-small" className={`input is-small ${faberror.indexOf("fbaID"+i)!=-1?"is-danger":""}`} value={brec.fbaDetails[i].fbaID}
                                    onChange={(e)=>{
                                        brec.fbaDetails[i].fbaID=Utils.removeSpecialCharacters(e.target.value.trim(),12)
                                    }}
                                />
                        </$c>
                        <$cr n='2'>Amazon Reference ID</$cr>
                        <$c n='3'>
                            <input className={`input is-small ${faberror.indexOf("purchaseOrderID"+i)!=-1?"is-danger":""}`} value={brec.fbaDetails[i].purchaseOrderID}
                                onChange={(e)=>{
                                    brec.fbaDetails[i].purchaseOrderID=Utils.removeSpecialCharacters(e.target.value.trim(),8)
                                }}
                            />
                        </$c>
                        <$cr n='2'></$cr>
                        <$c n='3'>
                        </$c>
                    </div>
                            </>
                        })
                    }
                </>:<></>
            }
            <div className="columns is-narrow">
                <$cr n='2'>Special Instructions</$cr>
                <$c n='6'>
                    <textarea rows='3' style={{ fontSize: '0.8rem' }} className="textarea" value={brec.specialInstructions}
                        onChange={(e) => brec.specialInstructions = e.target.value} />
                </$c>
                <$c n='2'>
                {
                        contractsStore.current.shipmentType.toLowerCase().includes("fcl") &&
                            contractsStore.current.shipmentScope.endsWith("ort") && (
                            <div className="columns is-narrow">
                                <$c n="6">
                                <Agyesno
                                    label="Do you require a Direct Carrier MBL?"
                                    record={brec}
                                    name="carrierMBL"
                                    callback={handleToggle}
                                />
                                {showConfirm && (
                                    <MblConfirmationPopup onConfirm={confirmToggle} onCancel={cancelToggle} />
                                )}
                                </$c>
                            </div>
                            )
                        }

                </$c>
            </div>
            </div>

            <div className="columns">
                <$c n='1'></$c>
                <$c n='9'>
                    <div className="table-container ">
                        <table className="table is-fullwidth is-bordered is-striped is-narrow is-hoverable ">
                            <thead>
                                <tr>
                                    <th style={{ width: '16rem' }}>Required Documents</th>
                                    <th>Document Uploaded</th>
                                    <th style={{ width: '12rem' }}></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr id="cwf5">
                                    <td>Commercial Invoice {(brec.contract.hazardous === 'Yes' || brec.contract.temperatureControlled === 'Yes')? '*': `(Non-Mandatory)`}</td>
                                    <td className={validCommercialInvoice ? '' : 'is-danger-border'}>
                                        {brec.commercialInvoice_filename_list.map(renderLink).map((link,i) => <><p>{link}
                                                <span className="icon has-text-danger" style={{cursor: 'pointer'}} onClick={() => {
                                                    brec.commercialInvoice_filename_list = delFile(brec.commercialInvoice_filename_list,i)
                                                }}>
                                                    <i className="fas fa-trash"></i>
                                                </span></p><br /></>)}
                                    </td>
                                    <td>
                                        <AgCustomFile source='carrierwebform' cat={filecat} filetype='commercialInvoice' parent="cwf5" multi={true}
                                            callback={(filename) => brec.commercialInvoice_filename_list.push(filename)} withBtn={brec.commercialInvoice_filename_list.length > 0} />
                                    </td>
                                </tr>
                                <tr id="cwf6">
                                    <td>Packing List {(brec.contract.hazardous === 'Yes' || brec.contract.temperatureControlled === 'Yes')? '*': `(Non-Mandatory)`}</td>
                                    <td className={validPackingList ? '' : 'is-danger-border'}>{brec.packingList_filename_list.map(renderLink).map((link,i) => <><p>{link}
                                                <span className="icon has-text-danger" style={{cursor: 'pointer'}} onClick={() => {
                                                    brec.packingList_filename_list = delFile(brec.packingList_filename_list,i)
                                                }}>
                                                    <i className="fas fa-trash"></i>
                                                </span></p><br /></>)}</td>
                                    <td>
                                        <AgCustomFile source='carrierwebform' cat={filecat} filetype='packingList' parent="cwf6" multi={true}
                                            callback={(filename) => brec.packingList_filename_list.push(filename)} withBtn={brec.packingList_filename_list.length > 0} />
                                    </td>
                                </tr>
                                <tr id="cwf7">
                                    <td>Special Instructions (Non-Mandatory)</td>
                                    <td>
                                        {brec.specialInstructions_filename_list.map(renderLink).map((link,i) => <><p>{link}
                                                <span className="icon has-text-danger" style={{cursor: 'pointer'}} onClick={() => {
                                                    brec.specialInstructions_filename_list = delFile(brec.specialInstructions_filename_list,i)
                                                }}>
                                                    <i className="fas fa-trash"></i>
                                                </span></p><br /></>)}
                                    </td>
                                    <td>
                                    {
                                    bookingsStore.current?.custbookingconf_filename_list?.length > 0 && (contractsStore.current.shipmentType.toLowerCase().includes('fcl') || contractsStore.current.shipmentType.toLowerCase().includes('air')) &&
                                    <tr>
                                        <td>Customer Booking Confirmation (Non-Mandatory)</td>
                                        <td>{brec.custbookingconf_filename_list.map(renderLink).map(link => <>{link}<br /></>)}</td>
                                    </tr>
                                }
                                    </td>
                                    <td>
                                        <AgCustomFile source='carrierwebform' cat={filecat} filetype='specialInstructions' parent="cwf7" multi={true}
                                            callback={(filename) => brec.specialInstructions_filename_list.push(filename)} withBtn={brec.specialInstructions_filename_list.length > 0} />
                                    </td>
                                </tr>
                                {
                                    (
                                        (brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals'))
                                        || (brec.contract.hazardous === 'Yes')
                                    ) ?
                                        <tr id="cwf1">
                                            <td>MSDS</td>
                                            <td><p>{renderLink(brec.msds_filename)}
                                                { brec.msds_filename!=undefined && brec.msds_filename.length!=0 && <span className="icon has-text-danger" style={{cursor: 'pointer'}} onClick={() => {
                                                    brec.msds_filename = ""
                                                }}>
                                                    <i className="fas fa-trash"></i>
                                                </span>}</p></td>
                                            <td>
                                                <AgCustomFile source='carrierwebform' cat={filecat} filetype='msds' multi={false}
                                                    callback={(filename) => brec.msds_filename = filename} parent="cwf1" />
                                            </td>
                                        </tr>
                                        : <></>
                                }
                                {
                                    (brec.commodityType && brec.commodityType.startsWith('Non-Hazardous Chemicals')) ?
                                        <tr id="cwf2">
                                            <td>Non-DG Declaration <a className="is-link" href="./Non_Hazardous_Declaration.doc" download>(Download Template)</a></td>
                                            <td><p>{renderLink(brec.nondgdecl_filename)}
                                                { brec.nondgdecl_filename!=undefined && brec.nondgdecl_filename.length!=0 && <span className="icon has-text-danger" style={{cursor: 'pointer'}} onClick={() => {
                                                    brec.nondgdecl_filename = ""
                                                }}>
                                                    <i className="fas fa-trash"></i>
                                                </span>}</p></td>
                                            <td>
                                                <AgCustomFile cat={filecat} filetype='nondgdecl' multi={false}
                                                    callback={(filename) => brec.nondgdecl_filename = filename} parent="cwf2" />
                                            </td>
                                        </tr> : <></>
                                }
                                {
                                    (brec.contract.hazardous === 'Yes') ?
                                        <>
                                            <tr id="cwf3">
                                                <td>DG Declaration <a className="is-link" href="./DG_Declaration_updated.doc" download>(Download Template)</a></td>
                                                <td><p>{renderLink(brec.dgdecl_filename)}
                                                { brec.dgdecl_filename!=undefined && brec.dgdecl_filename.length!=0 && <span className="icon has-text-danger" style={{cursor: 'pointer'}} onClick={() => {
                                                    brec.dgdecl_filename = ""
                                                }}>
                                                    <i className="fas fa-trash"></i>
                                                </span>}</p></td>
                                                <td>
                                                    <AgCustomFile cat={filecat} filetype='dgdecl' multi={false}
                                                        callback={(filename) => brec.dgdecl_filename = filename} parent="cwf3" />
                                                </td>
                                            </tr>
                                            <tr id="cwf4">
                                                <td >IIP Certificate *</td>
                                                <td className={validIIPCertificate ? '' : 'is-danger-border'}><p>{renderLink(brec.iipcert_filename)}
                                                { brec.iipcert_filename!=undefined && brec.iipcert_filename.length!=0 && <span className="icon has-text-danger" style={{cursor: 'pointer'}} onClick={() => {
                                                    brec.iipcert_filename = ""
                                                }}>
                                                    <i className="fas fa-trash"></i>
                                                </span>}</p></td>
                                                <td   >
                                                    <AgCustomFile
                                                        cat={filecat}
                                                        filetype='iipcert'
                                                        multi={false}
                                                        callback={(filename) => brec.iipcert_filename = filename}
                                                        parent="cwf4"
                                                    />
                                                </td>
                                            </tr>

                                        </> : <></>
                                }
                                </tbody>
                        </table>
                    </div>
                </$c>
            </div>
            <div className="columns">
                <$cr n='1'></$cr>
                <$c n='9'>
                    <button className="button is-warning" onClick={(e) => {
                        configStore.scheduleOrCarrierform = 'schedule'
                        hideme(false)
                    }}>
                        <span className="icon" style={{ marginRight: '5px' }}>
                            <i class="fa-solid fa-circle-left"></i>
                        </span>
                        Real-time Pricing
                    </button>
                    <button className="button is-link" style={{ float: 'right' }} onClick={onSubmit}  disabled={isButtonClicked}>
                        Confirm and Save Booking
                    </button>
                </$c>
            </div>
        </div>
        {showList?<div><div className='exist_customer_entity_wrapper'>
                <div className='exist_customer_entity_container'>
                  <h1 style={{fontWeight:900}}>Link Existing Customer Entity</h1>
                  <table>
                        <tr>
                          
                          <th>Entity ID</th>
                          <th>Entity Name</th>
                          <th>Country</th>
                        </tr>
                        <tbody>
                        {EntityList?.map((d)=>{
                          return <>
                               <tr>
                                  <td>
                                    <div className='flexWithCenter'>
                                    <input type="radio" id="linkedRadio" name="fav_language" value={d._id} onClick={() =>setShipperDetails({ ...shipperDetails, linkedEntity: d._id })}/>
                                  <span>{d?._id}</span> 
                                    </div>

                                  </td>
                                  <td>{d?.entityName}</td>
                                  <td>{d?.entityCountry}</td>
                                </tr>
                          </>
                        })}
                        </tbody>
   
                </table>
                    <div style={{textAlign:"right",margin:"1rem"}}>
                    <button className="button" style={{fontWeight:500,margin:"0 1rem"}} onClick={()=> {
                        setShipperDetails({ ...shipperDetails, linkedEntity: "" })
                      setshowList(false)
                      }} >Cancel</button>
                    <button className="button is-link" style={{backgroundColor:"#2c358a",fontWeight:500}} onClick={()=> { 
                    
                      setshowList(false)
                      }}>Submit</button>
                    </div>
              </div>
            </div></div>
            :null}
        </>
    )
}