import { subscribe, useSnapshot } from "valtio";
import ReactTablePagination from "../components/ag-table/ReactTablePagination";
import AgLastUpload, { AgLastUpload1, AgLastUpload2 } from "../components/AgLastUpload";
import { taskStore } from "../mytasks/task-store";
import { configStore, loginStore } from "../state/CommonState";
import { menucountStore } from "../state/menuStore";
import MonApi from "../state/mongo-api";
import Utils from "../util/Utils";
import ShipmentGraph, { graphNode } from "./shipment-graph";
import { myactionsstore } from "./store-myactions";
import React,{useState,useEffect, useRef} from "react";
import {useTable,usePagination,useSortBy} from "react-table"
import { QueryClient,QueryClientProvider } from "react-query";

//mychanges
import { dataListStore, modalStore } from "../state/CommonState";
import { Agyesno } from "../components/Agyesno";
import { AgUnloc, AgUnloc1, TableSelect5 } from "../components/AgComponents";




import {reducer,PAGE_CHANGED,PAGE_SIZE_CHANGED,PAGE_SORT_CHANGED,PAGE_FILTER_CHANGED,TOTAL_COUNT_CHANGED,PAYLOAD_CHANGED} from "../components/ag-table/ag-reducer"
import { DRS_COLUMNS } from "../components/ag-table/columns";
import ApiAgTable from "../components/ag-table/api_ag-table";
import { OneDate, OneInput5, OneSelect4 } from "../mytasks/act-common";
import Api from "../state/Api";
import { AgUnlocdsr } from "../components/AgComponents";
import { loader } from "../util/loader";
import { VisibilityGraph } from "./visibilitygraph";
import { Visibility } from "./visibility";
import { DsrModal } from "./dsrmodal";
import { Typography } from "@mui/material";
import { Nodata } from "../mui-components/nodatafound";
import { useLocation } from "react-router";

const queryClient = new QueryClient()

const initialState = {
    queryPageIndex: 0,
    queryPageSize: 10,
    totalCount: 0,
    queryPageFilter: "",
    queryPageSortBy: [],
    queryPayload: {}
};

const TableLine = (props) => {
    let { label, value } = props
    if (value) value = Utils.reformatSuffixedDate(value)
    if (value) {
        return (
            <tr>
                <td style={{ fontWeight: 'bold', textAlign: 'right', paddingRight: '10px',width:'50%' }}>{label}</td>
                <td style={{overflowWrap: 'anywhere'}}>{value}</td>
            </tr>
        )
    }
    return <></>
}
const DataTable = (props) => {
    useSnapshot(myactionsstore)
    useSnapshot(configStore)
    const filterStatus = {
        'fcl':'FCL',
        'lcl':'LCL',
        'air':'AIR'
    }
    const filterStatus1 = {
        'In-transit': 'In-transit',
        'Complete': 'Complete'
    }
    const location = useLocation().pathname;
    const [graphList, setGraphList] = useState([])
    const [elist, setElist] = useState([])
    const [{ queryPageIndex, queryPageSize, totalCount, queryPageFilter, queryPageSortBy, queryPayload }, dispatch] =
    React.useReducer(reducer, initialState);
    const totalPageCount = Math.ceil(totalCount / queryPageSize)
    const [data1, setData1] = useState({ results: [] })
    let columns = DRS_COLUMNS
    const [search, setSearch] = useState('')
    const [selectedFilter, SetSelectedFilter] = useState(`ALL`);
    const [selected1Filter, SetSelected1Filter] = useState(`ALL`);
    let entity = 'ALL'
    if (loginStore.isClient()) entity = configStore.currentSelectedEntity.entityId
    let url = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/downloadxl/${entity}`
    let urlzip = `${process.env.REACT_APP_API_SERVER}/api/v1/myactions/downloadzip/${entity}`

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        pageCount,
        pageOptions,
        gotoPage,
        previousPage,
        canPreviousPage,
        nextPage,
        canNextPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable({
        columns,
        data: data1?.results,
        initialState: {
            pageIndex: queryPageIndex,
            pageSize: queryPageSize,
            sortBy: queryPageSortBy,
        },
        manualPagination: true,
        pageCount: data1 ? totalPageCount : null,
        autoResetSortBy: false,
        autoResetExpanded: false,
        autoResetPage: false
    },
        useSortBy,
        usePagination,
    );
   
   

    const manualPageSize = []
    // useEffect(()=>{
    //     if (configStore.currentURL.startsWith("/visibility/view")) {
    //         const visibilityId = location.split('/').at(3);
    //         if(visibilityId){
    //             setSearch(visibilityId);
    //         }
    //     }
    // },[]) 

    useEffect(() => {
        dispatch({ type: PAGE_CHANGED, payload: pageIndex });
    }, [pageIndex,gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SIZE_CHANGED, payload: pageSize });
        gotoPage(0);
    }, [pageSize, gotoPage]);

    useEffect(() => {
        dispatch({ type: PAGE_SORT_CHANGED, payload: sortBy });
        gotoPage(0);
    }, [sortBy, gotoPage]);

    useEffect(() => {
        if (configStore.currentURL.startsWith("/visibility/view")) {
            const visibilityId = location.split('/').at(3);
            if(visibilityId){
                dispatch({ type: PAYLOAD_CHANGED, payload: { search:visibilityId,filter:selectedFilter,filter1:selected1Filter } });
                setSearch(visibilityId);
            }
        }else{
            dispatch({ type: PAYLOAD_CHANGED, payload: { search:search,filter:selectedFilter,filter1:selected1Filter } });
        }
        gotoPage(0);
    }, [selectedFilter,selected1Filter, gotoPage]);

    useEffect(() => {
        if (search.length === 0) {
            dispatch({ type: PAYLOAD_CHANGED, payload: { search:search,filter:selectedFilter,filter1:selected1Filter } });
            gotoPage(0);
        }
    }, [search]);

    const onClickSearch = () => {
        if (search.length > 0) {
            dispatch({ type: PAYLOAD_CHANGED, payload: { search:search,filter:selectedFilter,filter1:selected1Filter } });
            gotoPage(0);
        }else{
            configStore.setModalMessage(`Please Provide Valid Input`)  
        }
    };


    function setlist() {
        //setData1({results : myactionsstore.list, count:myactionsstore.count})
        let eli = []
        let graphList1 = []
        for (let i = 0; i < myactionsstore.list.length; i++) {
            let rec = myactionsstore.list[i]
            let oneGraph = []
            console.log("the rec is ",rec)
            oneGraph = Visibility.GraphLine(rec)
            // console.log("oneGraph",i+1,oneGraph);
        if (rec?.icegateResData && rec?.icegateResData?.oocDt) {
            oneGraph[oneGraph?.length-1].r7 = `OOC ${Utils.convertICEGATEDate(rec?.icegateResData?.oocDt,2)}`
        }
        if (rec?.icegateResData && rec?.icegateResData?.leoDt) {
            oneGraph[0].r7 =`LEO ${Utils.convertICEGATEDate(rec?.icegateResData?.leoDt,2)}`
        }
            // if (rec.shipmentType !== 'Air') oneGraph = Visibility.oceanGraphLine(rec)
            // else oneGraph = Visibility.airGraphLine(rec)
            let activeOn = false
            let lastactive = -1
            for (let i = oneGraph.length - 1; i >= 0; i--) {
                let node = oneGraph[i]
                if(rec.status_closed == 'Y' || rec.status_closed == 'Yes' || rec.booking.status == 'ARCHIVED'){
                    node.isActive = true
                }
                if (node.isActive && !activeOn) {
                    node.activelines = 1
                    lastactive = i
                }
                if (node.isActive) activeOn = true
                if (activeOn && !node.isActive) node.isActive = true
                if (node.isActive && i < lastactive) node.activelines = 2
            }
            graphList1.push(oneGraph)
            eli.push(rec)
            console.log("graphList1",graphList1);
            setGraphList(graphList1)
        }
        setElist(eli)
    }

    React.useEffect(() => {
        if (data1?.count) {
            dispatch({
                type: TOTAL_COUNT_CHANGED,
                payload: data1.count,
            });
        }
    }, [data1?.count]);

    useEffect(() => {
        console.log('*** subscribing ***********')
        const unsubscribe = subscribe(myactionsstore, () => {
            setlist()
        })
        return unsubscribe
    },[myactionsstore,data1])

    useEffect(() => {
        console.log('*** GETTING ALL THE DSR')
        //Newapi.getAllDsr4Display();
        if(queryPayload.filter && queryPayload.filter1){
            loader.show()
            ApiAgTable.getAllDsr4Display(queryPageIndex, queryPageSize, queryPageFilter, queryPageSortBy, queryPayload).then(res=>{
                res.clone().json().then((res) => {
                    if (JSON.stringify(myactionsstore.list) != JSON.stringify(res.results1)) {
                        let lres = res
                        for (let i = 0; i < lres?.results1?.length; i++) {
                            if(lres.results1[i]['booking']['containershow']==undefined){
                                lres.results1[i]['booking']['containershow'] = -1
                            }
                        }
                       if(JSON.stringify(data1)!=JSON.stringify(lres) && lres.results && lres.results.length > 0){
                        console.log(lres)
                        myactionsstore.setList(lres.results1)
                        setData1({results:lres.results,count:lres.count})
                       }else{
                            myactionsstore.list = [];
                            setData1({ count: 0, results: [] });
                        }
                    }
                    loader.hide()
                }, err => {
                    console.log(err)
                })
            })
        }
        
},[queryPayload,queryPageIndex,queryPageSize,queryPageFilter,queryPageSortBy,configStore.currentSelectedEntity, configStore.currentSelectedBranch,myactionsstore.recheck])

return (
    <div style={{flex:1}}>
        {/* <ActrejectModal7/> */}
        
        <div className="box is-fullwidth" style={{flex:1,backgroundColor:"transparent",border:"none !important",boxShadow: loginStore.isClient() ? "none" : "initial"}}>
            <div className="columns" style={{border:"none !important"}}>
                <h4 className="title is-3">Visibility</h4>
                <div className="field has-addons column is-4" style={{border:"none !important"}}>
                    <p className="control">
                        <input className="input"
                        style={{
                            borderColor: "none",
                            boxShadow: "none",
                        }}
                        value={search} 
                        onChange={(e) => {
                            configStore.currentURL = ""
                            setSearch(e.target.value)
                        }}
                        onKeyUp = {(e) => {
                            if (e.key === 'Enter') {
                                configStore.currentURL = ""
                                setSearch(e.target.value)
                                onClickSearch()
                            }
                        }}
                        placeholder='Search' />
                    </p>
                    <p className="control">
                        <span className="button is-link" onClick={onClickSearch}>Search</span>
                    </p>
                    
                </div>
                {/* <div><button onClick={()=>{configStore.quotationModalVisible = true

}}>button</button>
<ActrejectModal7/></div> */}
                
                {
                    (!loginStore.isClient()) ? <>
                        <div className="column is-4">
                        <div className="columns" style={{ padding: '0px' }}>
                        <div className="column is-6">
                        <div class="level-item level-left">
                                <div class="field">
                                    <p class="control has-icons-left has-icons-right">Shipment Type</p>
                                    <p class="control has-icons-left has-icons-right">
                                        <span class="select">
                                            <select name="status" id="status"
                                                value={selectedFilter} onChange={(e) => SetSelectedFilter(e.target.value)}
                                            >
                                                <option value="ALL">ALL</option>
                                                {
                                                    Object.entries(filterStatus).map(([key, value]) => (
                                                        <option value={key}>{value}</option>
                                                    ))
                                                }
                                            </select>
                                            <span class="icon">
                                                <i class="fa-solid fa-filter"></i>
                                            </span>
                                        </span>
                                    </p>
                                </div>
                            </div>  </div>
                        <div className="column is-6" >
                        <div class="level-item level-left">
                                <div class="field">
                                    <p class="control has-icons-left has-icons-right">Status</p>
                                    <p class="control has-icons-left has-icons-right">
                                        <span class="select">
                                            <select name="status" id="status"
                                                value={selected1Filter} onChange={(e) => SetSelected1Filter(e.target.value)}
                                            >
                                                <option value="ALL">ALL</option>
                                                {
                                                    Object.entries(filterStatus1).map(([key, value]) => (
                                                        <option value={key}>{value}</option>
                                                    ))
                                                }
                                            </select>
                                            <span class="icon">
                                                <i class="fa-solid fa-filter"></i>
                                            </span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        </div>
                            
                        </div>
                        <div className="column is-2" style={{ justifyContent: 'center', textAlign: 'right' }}>
                            <div className="title is-5" style={{ marginBottom: '0px' }}>Pending DSR : {menucountStore.pendingdsr}</div>
                        </div>
                    </> : <>
                        <div className="column is-5">
                            <div class="level-item level-left ml-6">
                                <div class="field">
                                    <p class="control has-icons-left has-icons-right">
                                        <span class="select">
                                            <select name="status" id="status"
                                                value={selectedFilter} onChange={(e) => SetSelectedFilter(e.target.value)}
                                            >
                                                <option value="ALL">ALL</option>
                                                {
                                                    Object.entries(filterStatus).map(([key, value]) => (
                                                        <option value={key}>{value}</option>
                                                    ))
                                                }
                                            </select>
                                            <span class="icon">
                                                <i class="fa-solid fa-filter"></i>
                                            </span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {/* <div className="column is-3" style={{ justifyContent: 'center', textAlign: 'right',alignItems:"center",flexDirection:"row" }}>
                    <div style={{ marginBottom: '0px',fontWeight:"normal",fontSize:"14px",justifyContent: 'center',alignItems:"center",display:"flex", gap:5 }}>
                        <Typography> Daily Status Reports</Typography>
                        <span>
                                <a class="button is-small is-link is-inverted is-rounded"
                                    href={url}>
                                    <span class="icon">
                                        <i class="fas fa-download"></i>
                                    </span>
                                </a>
                            </span>
                        </div> */}
                       
                    {/* <div className="columns" style={{ padding: '0px' }}>
                        <div className="column is-2">  </div>
                        <div className="column is-4"></div>
                        <div className="column is-5" style={{ width: "55%", position: "relative", left: "2px", textAlign: "right", marginTop: "5px" }} >
                            <span style={{ textDecoration: 'underline' }}>MS-Excel</span>
                            <span>
                                <a class="button is-small is-link is-inverted is-rounded"
                                    href={url}>
                                    <span class="icon">
                                        <i class="fas fa-download"></i>
                                    </span>
                                </a>
                            </span>
                        </div>
                        
                        <div className="column is-4" style={{ width: "39%", position: "relative", right: "1rem", textAlign: "right", marginTop: "5px" }}>
                            <span style={{ textDecoration: 'underline' }}>CSV</span>
                            <span>
                                <a class="button is-link is-small is-inverted  is-rounded"
                                    href={urlzip}>
                                    <span class="icon" >
                                        <i class="fas fa-download"></i>
                                    </span>
                                </a>
                            </span>
                        </div>


                    </div> */}
                    
                {/* </div> */}
                
            </div>

            {
                graphList?.length > 0 ?
                graphList.map((e, i) => {
                    let e1 = elist[i]
                    if(e1){
                        return <VisibilityGraph graphNodes={e} dsrRec={e1} />
                    }
                }) : <Nodata width="250px" height="250px"/>
            }
            <ReactTablePagination
                page={page}
                gotoPage={gotoPage}
                previousPage={previousPage}
                nextPage={nextPage}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                pageOptions={pageOptions}
                pageSize={pageSize}
                pageIndex={pageIndex}
                pageCount={pageCount}
                setPageSize={setPageSize}
                manualPageSize={manualPageSize}
                dataLength={totalCount}
                rows={rows}
            />
        </div>
        
      

<DsrModal />
    </div>
)

}


const CustomerMyactions = (props) => {
    return (
        <QueryClientProvider client={queryClient}>
            <DataTable />
        </QueryClientProvider>
    )
}

export default CustomerMyactions;