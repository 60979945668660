import { useEffect, useState } from "react";
import { Chart } from "react-google-charts"
import { useSnapshot } from "valtio";
import { configStore, entityStore, loginStore } from "../state/CommonState";
import Utils from "../util/Utils";
import ChartApi from "./chart.api";
import ChartBookings from "./chart.bookings";
import { ChartBlueBox, ChartBlueHeading } from "./chart.common";
import ChartContracts from "./chart.contracts";
import ChartEnquiries from "./chart.enquiries";
import ChartFinance from "./chart.finance";
import ChartNewContracts from "./chart.newcontracts";
import { ChartOthers, Combo1 } from "./chart.others";
import PercentChart from "./chart.percent";
import ChartStatus from "./chart.status";
import chartStore from "./chart.store";
import { Combo2 } from "./chart1";
import { Combo3 } from "./chart2";
import { BsFillEyeFill } from "react-icons/bs";
import "./charts.css";
const getcliptext = (t, list) => {
    // let o = "Booking #\tInvoice #\tInvoice Date\tDue Date\t"
    // if (t == '1') {
    //     o += "Invoice Amount\n"
    // } else {
    //     o += "Outstanding\tOverdue Days\n"
    // }

    let o = ""

    if(!loginStore.isClient()) o=o+"Customer\t"
    if(t!='4') o=o+"Booking #\t"
    if(t!='4') o=o+"Customer Reference #\t"
    if(t=='4'){o=o+"Voucher #\t"}else if(t=='5'){o=o+"Credit Note #\t"}else{o=o+"Invoice #\t"}
    if(t=='4'){o=o+"Receipt Date\t"}else if(t=='5'){o=o+"Credit Note Date\t"}else{o=o+"Invoice Date\t"}
    if(t!='4' && t!='5') o=o+"Due Date\t"
    if(t=='1') o=o+"Invoice Amount\t"
    if(t=='2') o=o+"Outstanding\t"
    if(t=='3') o=o+"Outstanding\tOverdue Days\t"
    if(t=='4' || t=='5') o=o+"Unmatched Credit\t"
    if(t=='4') o=o+"Remarks"+"\t"
    o=o+'\n'

    list.map(e => {
        // o += e.bookingNum + "\t" + e['Voucher No'] + "\t" + e.Date.replace('-22', '-2022') + "\t" +
        //     Utils.formatDate(new Date(e.dueDate)) + "\t"
        // if (t == '1') {
        //     o += Utils.N1(e['Amount (INR)']) + "\n"
        // } else {
        //     o += Utils.N1(e['OS Amount (INR)']) + "\t" + e.dueDays + "\n"
        // }
        if(!loginStore.isClient()){
            if(e.entity?.entityName){
                o=o+e.entity?.entityName+"\t"
            }else{
                o=o+"\t"
            } 
        } 
        if(t!='4') o=o+e.bookingNum+"\t"
        if(t!='4') o=o+e.customerReferenceNum+"\t"
        o=o+e['Voucher No']+"\t"
        o=o+e.Date.replace('-22', '-2022')+"\t"
        if(t!='4' && t!='5') o=o+Utils.formatDate(new Date(e.dueDate))+"\t"
        if(t=='1') o=o+e['OS Amount (INR)']+"\t"
        if(t=='2') o=o+e['OS Amount (INR)']+"\t"
        if(t=='3') o=o+e['OS Amount (INR)']+"\t"+e.dueDays+"\t"
        if(t=='4' || t=='5') o=o+e['OS Amount (INR)']+"\t"
        if(t=='4') o=o+e['Narration']+"\t"
        o=o+'\n'
    }
    )
    return o
}
export function FinanceList(props) {
    let t = props.type
    let list = []
    if (t == '1') list = props.results.totalList
    else if (t == '2') list = props.results.upcomingList
    else if (t == '3')list = props.results.overdueList
    else if (t == '4')list = props.results.pendinglist
    else if (t == '5')list = props.results.acctountlist
    let tableTitle = ''
    if (t == '1') tableTitle = 'Total Credit Utilized'
    else if (t == '2') tableTitle = 'Upcoming Dues'
    else if (t == '3')tableTitle = 'Overdue Amount'
    else if (t == '4')tableTitle = 'Pending Reconciliation'
    else if (t == '5')tableTitle = 'Accounting Health'
    console.log(list)
    return (
        <div style={{
           
            width: '100%',
            display: 'block', justifyContent: 'center',
            alignItems: 'center',
            overflowY:"scroll"
        }}>
            <div className="columns">
                <div className="column is-8">
                    <div className="title is-3" style={{ margin: 'auto', width: 'max-content' }}>{tableTitle}</div>
                </div>
                <div className="column is-2">
                    <button className="button is-warning" onClick={(e) => {
                        navigator.clipboard.writeText(getcliptext(t, list))
                        configStore.setModalMessage('Data copied to clipboard')
                    }}>Copy to Clipboard</button>
                </div>
                <div className="column is-1">
                    <button className="button islink"
                        onClick={(e) => {
                            props.setPopup(false)
                        }}>
                        <span className="icon ">
                            <i className="fas fa-xmark"></i>
                        </span>
                    </button>
                </div>
            </div>
            <div style={{ height: '70%',overflow:"scroll",  margin: 'auto', width: 'max-content' }}
            >
                <table className="table is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            {
                                (!loginStore.isClient()) ?
                                    <th>Customer</th>
                                    : <></>
                            }

                            {
                                (t!='4')?<th>Booking #</th>:<></>
                            }

                            
                            {
                                (t=='4')?
                                <>
                                        <th>Voucher #</th>
                                    </>:<>{
                                (t=='5')?
                                <>
                                        <th>Credit Note #</th>
                                    </>:<><th>Invoice #</th></>
                            }</>
                            }
                            {
                                (t=='4')?<th>Receipt Date</th>:<>{(t=='5')?<th>Credit Note Date</th>:<th>Invoice Date</th>}</>
                            }
                            {
                                (t!='4' && t!='5')?<th>Due Date</th>:<></>
                            }
                            
                            
                            {
                                (t == '1') ?
                                   
                                        <th>Invoice Amount</th>
                                    
                                    :<></>
                            }
                            {
                                (t=='2')?
                               
                                        <th>Outstanding</th>
                                    :<></>
                            }
                            {
                                (t=='3')?
                                <>
                                        <th>Outstanding</th>
                                        <th>Overdue Days</th>
                                    </>:<></>
                            }
                            {
                                (t=='4' || t=='5')?
                   
                                        <th>Unmatched Credit</th>
                                    :<></>
                            }
                            {
                                (t=='4')?
                   
                                        <th>Remarks</th>
                                    :<></>
                            }
                            {
                                (t!='4') ? <th>Customer Reference #</th> : <></>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            list?.map(e => {
                                return (
                                    <tr>
                                        {
                                            (!loginStore.isClient()) ?
                                                <td>{e['CUSTOMER_NAME']}</td>
                                                : <></>
                                        }
                                        {
                                (t!='4')?<td>{e['JOB_NO']}</td>:<></>
                            }
                            
                                        
                                        <td>{e['DOCUMENT_NO']}</td>
                                        <td>{e['DOCUMENT_DATE']}</td>
                                        {/* <td>{e.Date.replace('-22', '-2022')}</td> */}

                                        {

                                (t!='4' && t!='5')?<td>{e['DUE_DATE']}</td>:
                                <></>
                            }
                                        
                                        
                                        {
                                (t == '1') ?
                                    <>
                                        <td style={{ textAlign: 'right' }}>{Utils.N1(e['OUTSTANDING_LOCAL_AMOUNT'])}</td>
                                    </>
                                    :<></>
                            }
                            {
                                (t=='2')?
                                <>
                                        <td style={{ textAlign: 'right' }}>{Utils.N1(e['OUTSTANDING_LOCAL_AMOUNT'])}</td>
                                    </>:<></>
                            }
                            {
                                (t=='3')?
                                <>
                                        <td style={{ textAlign: 'right' }}>{Utils.N1(e['OUTSTANDING_LOCAL_AMOUNT'])}</td>
                                        <td style={{ textAlign: 'right' }}>{e['Over Due Days']}</td>
                                    </>:<></>
                            }
                            {
                                (t == '4' || t=='5') ?
                                    <>
                                        <td style={{ textAlign: 'right' }}>{Utils.N1(e['OUTSTANDING_LOCAL_AMOUNT'])}</td>
                                    </>
                                    :<></>
                            }
                            {
                                (t=='4')?
                   
                                <td>{e['Narration']}</td>
                                    :<></>
                            }
                            {
                                (t != '4')? <td>{e['CUSTOMER_CODE']}</td>:<></>
                            }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                {/*<button className="button islink" onClick={(e) => props.setPopup(false)}>Close</button>*/}
            </div>
        </div>
    )
}
export function ChartNewHome() {
    useSnapshot(configStore)
    useSnapshot(chartStore)
    useSnapshot(entityStore)
    const [results, setResults] = useState({})
    const [popupType, setPopupType] = useState('1')
    const [popup, setPopup] = useState(false)
    const [entList,setEntList] = useState(entityStore.list.sort((a, b) => {
        return compareStrings(a.entityName, b.entityName);
      }))
    
    function compareStrings(a, b) {
        // Assuming you want case-insensitive comparison
        a = a?.toLowerCase();
        b = b?.toLowerCase();
      
        return (a < b) ? -1 : (a > b) ? 1 : 0;
    }
    useEffect(() => {
        ChartApi.getFinanceData(setResults)
    }, [configStore.currentSelectedEntity,configStore.chartSelectedEntity])
    if (popup) {
        return <FinanceList type={popupType} results={results} setPopup={setPopup} />
    }
    if (chartStore.pieChartFull || chartStore.comboChartFull) {
        return (
            <div className="columns" style={{ height: '90vh' }}>
                <div className="column is-12" style={{height: "500px"}}>
                    <ChartBlueBox headingText='Product-wise Analysis' >
                        <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                            <ChartOthers />
                        </div>
                    </ChartBlueBox>
                </div>
            </div>
        )
    }
    return (
        <>
            <div className="columns" style={{flex:1}}>
                {
                    (configStore.currentSelectedEntity.isCreditCustomer && configStore.currentSelectedEntity.isCreditCustomer.toLowerCase() == 'no') ?
                        <div className="column is-1">
                        </div>
                        :
                        <div className="column is-3">
                            <div className="columns" style={{ height: '8%', padding: '5px' }}>
                                <ChartBlueHeading headingText='Finance'  />
                                {
                                    (!loginStore.isClient())?<div class="level-item level-left ml-6">
                                    <div class="field">
                                        <p class="control has-icons-left has-icons-right">
                                            <span class="select" style={{"width":"115px"}}> 
                                                <select name="status" id="status" style={{"padding-right": "2.5em","paddingLeft":"2.5em","maxWidth": "98%",}}
                                                  value={configStore.chartSelectedEntity} onChange={(e) => configStore.chartSelectedEntity = e.target.value}
                                                 >
                                                    <option value="ALL">ALL</option>
                                                    {
                                                       entList.map(e=>{
                                                           return  <option value={e.gst}>{e.entityName}{(e.state?' - ':'')}{e.state}</option> 
                                                       })
                                                    }
                                                    
                                                </select>



                                                
                                                 <span class="icon">
                                                    <i class="fa-solid fa-filter"></i>
                                                </span>
                                            </span>
                                        </p>
                                    </div>
                                </div>:<></>
                                }
                                
                            </div>
                            <div className="columns" style={{ height: '25%', padding: '5px', cursor: 'pointer' }}
                                onClick={(e) => {
                                    setPopup(true)
                                    setPopupType('1')
                                }}>
                                <ChartBlueBox >
                                    <div style={{ height: '10px' }}></div>
                                    <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#2c358a', marginTop: '0px' }}>
                                        {Utils.N1(results['totalCreditUtilized'])}
                                    </div>
                                    <div style={{ fontSize: '1.3rem', fontStyle: 'italic' }} id="mix1">Total Credit Utilized
                                    
                                    </div>
                                  
                                </ChartBlueBox>
                                {/* <span>
                                    <a
                                      href={
                                        process.env.REACT_APP_API_SERVER +
                                        "/api/v1/s3filestore/getfilefinance/" +
                                        "Debtors_Outstanding_Foreign_Currency_15.10.24.xlsx" +
                                        "/" +
                                        "Debtors_Outstanding_Foreign_Currency_15.10.24.xlsx"
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                      alt="">
                                    <BsFillEyeFill  />
                                    </a>
                                    </span> */}
                            </div>
                            <div className="columns" style={{ height: '25%', padding: '5px' }}
                            >
                                {
                                    (loginStore.isClient())?<ChartBlueBox >
                                    <div style={{ height: '10px' }}></div>
                                    <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#2c358a', marginTop: '0px',cursor:"pointer" }} onClick={(e) => {
                                    setPopup(true)
                                    setPopupType('4')
                                }}>
                                        {Utils.N1(results['pendingReconciliation'])}
                                    </div>
                                    <div style={{ fontSize: '1.3rem', fontStyle: 'italic',cursor:"pointer"}} id="mix2" onClick={(e) => {
                                    setPopup(true)
                                    setPopupType('4')
                                }}>Pending Reconciliation</div>
                                </ChartBlueBox>:<ChartBlueBox >
                                    <div style={{ height: '10px' }}></div>
                                    <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#2c358a', marginTop: '0px',cursor:"pointer" }} onClick={(e) => {
                                    setPopup(true)
                                    setPopupType('4')
                                }}>
                                        {Utils.N1(results['pendingReconciliation'])}
                                    </div>
                                    <div style={{ fontSize: '1.3rem', fontStyle: 'italic',cursor:"pointer" }} id="mix3" onClick={(e) => {
                                    setPopup(true)
                                    setPopupType('4')
                                }}>Pending Reconciliation</div>
                                    <div style={{ fontSize: '1.3rem', fontStyle: 'italic',cursor:"pointer"}} id="mix4"
                                    onClick={(e) => {
                                        setPopup(true)
                                        setPopupType('5')
                                    }}
                                    >Accounting Health</div>
                                </ChartBlueBox>
                                }
                            </div>
                            <div className="columns" style={{ height: '25%', padding: '5px', cursor: 'pointer' }}
                                onClick={(e) => {
                                    setPopup(true)
                                    setPopupType('2')
                                }}>
                                <ChartBlueBox >
                                    <div style={{ height: '10px' }}></div>
                                    <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#2c358a', marginTop: '0px' }}>
                                        {Utils.N1(results['upcomingDue'])}
                                    </div>
                                    <div style={{ fontSize: '1.3rem', fontStyle: 'italic' }} id="mix5">Upcoming Dues</div>
                                </ChartBlueBox>
                            </div>
                            <div className="columns" style={{ height: '25%', padding: '5px', cursor: 'pointer' }}
                                onClick={(e) => {
                                    setPopup(true)
                                    setPopupType('3')
                                }}>
                                <ChartBlueBox>
                                    <div style={{ height: '10px' }}></div>
                                    <div style={{ fontSize: '2rem', fontWeight: 'bold', color: '#f00', marginTop: '0px' }}>
                                        {Utils.N1(results['overdueAmount'])}
                                    </div>
                                    <div style={{ fontSize: '1.3rem', fontStyle: 'italic'}} id="mix6">Overdue Amount</div>
                                </ChartBlueBox>
                            </div>
                        </div>
                }
                <div className="column is-9">
                    <div className="columns" style={{ height: '25%', padding: '5px' }}>
                        <ChartBlueBox headingText='Bookings' >
                            <ChartBookings />
                        </ChartBlueBox>
                    </div>
                    <div className="columns" style={{ height: '25%', padding: '5px' }}>
                        <ChartBlueBox headingText='Contracts'>
                            <ChartNewContracts />
                        </ChartBlueBox>
                    </div>
                    <div className="columns" style={{ flex : 1, padding: '5px' }}>
                        <ChartBlueBox headingText='Product-wise Analysis' >
                            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                                <ChartOthers />
                            </div>
                        </ChartBlueBox>
                    </div>
                </div>
            </div>
        </>
    )
}
