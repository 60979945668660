import { subscribe, useSnapshot } from "valtio";
import { useEffect, useState } from "react";
import { bookingsStore, configStore } from "../state/CommonState";
import TaskDatetime from "./task-datetime";
import Taskfiledisplay from "./task-file-display";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import { Col12, Col4 } from "../Controls";
import CoiCreationPreview from "./act-coi-preview";
import { CoiCreation1 } from "../state/CommonState";
import { CoiCreationValidation } from "../state/validation-stores";
import { myactionsstore } from "../my-actions/store-myactions";
import Api from "../state/Api";
import { enquiryStore } from "../enquiry/enquiry-data";
import { rtpStore } from "../pricing/pricing-store";
import { DestinationSwitch,  OriginSwitch1 } from "../components/ag-switch";
import Newapi from "../state/new-api";
import Utils from "../util/Utils";
import Taskfiletable1 from "./task-file-table1";


export function PricingAddress21(props) {
  console.log("Sx",props)
  const { label, record, name, callback = f => f, isValid = true, validationMsg, isDisabled } = props
  const imRecord = useSnapshot(record)
  const [list, setList] = useState([])
  const [selectVisible, setSelectVisible] = useState('hidden')
  const [addresstext, setAddresstext] = useState('')
  useSnapshot(enquiryStore)
  useSnapshot(rtpStore)

  let shipmentType = enquiryStore.current.shipmentType.slice(0,3)
  console.log("shipmentType",shipmentType)
  useEffect(() => {
      if (label == 'Origin') record[name] = ''
      setSelectVisible('hidden')
  }, [enquiryStore.current.originType])
  useEffect(() => {
      if (label != 'Origin') record[name] = ''
      setSelectVisible('hidden')
  }, [enquiryStore.current.destinationType])
  useEffect(() => {
      console.log("addresstext",addresstext)
      if(addresstext && addresstext.length>2){
      const getData = setTimeout(() => {
          let s = encodeURI(addresstext)
          console.log("s",s)
          let product = "LCL"
          if(shipmentType.toLowerCase().indexOf('fcl')!=-1) product = "FCL"
          if(shipmentType.toLowerCase().indexOf('air')!=-1) product = "Air"
          let url = `${process.env.REACT_APP_API_SERVER}/api/v1/location/fetchlocation4product/${product}/${s}`
          let loctype = enquiryStore.current.originType
          if (label != 'Origin') loctype = enquiryStore.current.destinationType
          if (loctype != 'Port') url = `${process.env.REACT_APP_API_SERVER}/api/v1/location/fetchaddress2/${s}`
          let results
          (async () => {
              console.log("Testing....")
              results = await Newapi.get(url)
              if (results.error)
                  setList([])
              else
                  setList(results)
              console.log('RESULTS=', results)
          })()
      }, 300)
      return () => clearTimeout(getData)
  }
  }, [addresstext])

  const setLocation = (s, pureset = false) => {
      record[name] = s
      setAddresstext(s)
  }
  let doorLabel = 'Door'
  if (true) {
      if (label == 'Origin') doorLabel = 'Ex-Works'
      else doorLabel = 'Door Delivery'
  }
  let Choicecomp = OriginSwitch1
  if (label != 'Origin') Choicecomp = DestinationSwitch
  return (
      <div className="field"

      >
          <div className="columns" style={{ alignItems: 'left' }}>
              <div className="column is-12 " style={{ alignItems: 'left',  marginLeft:"20px", marginTop:"10px" }}>
              <Choicecomp  {...props}/>
              </div>
          </div>
          <div className="columns"
          >
              <div className="column is-12 p-0">
                  <textarea rows='3' style={{ height: '3rem', marginLeft:"20px",width:"260px"}}
                      autoComplete="off"
                      onClick={() => setSelectVisible('hidden')}
                      onKeyDown={() => setSelectVisible('visible')}
                      {...props} value={imRecord[name]}
                      disabled={isDisabled}
                      //{...isDisabled}
                      onFocus={() => setSelectVisible('visible')}
                      onChange={(e) => {
                          setLocation(e.target.value)
                          callback(e.target.value)
                          if (e.target.value == 'distance') rtpStore.distanceButtonVisible = true
                          if (e.target.value == 'hidedistance') rtpStore.distanceButtonVisible = false
                      }}
                      className={isValid ? "input is-small" : "input is-small is-danger"} />
                  <div style={{ width: '100%', visibility: selectVisible }}>
                      <select style={{
                          width: '100%',
                          border: '1px solid white',
                          overflowWrap: 'break-word',
                          whiteSpace: 'normal',
                           marginLeft:"20px",
                           width:"260px"
                      }}
                          size={selectVisible == 'visible' ? 3 : 0}
                          value={imRecord[name]}
                          onChange={(e) => {
                              console.log('selected value=', e.target.value)
                              setLocation(e.target.value)
                              setSelectVisible('hidden')
                          }}>
                          <option value=''></option>
                          {
                              list.map(e => <option style={{
                                  overflowWrap: 'break-word',
                                  whiteSpace: 'normal',
                                  border: '1px solid lightgrey'
                              }} value={e}>{e}</option>)
                          }
                      </select>
                  </div>
              </div>
          </div>
      </div>
  )
}

export default function CoiCreation(props) {
  useSnapshot(taskStore);
  useSnapshot(bookingsStore);
  useSnapshot(enquiryStore)
  useSnapshot(configStore);

  let brec = bookingsStore.current;

  useSnapshot(configStore);
  let task = taskStore.current;
  let stage = task.stage;
  let enq = enquiryStore.current
  const [disableButton, setDisableButton] = useState(true);
  const [showCharges, setShowCharges] = useState();
  const [invoiceData, SetInvoiceData] = useState();
  const [origin, SetOrigin] = useState(null);
  const [destination, SetDestination] = useState(null);
  const [insuranceAmount, SetInsuranceAmount] = useState(0);
  const [validation,setValidation] = useState(false);

  function check() {
    let disable = false;
    let reject = false;
    let checkfile = [];

    taskStore.current.files.map((e) => {
      if (e.approved == "Reject") {
        reject = true;
      }
      if (checkfile.indexOf(e.label) == -1) {
        if (
          taskStore.current.original_file.indexOf(e.label) != -1 &&
          e.filename.length == 0
        ) {
          disable = true;
        } else {
          if (taskStore.current.original_file.indexOf(e.label) != -1) {
            checkfile.push(e.label);
          }
        }
      }
    });
    setDisableButton(disable);
  }
  useEffect(() => {
    check();
    let unsubscribe = subscribe(taskStore.current, () => {
      check();
    });
    return unsubscribe;
  }, [taskStore.current]);
  useEffect(() => {
    console.log("useEffect is called..");
    CoiCreationValidation.CoiCreationValidMap[
      "riskcommencementlocation"
    ] = true;
    CoiCreationValidation.CoiCreationValidMap["riskendinglocation"] = true;

    CoiCreation1.riskcommencementlocation = "";
    CoiCreation1.riskendinglocation = "";
    if(brec.insurance_Amount_currency_type=="USD"){
      SetInsuranceAmount(parseInt(brec?.insurance_Amount)*configStore.getExchangeRate("USD")
  )
    } else{
      SetInsuranceAmount(parseInt(brec?.insurance_Amount))

    }
    configStore.coiModalVisible = false;

    SetOrigin(brec.contract.origin);
    SetDestination(brec.contract.destination);

  }, []);
  useEffect(() => {}, [ showCharges]);
  useEffect(() => {
    if(task.shipmentScope.split("-")[0]==enq.originType){
      CoiCreation1.riskcommencementlocation = origin;
    }
    if(task.shipmentScope.split("-")[2]==enq.destinationType){
      CoiCreation1.riskendinglocation = brec.contract.destination;
    }
    console.log("insuranceAmount",insuranceAmount)
    CoiCreation1.commericalInvoiceValue =insuranceAmount

  }, [origin, destination, enq.originType,enq.destinationType,insuranceAmount]);

  const _params = (label, name, type = "text", dataList = []) => {
    return {
      type,
      name,
      label,
      record: CoiCreation1,
      dataList,
    };
  };
  console.log("DS", invoiceData);
  console.log("DSx", CoiCreation1);

  if (stage === 1) {
    return (
      <div className="box">
        {configStore.coiModalVisible?<CoiCreationPreview></CoiCreationPreview>:""}
        <div className="columns">
          <Col4 style={{ width: "36.3333%" }}>
            <label
              style={{
                fontSize: "large",
                fontWeight: "bold",
              }}
            >
              * Risk Commencement Location
            </label>
            {task.shipmentScope.split("-")[0]==enq.originType? <PricingAddress21
              {..._params("", "riskcommencementlocation")}
              label="Origin"
              record={CoiCreation1}
              name="riskcommencementlocation"
              isDisabled="true"
        
            />: <PricingAddress21
            {..._params("", "riskcommencementlocation")}
            label="Origin"
            record={CoiCreation1}
            name="riskcommencementlocation"
      
          />}
          </Col4>
          <Col4>
            <label
              style={{
                fontSize: "large",
                fontWeight: "bold",
              }}
            >
              * Risk Ending Location
            </label>
            {task.shipmentScope.split("-")[2]==enq.destinationType? <PricingAddress21
              {..._params("", "riskendinglocation")}
              label="Destination"
              record={CoiCreation1}
              name="riskendinglocation"
              isDisabled={true}
            />: <PricingAddress21
            {..._params("", "riskendinglocation")}
            label="Destination"
            record={CoiCreation1}
            name="riskendinglocation"
          />}
           
          </Col4>
        </div>
        <div className="columns">
        <Col12>
        <Taskfiletable1 additionalField={true} commercialInvoice={true} validation={validation}/>
        </Col12>
        </div>
        <div className="columns" >
          <Col4 style={{ width: "36.3333%" }}>
            <label
              style={{
                fontSize: "large",
                fontWeight: "bold",
              }}
            >
              Total Sum Insured :{" "+Utils.N(CoiCreation1.commericalInvoiceValue, "INR")}
            </label>

           
          </Col4>
        </div>

        <p>
          <span>
            <button
              style={{ float: "left" }}
              className="button islink"
              disabled={disableButton}
              onClick={async () => {
                let v = false
                for (let i = 0; i < task.files.length; i++) {
                  let l = task.files[i]
                  if(l.filename.length==0 || l.invoiceNo.length==0 && l.invoiceDate.length==0){
                    v = true
                    i = task.files.length+1
                  }
                }
                if(v!=validation){
                  setValidation(v)
                }
                if (
                  CoiCreation1.riskcommencementlocation.length > 0 &&
                  CoiCreation1.riskendinglocation.length > 0 &&
                  (CoiCreation1.commericalInvoiceValue).toString().length > 0 &&
                  !v
                ) {
                  task.lastName = "";
                  task.descriptionOfPackaging = "";
                  task.phoneNo = "";
                  task.email = "";
                  task.invoiceDate = "";
                  task.journeyDate = "";
                  task.invoiceNo = "";
                  task.psd = "";
                  task.containerized = "";
                  task.containersNo = "";
                  task.dateofCommencementofVoyage = "";
                  myactionsstore.error = [];

                  configStore.coiModalVisible = true;
                } else {
                  if (CoiCreation1.riskcommencementlocation.length == 0) {
                    CoiCreationValidation.CoiCreationValidMap[
                      "riskcommencementlocation"
                    ] = false;
                    setShowCharges(
                      CoiCreationValidation.CoiCreationValidMap[
                        "riskcommencementlocation"
                      ]
                    );
                  }
                  if (CoiCreation1.riskendinglocation.length == 0) {
                    CoiCreationValidation.CoiCreationValidMap[
                      "riskendinglocation"
                    ] = false;
                    setShowCharges(
                      CoiCreationValidation.CoiCreationValidMap[
                        "riskendinglocation"
                      ]
                    );
                  }
                  if (CoiCreation1.commericalInvoiceValue == 0) {
                    configStore.setModalMessage("Total Sum Insured is 0.")
                  }
                }
              }}
            >
              Preview
            </button>
          </span>
        </p>
      </div>
    );
  }
  if (stage > 1) {
    console.log("Stage 2..........");
    return <Taskfiledisplay />;
  }
}
export function CoiCreationTrigger(props) {
  const [invoiceData, setInvoiceData] = useState(null);
  const [disableButton, setDisableButton] = useState(true);
  console.log("props",props)
  useSnapshot(taskStore); 
  useSnapshot(configStore); 

  const task = taskStore.current;
  const stage = task?.stage; 
  console.log(task)
  useEffect(() => {
    if (task?.bookingNum) {
      Api.getInvoiceData(
        { bookingNum: task.bookingNum },
        async (data) => {
          setInvoiceData(data.data);
        }
      );
    }
  }, [task?.bookingNum]); // Add task.bookingNum as a dependency if it changes

  useEffect(() => {
    const unsubscribe = subscribe(taskStore.current, () => {
      if (taskStore.current.dueDate.length > 0) {
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
    });
    return unsubscribe;
  }, [taskStore.current]);
  let file = [{
    label: 'Commercial Invoice',
    filename: '',
    invoiceNo: '' ,
    invoiceDate: '',
    approved: '',
    rejectReason: ''
}]
  useEffect(()=>{
    if(invoiceData && invoiceData?.files && invoiceData?.files.length>0){
      let l = []
      for (let i = 0; i < invoiceData?.files.length; i++) {
        if(invoiceData?.files[i]?.label=="Commercial Invoice"){
          l.push(
            {
              label: 'Commercial Invoice',
              filename: invoiceData?.files[i]?.filename,
              invoiceNo: '' ,
              invoiceDate: '',
              approved: '',
              rejectReason: ''
            }
          )
        }
      }
      if(l.length>0){
        taskStore.current.files = l
      }else{
        taskStore.current.files = file
      }
    }else{
      taskStore.current.files = file
    }
  },[invoiceData])

  return invoiceData && invoiceData?.status == "PENDING" ? (
    <div>
      <h1 style={{ color: "red", margin: "10px" }}>
        Please complete the 'Commercial Invoice & Packing List Pending' My Action first before triggering the COI
        Creation (Insurance) My Action.
      </h1>
    </div>
  ) : (
    <>
      <TaskDatetime />
      <br />
      <br />
      <br />
      <p>
        <button
          className="button islink"
          disabled={disableButton}
          onClick={async (e) => {
            taskStore.current.stage = 1;
            taskStore.current.pendingWith = "CUSTOMER";
            //await Taskapi.getEmailsForEntity(taskStore.current.gst)
            setDisableButton(true)
            await Taskapi.saveTask(taskStore.current);
            taskStore.triggerActionVisible = false;
            taskStore.myActionVisible = false;
          }}
        >
          Trigger Action
        </button>
      </p>
    </>
  );
}
