import { subscribe, useSnapshot } from "valtio"
import { useEffect, useRef, useState } from "react"
import { AgmodalStore, bookingsStore, configStore, dataListStore, loginStore } from "../state/CommonState";
import TaskDatetime from "./task-datetime";
import Taskfileapproval, { validateFileApproval } from "./task-file-approval";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import MonApi from "../state/mongo-api";
import { loader } from "../util/loader";
import { AgSelectWithMap } from "../components/AgCustomComponents";
import Api from "../state/Api";
import Utils from "../util/Utils";
import { OneLabel } from "./act-common";

export default function Cro(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    useSnapshot(AgmodalStore)
    let task = taskStore.current
    let stage = task.stage

    if (stage >= 1) {
        return (
            <div className="box">
                <p>Please download your Carrier Release Order (CRO)</p>
                {
                    (taskStore.current.mlo!=undefined && taskStore.current.mlo=='yes')?<p>Mainline Operator : {task.carrier}</p>:""
                }
                {
                    (taskStore.current.carrier_booking_ref_number!=undefined && taskStore.current.carrier_booking_ref_number.trim().length>0)?<p>Carrier Booking Reference# : {task.carrier_booking_ref_number}</p>:""
                }
                {
                    (taskStore.current.mbl_number!=undefined && taskStore.current.mbl_number.trim().length>0)?<p>Carrier MBL Number : {task.mbl_number}</p>:""
                }
                <p>Please note that the same is Valid Till {task.validTill}</p>
                <Taskfiledisplay />
                <OneLabel label='ETD@POL' name='etd_at_pol' /><br/>
                <OneLabel label='VGM Cutoff' name='vgm_cut_off' /><br/>
                <OneLabel label='Gate Cutoff' name='gate_cut_off' /><br/>
                <OneLabel label='SI Cutoff' name='shipping_instructions_cut_off' /><br/>
                <OneLabel label='SB Cutoff' name='shipbill_cutoff' /><br/>
                <OneLabel label='Gate Opening' name='gate_opening' /><br/>
                {task.isf_cutoff?<OneLabel label='ISF Cutoff' name='isf_cutoff' />:<></>}<br/>
                {task.ams_cutoff?<OneLabel label='AMS Cutoff' name='ams_cutoff' />:<></>}
                <p>
                    <span>
                        {
                            (stage === 1) ?
                                <button style={{ float: 'left' }} className="button islink"
                                    onClick={async (e) => {
                                        taskStore.current.stage = 2
                                        taskStore.current.pendingWith = 'AGRAGA'
                                        taskStore.setComplete()
                                        taskStore.setCustomerComplete()
                                        await Taskapi.updateTask(taskStore.current)
                                        taskStore.triggerActionVisible = false
                                        taskStore.myActionVisible = false
                                        if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                            AgmodalStore.pageVisible = false
                                            AgmodalStore.apiCall = true
                                        }
                                    }}>Acknowledge</button>
                                : <></>
                        }
                    </span>
                </p>
            </div>
        )
    }
}
export function CroTrigger(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    useSnapshot(loader)
    useSnapshot(bookingsStore)
    let task = taskStore.current
    const [carrierlist, setCarrierlist] = useState([])
    const [cursor, setCursor] = useState(0)
    const ref = useRef(null)
    useEffect(() => {
        const input = ref.current
        if (input) {
            input.setSelectionRange(cursor, cursor)
        }
    }, [ref, cursor])
    const check = async () =>{
        try {
            if(carrierlist.length==0){
                loader.show()
                let a = ""
                let b = false
                if(bookingsStore.current.contract && bookingsStore.current.contract.carrier){
                    a = bookingsStore.current.contract.carrier.split('(')
                    if(a.length>0){
                        a = a[a.length-1]
                        a = a.replace(')','')
                        a = a.toLowerCase()
                    }
                }
                if(a.length==0){
                    b=true
                }
                let r = await MonApi.aget(`${process.env.REACT_APP_API_SERVER}/api/v1/dataList/getFCLCarrierList`)
                let c = []
                for (let i = 0; i < r.length; i++) {
                    if(r[i]['MLO or NVOCC']=='NVOCC'){
                        if(r[i]['SCAC code'].toLowerCase()==a && b==false){
                            b=true
                        }
                    }else{
                        c.push(r[i])
                    }
                }
                setCarrierlist(c)
                if(b){
                    taskStore.current.mlo = 'yes'
                    taskStore.current.carrier = ""
                }else{
                    taskStore.current.mlo = 'no'
                }
                loader.hide()
                console.log(r)
            }
        } catch(error) {
            loader.hide()
            console.log(error)
        }
    }
    const close = async()=>{
        taskStore.current.stage = 1
        taskStore.current.pendingWith = 'CUSTOMER'
        await Taskapi.saveTask(taskStore.current)
        taskStore.triggerActionVisible = false
        taskStore.myActionVisible = false
        loader.hide()
    }
    useEffect(()=>{
        check()
    },[bookingsStore.current])
    const getFormattedDateTime = (dateTime,data) => {
        const now = new Date(dateTime); 
        const pastDate = new Date(now.getTime() - data * 60 * 60 * 1000); 
      
        const day = String(pastDate.getDate()).padStart(2, '0');
        const month = String(pastDate.getMonth() + 1).padStart(2, '0'); 
        const year = pastDate.getFullYear();
      
        const hours = String(pastDate.getHours()).padStart(2, '0');
        const minutes = String(pastDate.getMinutes()).padStart(2, '0');
        const seconds = String(pastDate.getSeconds()).padStart(2, '0');
      
        return `${year}-${month}-${day}T${hours}:${minutes}`;      
    };
    useEffect(() => {
        async function fetch(){
            const ret =  await MonApi.apost(`${process.env.REACT_APP_API_SERVER}/api/v1/common/executequery/Bookingdsr`,{filter:{_id:task.bookingNum},project:{}})
            task.gate_cut_off = Utils.formatToDate1(ret[0]?.gate_cut_off??'')
            task.vgm_cut_off = Utils.formatToDate1(ret[0]?.vgm_cut_off??'')
            task.shipping_instructions_cut_off = Utils.formatToDate1(ret[0]?.shipping_instructions_cut_off??'')
            task.shipbill_cutoff = Utils.formatToDate1(ret[0]?.shipbill_cutoff??'')
            task.gate_opening = Utils.formatToDate1(ret[0]?.gate_opening??'')
            task.isf_cutoff = Utils.formatToDate1(ret[0]?.isf_cutoff??'')
            task.ams_cutoff = Utils.formatToDate1(ret[0]?.ams_cutoff??'')
            task.etd_at_pol = Utils.formatToDate1(ret[0]?.etd_at_pol??'') 
        }
        fetch()
        if(taskStore.current.disable==undefined){
            taskStore.current.disable = false
        }
        if(taskStore.current != undefined && taskStore.current.carrier_type==undefined){
            taskStore.current.carrier_type = ""
            taskStore.current.carrier_booking_ref_number = ""
            taskStore.current.mbl_number = ""
        }
        let unsubscribe = subscribe(taskStore.current, () => {
            let disable = false
            if (taskStore.current.etd_at_pol === ''||taskStore.current.validTill.length == 0 || taskStore.current.mlo==undefined || (taskStore.current.mlo=='yes' && taskStore.current.carrier != undefined && taskStore.current.carrier.length==0) || (taskStore.current.carrier_type.trim().length!=0 && taskStore.current.mbl_number.trim().length==0 && taskStore.current.carrier_booking_ref_number.trim().length==0)) {
                disable = true
            }
            if(!disable){
                let checkfile = []
                taskStore.current.files.map((e) =>{
                    if(checkfile.indexOf(e.label) == -1){
                        if(taskStore.current.original_file.indexOf(e.label) != -1 && e.filename.length==0){
                            if(disable==false){
                                disable = true
                            }
                        }else{
                            if(taskStore.current.original_file.indexOf(e.label) != -1){
                                checkfile.push(e.label)
                            }
                        }
                    }
                })
            }
            if(disable != taskStore.current.disable){
                taskStore.current.disable = disable
            }
            // if((task.gate_cut_off === '' && task.etd_at_pol!=='')||(task.gate_cut_off && task.etd_at_pol)) task.gate_cut_off = getFormattedDateTime(task.etd_at_pol,48)
            // if((task.vgm_cut_off === '' && task.gate_cut_off!='')||(task.vgm_cut_off && task.gate_cut_off)) task.vgm_cut_off = getFormattedDateTime(task.gate_cut_off,48)
            // if((task.shipping_instructions_cut_off === '' && task.gate_cut_off!='')||(task.shipping_instructions_cut_off && task.gate_cut_off)) task.shipping_instructions_cut_off = getFormattedDateTime(task.gate_cut_off,bookingsStore.current.contract.destination.includes('United States')?96:48)
            // if((task.shipbill_cutoff === '' && task.gate_cut_off!='')||(task.shipbill_cutoff && task.gate_cut_off)) task.shipbill_cutoff = getFormattedDateTime(task.gate_cut_off,24)
            // if((task.gate_opening === '' && task.etd_at_pol!=='')||(task.gate_opening && task.etd_at_pol)) task.gate_opening =  getFormattedDateTime(task.etd_at_pol,120)
            // if((task.isf_cutoff === '' && task.etd_at_pol!=='' &&  bookingsStore.current.contract.destination.includes('United States'))||(task.isf_cutoff && task.etd_at_pol &&  bookingsStore.current.contract.destination.includes('United States'))) task.isf_cutoff =  getFormattedDateTime(task.etd_at_pol,48)
            // if((task.ams_cutoff === '' && task.etd_at_pol!=='' &&  bookingsStore.current.contract.destination.includes('United States'))||(task.ams_cutoff && task.etd_at_pol &&  bookingsStore.current.contract.destination.includes('United States'))) task.ams_cutoff =  getFormattedDateTime(task.etd_at_pol,48)
        })
        return unsubscribe
    }, [taskStore.current])  
    return (
        <>
            {/* <TaskDatetime /> */}
            <hr />
            <Taskfiletable />
            <hr />
            <div className="columns" >
                <div className="column is-3">
                    <div className="field">
                        <label className="label is-small" style={{ marginBottom: "0px" }}>
                        Valid Till *
                        </label>
                        <div className="control is-fullwidth is-small">
                            <input type='date' className="input is-small" value={task.validTill} onChange={(e) => task.validTill = e.target.value} />
                        </div>
                    </div>
                </div>
                {
                    (taskStore.current.mlo!=undefined && taskStore.current.mlo=='yes')?<div className="column is-3">
                        <AgSelectWithMap label={"Mainline Operator *"}
                  record={taskStore.current}
                  name="carrier"
                  dataMap={carrierlist.map((carrier) => ({
                    key: carrier["Display Name"],
                    value: `${carrier["Display Name"]} (${carrier["SCAC code"]})`,
                  }))}/>
                    </div>:""
                }
                <div className="column is-3">
                        <AgSelectWithMap label={"Select Type"}
                        callback={(e)=>{
                            if(taskStore.current.carrier_type == ""){
                                taskStore.current.mbl_number = ""
                                taskStore.current.carrier_booking_ref_number = ""
                            }
                            if(taskStore.current.carrier_type == "carrier_booking_reference_#" && taskStore.current.mbl_number.length>0){
                                taskStore.current.mbl_number = ""
                            }
                            if(taskStore.current.carrier_type == "carrier_mbl_number" && taskStore.current.carrier_booking_ref_number.length>0){
                                taskStore.current.carrier_booking_ref_number = ""
                            }
                        }}
                  record={taskStore.current}
                  name="carrier_type"
                  dataMap={[{
                    key: "Select an option",
                    value: "",
                  },{
                    key: "Carrier Booking Reference#",
                    value: "carrier_booking_reference_#",
                  },{
                    key: "Carrier MBL Number",
                    value: "carrier_mbl_number",
                  }]}/>
                    </div>
                    {
                        (taskStore.current && taskStore.current.carrier_type=='carrier_booking_reference_#')?
                        <div className="column is-3">
                    <div className="field">
                        <label className="label is-small" style={{ marginBottom: "0px" }}>
                        Carrier Booking Reference# *
                        </label>
                        <div className="control is-fullwidth is-small">
                            <input ref={ref} type='text' className="input is-small" value={task.carrier_booking_ref_number} onChange={(e) => {
                                setCursor(e.target.selectionStart)
                                task.carrier_booking_ref_number = e.target.value
                                }} />
                        </div>
                    </div>
                </div>:""}
                {
                        (taskStore.current && taskStore.current.carrier_type=='carrier_mbl_number')?
                <div className="column is-3">
                <div className="field">
                    <label className="label is-small" style={{ marginBottom: "0px" }}>
                    Carrier MBL Number *
                    </label>
                    <div className="control is-fullwidth is-small">
                        <input ref={ref} type='text' className="input is-small" value={task.mbl_number} onChange={(e) => {
                            setCursor(e.target.selectionStart)
                            task.mbl_number = e.target.value
                        }} />
                    </div>
                </div>
            </div>:""
                    }
            </div>
            <div className="columns gap-4" >
                <div className="field">
            <label className="label is-small" style={{ marginBottom: "0px" }}>
            ETD @ POL *
            </label>
            <div className="control is-fullwidth is-small">
                    <input type='datetime-local' className="input is-small" value={task.etd_at_pol} onChange={(e) => {
                        task.etd_at_pol = e.target.value
                        if(task.gate_cut_off === '' && task.etd_at_pol!=='') task.gate_cut_off = getFormattedDateTime(task.etd_at_pol,48)
                        if(task.vgm_cut_off === '' && task.gate_cut_off!='') task.vgm_cut_off = getFormattedDateTime(task.gate_cut_off,48)
                        if(task.shipping_instructions_cut_off === '' && task.gate_cut_off!='') task.shipping_instructions_cut_off = getFormattedDateTime(task.gate_cut_off,bookingsStore.current.contract.destination.includes('United States')?96:48)
                        if(task.shipbill_cutoff === '' && task.gate_cut_off!='') task.shipbill_cutoff = getFormattedDateTime(task.gate_cut_off,24)
                        if(task.gate_opening === '' && task.etd_at_pol!=='') task.gate_opening =  getFormattedDateTime(task.etd_at_pol,120)
                        if(task.isf_cutoff === '' && task.etd_at_pol!=='' &&  bookingsStore.current.contract.destination.includes('United States')) task.isf_cutoff =  getFormattedDateTime(task.etd_at_pol,48)
                        if(task.ams_cutoff === '' && task.etd_at_pol!=='' &&  bookingsStore.current.contract.destination.includes('United States')) task.ams_cutoff =  getFormattedDateTime(task.etd_at_pol,48)
                        }} />
            </div>
            </div>
            <div className="field">
            <label className="label is-small" style={{ marginBottom: "0px" }}>
            VGM Cutoff 
            </label>
            <div className="control is-fullwidth is-small">
                    <input type='datetime-local' className="input is-small" value={task.vgm_cut_off} onChange={(e) => task.vgm_cut_off = e.target.value} />
            </div>
            </div>                    
            <div className="field">
            <label className="label is-small" style={{ marginBottom: "0px" }}>
            Gate Cutoff 
            </label>    
            <div className="control is-fullwidth is-small">
                    <input type='datetime-local' className="input is-small" value={task.gate_cut_off} onChange={(e) => task.gate_cut_off = e.target.value} />
            </div>
            </div>
            <div className="field">
            <label className="label is-small" style={{ marginBottom: "0px" }}>
            Shipping Instructions Cut-off 
            </label>
            <div className="control is-fullwidth is-small">
                    <input type='datetime-local' className="input is-small" value={task.shipping_instructions_cut_off} onChange={(e) => task.shipping_instructions_cut_off = e.target.value} />
            </div>
            </div>
            
            </div>
{/* check */}
            <div className="columns gap-4" >

            <div className="field">
            <label className="label is-small" style={{ marginBottom: "0px" }}>
            Shipping Bill Cut-off 
            </label>
            <div className="control is-fullwidth is-small">
                    <input type='datetime-local' className="input is-small" value={task.shipbill_cutoff} onChange={(e) => task.shipbill_cutoff = e.target.value} />
            </div>
            </div>
            <div className="field">
            <label className="label is-small" style={{ marginBottom: "0px" }}>
            Gate Opening 
            </label>
            <div className="control is-fullwidth is-small">
                    <input type='datetime-local' className="input is-small" value={task.gate_opening} onChange={(e) => task.gate_opening = e.target.value} />
            </div>
            </div>
            {bookingsStore.current.contract.destination.includes('United States')?
            <div className="field">
            <label className="label is-small" style={{ marginBottom: "0px" }}>
            ISF Cut-off 
            </label>
            <div className="control is-fullwidth is-small">
                    <input type='datetime-local' className="input is-small" value={task.isf_cutoff} onChange={(e) => task.isf_cutoff = e.target.value} />
            </div>
            </div>:<></>}
            {
            bookingsStore.current.contract.destination.includes('United States')?
            <div className="field">
            <label className="label is-small" style={{ marginBottom: "0px" }}>
            AMS Cut-off 
            </label>
            <div className="control is-fullwidth is-small">
                    <input type='datetime-local' className="input is-small" value={task.ams_cutoff} onChange={(e) => task.ams_cutoff = e.target.value} />
            </div>
            </div>
            :<></>

            }
            
            
            </div>
            <p>
                <button className="button islink" disabled={taskStore.current.disable}
                    onClick={async (e) => {
                        loader.show()
                        if(taskStore.current.mlo!=undefined && taskStore.current.mlo=='yes' && taskStore.current.carrier.length>0){
                            bookingsStore.current.contract.carrier = taskStore.current.carrier
                            let ret = await Taskapi.replaceBooking(bookingsStore.current)
                        }
                        let ret = await Taskapi.getbookingdsr(taskStore.current.bookingNum)
                        ret[0]['gate_cut_off'] = task.gate_cut_off.split('T')[0]
                        ret[0]['vgm_cut_off'] = task.vgm_cut_off.split('T')[0]
                        ret[0]['shipping_instructions_cut_off']=task.shipping_instructions_cut_off.split('T')[0]
                        ret[0]['shipbill_cutoff'] = task.shipbill_cutoff.split('T')[0]
                        ret[0]['gate_opening']=task.gate_opening.split('T')[0]
                        ret[0]['isf_cutoff']=task.isf_cutoff.split('T')[0]    
                        ret[0]['ams_cutoff'] = task.ams_cutoff.split('T')[0]
                        ret[0]['etd_at_pol'] =task.etd_at_pol.split('T')[0]
                        if(taskStore.current.carrier_type.trim().length!=0 && taskStore.current.carrier_booking_ref_number!=undefined && taskStore.current.mbl_number!=undefined && (taskStore.current.carrier_booking_ref_number.length>0 || taskStore.current.mbl_number.length>0)){
                            // let ret = await Taskapi.getbookingdsr(taskStore.current.bookingNum)
                            if(ret.length>0){
                                if(taskStore.current.mbl_number.length>0){
                                    ret[0]['mbl_number'] = taskStore.current.mbl_number
                                }else{
                                    ret[0]['carrier_booking_ref_number'] = taskStore.current.carrier_booking_ref_number
                                }
                                Api.apieditdsr(ret[0],close)
                            }
                        }else{                            
                                Api.apieditdsr(ret[0],close)
                            }
                    }}>Trigger Action</button>
            </p>
        </>
    )
}