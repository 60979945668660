import { useSnapshot } from "valtio";
import { myactionsstore } from "./store-myactions";
import { configStore } from "../state/CommonState";
import { useEffect, useRef, useState } from "react";
import MultiSelect from "../port-to-port-schedules/multi-select";
import MonApi from "../state/mongo-api";
import { options } from "../charts/chart1";

    
export function ExceptionModal(props){
    useSnapshot(myactionsstore);
    useSnapshot(configStore);
    let ret = configStore.modalvisibledata;
    const [notifyCustomer, setNotifyCustomer] = useState(ret?.exceptionData?.notifyCustomer ? ret.exceptionData.notifyCustomer : false);
    const [optionSelected, setSelected] = useState(ret?.exceptionData?.exceptionlist ? ret.exceptionData.exceptionlist : []);
    const [remarks, setRemarks] = useState(ret?.exceptionData?.remarks ? ret.exceptionData.remarks : '');
    const [Options, setOptions] = useState([]);

    const fetchFields = async () => {
        try {
            const url = `${process.env.REACT_APP_API_SERVER}/api/v1/common/visibility/exceptions`;
            const response = await MonApi.apost(url, {});

            if (response && response.allFields) {
                setOptions(response.allFields.map(item => ({
                    value: item,
                    label: item
                })));
            }
        } catch (error) {
            console.error("Error fetching fields:", error);
        }
    };
    
    useEffect(() =>{
        fetchFields()
    }, [])

    const handleChange = (selected) => {
        setSelected(selected);
    };

    const onsubmit = () => {
        let li = Object.keys(ret)
        let lvs = {}
        for (let i = 0; i < li.length; i++) {
            lvs[li[i]] = ret[li[i]]
        }
        let exceptionlistArr = [];
        optionSelected.map((ele) => exceptionlistArr.push(ele));
        let exceptionData = {
            exceptionlist: exceptionlistArr,
            notifyCustomer: notifyCustomer,
            remarks: remarks
        }
        lvs.exceptionData = exceptionData;
        configStore.modalvisibledata = lvs;
        myactionsstore.exceptionModalVisible = false;
    };

    let modalClass = 'modal'
    if (myactionsstore.exceptionModalVisible) modalClass += ' is-active'
    console.log("check===============", Options)
    return(
        <>  
            { myactionsstore.exceptionModalVisible && Options && Options.length > 0  ?
                <div class={modalClass} onClick={() => { myactionsstore.exceptionModalVisible = false }}>
                    <div className="modal-background" ></div>
                    <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px',width:"75rem" }} >
                        <section className="p-8 modal-card-body" style={{width: "30em", borderRadius:"7px",position:"relative",left:"23em"}} >
                            <h2 class="text-xl font-semibold">Exception and Alerts</h2>
                            <div class='mt-2'>
                                <MultiSelect
                                    key="example_id"
                                    options={Options}
                                    onChange={handleChange}
                                    value={optionSelected}
                                    isSelectAll={true}
                                    menuPlacement={"bottom"}
                                />
                            </div>
                            <textarea placeholder="Remarks" rows='3' style={{ fontSize: '0.8rem' }} className="mt-4 textarea" value={remarks}
                                onChange={(e) => setRemarks(e.target.value)} />
                            <div class="my-2 flex items-center space-x-2">
                                <input
                                type="checkbox"
                                id="notifyCustomer"
                                
                                checked={notifyCustomer}
                                onChange={(e) => setNotifyCustomer(e.target.checked)}
                                class="text-green-600"
                                />
                                <label htmlFor="notifyCustomer" class="font-medium">
                                Notify Customer
                                </label>
                            </div>
                            <div class="my-4">
                                <button class="mr-5 button is-warning" onClick={() => myactionsstore.exceptionModalVisible = false} >cancel</button>
                                <button class="button is-danger" style={{ float: "right" }} onClick={onsubmit}>Submit</button>
                            </div>
                        </section>
                    </div>
                </div>
            : <></> }
        </>
    )
}