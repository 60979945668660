import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'
import schLogoMap from '../enquiry/carrier-logo'
import Api from '../state/Api'
import { bookingsStore, configStore, contractsStore, loginStore } from '../state/CommonState'
import Utils from '../util/Utils'
import { LCL, Line, } from '../components/circle-line'
import { enquiryStore } from '../enquiry/enquiry-data'
import FCLChargeClientViewv3 from './FCLChargeClientView-v3'
import EntityApi1 from "../state/entities-api";
import InsuranceIcon from "../assets/images/icon-02.svg";
import {PremiumInsurance} from "../mytasks/act-coi-confirmation.js";
import { useGlobalState } from '../GlobalState.js'

export default function FCLScheduleCompv3(props) {
    const [showCharges, setShowCharges] = useState(false)
    const [entityData, SetEntityData] = useState();
    const [premium, setpremium] = useState(false);
    const { currentMenu } = useGlobalState();

    const getEntitydata = async () => {
        let entityData = await EntityApi1.viewEntity(rec?.entityId);
        return entityData;
      };
      useEffect(()=>{
        if(entityData==undefined || entityData.length==0){
            getEntitydata().then((data1) => {
                SetEntityData(data1);
              });
        }
      },[])
    useSnapshot(contractsStore)
    useSnapshot(configStore)
    let e = { ...props.data }
    if (!e['POR Code']) e['POR Code'] = e['ocode']
    const [por, setPor] = useState({})
    const [pol, setPol] = useState({})
    const [ts1, setts1] = useState({})
    const [ts2, setts2] = useState({})
    const [dname, setdname] = useState({})
    useEffect(() => {
        if (!ts2.location) {
            Api.get4Unloc(e['POR Code'], setPor)
            Api.get4Unloc(e['ocode'], setPol)
            Api.get4Unloc(e['ts1code'], setts1)
            Api.get4Unloc(e['ts2code'], setts2)
            Api.get4Unloc(e['dcode'], setdname)
        }
    }, [])
    let rec = contractsStore.current
    if (props.contract) rec = props.contract
    if (!rec) return <></>
    let voldis = 0
    rec['chargesList'].map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        if (e.chargeDescription == 'Volume Discount') {
            voldis = e.total * erate
        }
    })
    let usdRate = configStore.getExchangeRate('USD')
    let voldisusd = voldis / usdRate
    let showOrigin = false
    let showDestination = false
    if (rec.shipmentScope.toLowerCase().indexOf('door-') >= 0) showOrigin = true
    if (rec.shipmentScope.toLowerCase().indexOf('-door') >= 0) showDestination = true
    if (por.location) e['POR Name'] = por.location
    if (pol.location) e['oname'] = pol.location
    if (ts1.location) e['ts1name'] = ts1.location
    if (ts2.location) e['ts2name'] = ts2.location
    if (dname.location) e['dname'] = dname.location
    let srec = {
        porname: e['POR Name'],
        porcode: e['POR Code'],
        departurePor: e['Departure from POR'],
        oname: e['oname'],
        ocode: e['ocode'],
        oetd: e['oetd'] ? Utils.formatDateShort(Utils.newDate(e['oetd'])) : '',
        r1code: e['r1code'],
        v1name: e['v1name'],
        s1voy: e['s1voy'],
        ts1name: e['ts1name'],
        ts1code: e['ts1code'],
        ts1eta: e['ts1eta'] ? Utils.formatDateShort(Utils.newDate(e['ts1eta'])) : '',
        ts1etd: e['ts1etd'] ? Utils.formatDateShort(Utils.newDate(e['ts1etd'])) : '',
        r2code: e['r2code'],
        v2name: e['v2name'],
        s2voy: e['s2voy'],
        ts2name: e['ts2name'],
        ts2code: e['ts2code'],
        ts2eta: e['ts2eta'] ? Utils.formatDateShort(Utils.newDate(e['ts2eta'])) : '',
        ts2etd: e['ts2etd'] ? Utils.formatDateShort(Utils.newDate(e['ts2etd'])) : '',
        r3code: e['r3code'],
        v3name: e['v3name'],
        s3voy: e['s3voy'],
        dname: e['dname'],
        dcode: e['dcode'],
        deta: e['deta'] ? Utils.formatDateShort(Utils.newDate(e['deta'])) : '',
        cscac: e['cscac']
    }
    let eta = e['deta']
    let etd = e['oetd']
    //console.log('ETD =', etd, e['oetd'])
    let etaDate = Utils.newDate(eta)
    let etdDate = Utils.newDate(etd)
    let days = Utils.getDays(etdDate, etaDate)
    let porCode = e['POR Code']
    let polCode = e['ocode']
    let cutoffDate = (porCode === polCode) ? Utils.addDays(etdDate, -3) : Utils.addDays(etdDate, -12)
    let cscac = e['cscac']
    //console.log('CSCAC=', cscac)

    let startDate = Utils.newDate(rec.mailedOn)
    //if (!startDate) startDate = new Date()
    //console.log('%%%%%%%%% startDate=', startDate)
    startDate = Utils.addDays(startDate, 3)
    // The below overrides the above startDate. The below considers only the current date
    // and not the quote start date
    let currentDate = new Date()
    let cargoReadinessDate = (rec.cargoReadinessDate && rec.cargoReadinessDate.length >= 10) ?
        new Date(rec.cargoReadinessDate) : currentDate
    if (cargoReadinessDate < currentDate) cargoReadinessDate = currentDate
    if (isNaN(cargoReadinessDate)) cargoReadinessDate = currentDate
    let startDays = (porCode === polCode) ? 2 : 8 // the logic in mail is >= 4/13, equivalent to this > 3/12
    startDate = Utils.addDays(cargoReadinessDate, startDays)
    if (etdDate < startDate && !props.showbooking) {
        if (loginStore.isClient()) return <></>
        console.log('###CONDITION1', porCode, polCode, 'FAILS= ETD', etdDate, '< Quote Date', startDate)
        return <div>
            POR = {porCode}, POL = {polCode}, CRD = {cargoReadinessDate.toISOString().substring(0, 10)},
            ETD = {etdDate.toISOString().substring(0, 10)},
            Start Date as calculated = {startDate.toISOString().substring(0, 10)}<br />
            CONDITION FAILED = ETD less than Start<hr />
        </div>
    }
    let endDate = Utils.newDate(rec.validUpto)
    let endDays = srec.porname !== srec.oname ? 8 : 2
    //endDate = Utils.addDays(endDate, endDays*-1)
    if (etdDate > endDate && !props.showbooking) {
        if (loginStore.isClient()) return <></>
        console.log('###CONDITION2', porCode, polCode, ' FAILS= ETD', etdDate, '> Valid Upto', endDate)
        return <div>
            POR = {porCode}, POL = {polCode}, CRD = {cargoReadinessDate.toISOString().substring(0, 10)},
            ETD = {etdDate.toISOString().substring(0, 10)},
            End Date as calculated = {endDate.toISOString().substring(0, 10)}<br />
            CONDITION FAILED = ETD greater than END<hr />
        </div>
    }

    return (
        <div className="box">
             <PremiumInsurance  data={(data)=>{
                console.log("DCs",data)
             setpremium(data)
        }} ></PremiumInsurance>
            <div className="columns" style={{ fontSize: '0.6rem' }}>
                <div className="column is-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {
                        (schLogoMap[cscac]) ?
                            <img src={schLogoMap[cscac]} alt={cscac} />
                            : <strong>{cscac}</strong>
                    }
                </div>
                <div className="column is-9">
                    <table className='schtable' style={{ width: '100%', textAlign: 'center' }}>
                        <tr>
                            <td>
                            </td>
                            <td colSpan={8}>
                            {
                                    (days!=undefined && Number(days)>0)?<>
                                    <div style={{ color: 'grey' }}>Total Transit Time</div>
                                    <div style={{ fontSize: '0.9rem' }}><strong>{days} days</strong></div>
                                    </>:<div style={{ color: 'grey' }}>Schedule Awaited</div>
                                }
                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {

                                }
                            </td>
                            <td>Cut-off&nbsp;
                                {
                                    Utils.formatDateShort(cutoffDate)
                                }
                            </td>
                            <td></td>
                            <td>
                            </td>
                            <td>
                                {(srec.ts1name) ? 'ETA ' + srec.ts1eta : ''}
                            </td>

                            <td>
                            </td>
                            <td>
                                {(srec.ts2name) ? 'ETA ' + srec.ts2eta : ''}
                            </td>

                            <td>
                            </td>
                            <td>
                                {'ETA ' + srec.deta}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>
                                    {
                                        (showOrigin) ? rec.origin : ''
                                    }
                                </strong>
                            </td>
                            <td>
                                <strong>
                                    {e['POR Name'] !== e['oname'] ? e['POR Name'] : e['oname']}
                                </strong>
                            </td>
                            <td>
                                <strong>
                                    {e['POR Name'] !== e['oname'] ? e['oname'] : ''}
                                </strong>
                            </td>
                            <td>
                                {e['v1name']}({e['s1voy']})
                            </td>
                            <td>
                                <strong>
                                    {e['ts1name']}
                                </strong>
                            </td>
                            <td>
                                {(srec.ts1name) ? e['v2name'] + '(' + e['s2voy'] : ''}
                            </td>
                            <td>
                                <strong>
                                    {e['ts2name']}
                                </strong>
                            </td>
                            <td>
                                {(srec.ts2name) ? e['v3name'] + '(' + e['s3voy'] : ''}
                            </td>
                            <td>
                                <strong>
                                    {e['dname']}
                                </strong>
                            </td>
                            <td>
                                <strong>
                                    {
                                        (showDestination) ? <>{rec.destination2!=undefined && rec.destination2.length>0?"Multiple":rec.destination}</> : ''
                                    }
                                </strong>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                {
                                    (showOrigin) ? <LCL origin='1' /> : ''
                                }
                            </td>
                            <td>{showOrigin ? <LCL /> : <LCL left='1' />}</td>
                            <td>
                                {
                                    (e['POR Name'] === e['oname']) ?
                                        <Line /> :
                                        <LCL />
                                }
                            </td>
                            <td>
                                <Line />
                            </td>
                            <td>
                                {e['ts1name'] ? <LCL /> : <Line />}
                            </td>
                            <td>
                                <Line />
                            </td>
                            <td>
                                {e['ts2name'] ? <LCL /> : <Line />}
                            </td>
                            <td>
                                <Line />
                            </td>
                            <td>
                                {
                                    (showDestination) ?
                                        <LCL /> :
                                        <LCL right='1' />
                                }
                            </td>
                            <td>
                                {
                                    (showDestination) ? <LCL destination='1' /> : ''
                                }
                            </td>
                        </tr>

                        <tr>
                            <td></td>
                            <td>{(e['POR Name'] === e['oname']) ? 'ETD ' + srec.oetd : ''}</td>
                            <td>{(e['POR Name'] !== e['oname']) ? 'ETD ' + srec.oetd : ''}</td>
                            <td>{e['r1code']}</td>
                            <td>{srec.ts1etd ? 'ETD ' + srec.ts1etd : ''}</td>
                            <td>{e['r2code']}</td>
                            <td>{srec.ts2etd ? 'ETD ' + srec.ts2etd : ''}</td>
                            <td>{e['r3code']}</td>
                        </tr>
                    </table>
                </div>
                <div className='column is-2' style={{ textAlign: 'center' }}>
                    {
                        ((rec.quoteValue + voldis) < 0) ?
                            <>
                                <div style={{ fontSize: '1.0rem' }}><strong>{Utils.N(rec.quoteValue)}</strong></div>
                                <div style={{ fontSize: '0.8rem' }}>{Utils.N(rec.quoteValueUSD, 'USD')}</div>
                                <div style={{ fontSize: '1.0rem' }}>(Less) Volume Discount <strong>{Utils.N(voldis)}</strong></div>
                            </> : <>
                                <div style={{ fontSize: '1.0rem' }}><strong>{Utils.N(rec.quoteValue + voldis)}</strong></div>
                                <div style={{ fontSize: '0.8rem' }}>{Utils.N(rec.quoteValueUSD + voldisusd, 'USD')}</div>
                            </>
                    }

                    <div style={{ fontSize: '0.6rem', color: 'grey' }}>(Taxes, as Applicable)</div>
                    <div style={{display:"flex",justifyContent: "center", alignItems: "center" }}>
                    {
                        (!props.viewonly && rec.quoteStatus == 'ACCEPTED') ?
                            <button className='button is-rounded is-link is-small' onClick={() => {
                                bookingsStore.initialize()
                                bookingsStore.current.schedule = props.data
                                bookingsStore.current.contract = { ...rec }
                                bookingsStore.current.enquiry = { ...enquiryStore.current }
                                bookingsStore.current.fbaDetails = Utils.fbaDetails(rec)
                                configStore.scheduleOrCarrierform = 'carrierform'
                            }}>
                                Proceed to Booking
                            </button> : <></>
                    }
                    {rec.quoteStatus == 'ACCEPTED'&& currentMenu !="Bookingsv3" && (rec.shipmentType=="LCL"||rec.shipmentType.includes("FCL")) && entityData?.entityCountry.toLowerCase()=="india"
              &&(rec?.finalPlaceOfDelivery.toLowerCase().includes("india")==true || rec?.portOfReceipt.toLowerCase().includes("india")==true) ?

              <div >
                <button className={
                configStore.scheduleOrCarrierform.toString() === "carrierform"
                  ? "button is-rounded is-link is-small is-hidden"
                  : "blinking"
              }
              style={{marginLeft:"8px",cursor:"pointer",display: "inline-block", border: "none",animation: "blink 1s infinite"}} onClick={()=>{
                   configStore.coiModalVisible2=true
                 }}>
                    <img src={InsuranceIcon} alt="Insurance Icon" width="54" height="70" />
                </button>
            </div>:""}
                    </div>
                    
                </div>
            </div>
            <div className="columns" style={{ fontSize: '0.8rem' }}>
                {
                    (showCharges) ?
                        <button className='button is-warning is-small is-fullwidth' onClick={() => setShowCharges(false)}>
                            Hide Detailed Pricing
                            <span className="icon" style={{ marginLeft: '5px' }}>
                                <i className="fas fa-sort-up"></i>
                            </span>
                        </button> :
                        <button className='button is-info is-small is-fullwidth' onClick={() => setShowCharges(true)}>
                            View Detailed Pricing
                            <span className="icon" style={{ marginLeft: '5px' }}>
                                <i className="fas fa-sort-down"></i>
                            </span>
                        </button>
                }

            </div>
            {
                (showCharges) ?
                    <div className="columns" style={{
                        fontSize: '0.8rem',
                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}>
                        {
                            (rec.shipperchargesList?.length > 0 || rec.consigneechargesList?.length > 0) ?
                                <>
                                    <div className='column is-6'>
                                        <FCLChargeClientViewv3 dummyContract={props.contract} />
                                    </div>
                                    <div className='column is-6'>
                                        {
                                            rec.shipperchargesList?.length > 0 ?
                                                <FCLChargeClientViewv3 dummyContract={props.contract} chargesName='shipperchargesList' />
                                                :
                                                <FCLChargeClientViewv3 dummyContract={props.contract} chargesName='consigneechargesList' />
                                        }
                                    </div>
                                </>
                                :
                                <div className='column is-12'>
                                    <FCLChargeClientViewv3 dummyContract={props.contract} />
                                </div>
                        }
                    </div> : <></>
            }
        </div>
    )
}

export function FCLScheduleActionCompv2(props) {
    const [showCharges, setShowCharges] = useState(false)
    useSnapshot(contractsStore)
    useSnapshot(configStore)
    let e = { ...props.data }
    if (!e['POR Code']) e['POR Code'] = e['ocode']
    const [por, setPor] = useState({})
    const [pol, setPol] = useState({})
    const [ts1, setts1] = useState({})
    const [ts2, setts2] = useState({})
    const [dname, setdname] = useState({})
    useEffect(() => {
        if (!ts2.location) {
            Api.get4Unloc(e['POR Code'], setPor)
            Api.get4Unloc(e['ocode'], setPol)
            Api.get4Unloc(e['ts1code'], setts1)
            Api.get4Unloc(e['ts2code'], setts2)
            Api.get4Unloc(e['dcode'], setdname)
        }
    }, [])
    const rec = contractsStore.current
    if (!rec) return <></>
    let showOrigin = false
    let showDestination = false
    if (rec.shipmentScope.toLowerCase().indexOf('door-') >= 0) showOrigin = true
    if (rec.shipmentScope.toLowerCase().indexOf('-door') >= 0) showDestination = true
    if (por.location) e['POR Name'] = por.location
    if (pol.location) e['oname'] = pol.location
    if (ts1.location) e['ts1name'] = ts1.location
    if (ts2.location) e['ts2name'] = ts2.location
    if (dname.location) e['dname'] = dname.location
    let srec = {
        porname: e['POR Name'],
        porcode: e['POR Code'],
        departurePor: e['Departure from POR'],
        oname: e['oname'],
        ocode: e['ocode'],
        oetd: e['oetd'] ? Utils.formatDateShort(Utils.newDate(e['oetd'])) : '',
        r1code: e['r1code'],
        v1name: e['v1name'],
        s1voy: e['s1voy'],
        ts1name: e['ts1name'],
        ts1code: e['ts1code'],
        ts1eta: e['ts1eta'] ? Utils.formatDateShort(Utils.newDate(e['ts1eta'])) : '',
        ts1etd: e['ts1etd'] ? Utils.formatDateShort(Utils.newDate(e['ts1etd'])) : '',
        r2code: e['r2code'],
        v2name: e['v2name'],
        s2voy: e['s2voy'],
        ts2name: e['ts2name'],
        ts2code: e['ts2code'],
        ts2eta: e['ts2eta'] ? Utils.formatDateShort(Utils.newDate(e['ts2eta'])) : '',
        ts2etd: e['ts2etd'] ? Utils.formatDateShort(Utils.newDate(e['ts2etd'])) : '',
        r3code: e['r3code'],
        v3name: e['v3name'],
        s3voy: e['s3voy'],
        dname: e['dname'],
        dcode: e['dcode'],
        deta: e['deta'] ? Utils.formatDateShort(Utils.newDate(e['deta'])) : '',
        cscac: e['cscac']
    }
    let eta = e['deta']
    let etd = e['oetd']
    //console.log('ETD =', etd, e['oetd'])
    let etaDate = Utils.newDate(eta)
    let etdDate = Utils.newDate(etd)
    let days = Utils.getDays(etdDate, etaDate)
    let porCode = e['POR Code']
    let polCode = e['ocode']
    let cutoffDate = (porCode === polCode) ? Utils.addDays(etdDate, -3) : Utils.addDays(etdDate, -12)
    let cscac = e['cscac']
    //console.log('CSCAC=', cscac)

    let startDate = Utils.newDate(rec.mailedOn)
    //if (!startDate) startDate = new Date()
    //console.log('%%%%%%%%% startDate=', startDate)
    startDate = Utils.addDays(startDate, 3)
    // The below overrides the above startDate. The below considers only the current date
    // and not the quote start date
    let currentDate = new Date()
    let cargoReadinessDate = (rec.cargoReadinessDate && rec.cargoReadinessDate.length >= 10) ?
        new Date(rec.cargoReadinessDate) : currentDate
    if (cargoReadinessDate < currentDate) cargoReadinessDate = currentDate
    if (isNaN(cargoReadinessDate)) cargoReadinessDate = currentDate
    let startDays = (porCode === polCode) ? 2 : 8 // the logic in mail is >= 4/13, equivalent to this > 3/12
    startDate = Utils.addDays(cargoReadinessDate, startDays)
    let endDate = Utils.newDate(rec.validUpto)
    let endDays = srec.porname !== srec.oname ? 8 : 2
    //endDate = Utils.addDays(endDate, endDays*-1)

    let voldis = 0
    rec['chargesList'].map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        if (e.chargeDescription == 'Volume Discount') {
            voldis = e.total * erate
        }
    })
    let usdRate = configStore.getExchangeRate('USD')
    let voldisusd = voldis / usdRate
    if (voldis > 0) {
        voldis = -(voldis)
        voldisusd = -(voldisusd)
    }
    return (
        <div className="box">
            <div className="columns" style={{ fontSize: '0.6rem' }}>
                <div className="column is-1" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    {
                        (schLogoMap[cscac]) ?
                            <img src={schLogoMap[cscac]} alt={cscac} />
                            : <strong>{cscac}</strong>
                    }
                </div>
                <div className="column is-9">
                    <table className='schtable' style={{ width: '100%', textAlign: 'center' }}>
                        <tr>
                            <td>
                            </td>
                            <td colSpan={8}>
                            {
                                    (days!=undefined && Number(days)>0)?<>
                                    <div style={{ color: 'grey' }}>Total Transit Time</div>
                                    <div style={{ fontSize: '0.9rem' }}><strong>{days} days</strong></div>
                                    </>:<div style={{ color: 'grey' }}>Schedule Awaited</div>
                                }
                            </td>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {

                                }
                            </td>
                            <td>Cut-off&nbsp;
                                {
                                    Utils.formatDateShort(cutoffDate)
                                }
                            </td>
                            <td></td>
                            <td>
                            </td>
                            <td>
                                {(srec.ts1name) ? 'ETA ' + srec.ts1eta : ''}
                            </td>

                            <td>
                            </td>
                            <td>
                                {(srec.ts2name) ? 'ETA ' + srec.ts2eta : ''}
                            </td>

                            <td>
                            </td>
                            <td>
                                {'ETA ' + srec.deta}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <strong>
                                    {
                                        (showOrigin) ? rec.origin : ''
                                    }
                                </strong>
                            </td>
                            <td>
                                <strong>
                                    {e['POR Name'] !== e['oname'] ? e['POR Name'] : e['oname']}
                                </strong>
                            </td>
                            <td>
                                <strong>
                                    {e['POR Name'] !== e['oname'] ? e['oname'] : ''}
                                </strong>
                            </td>
                            <td>
                                {e['v1name']}({e['s1voy']})
                            </td>
                            <td>
                                <strong>
                                    {e['ts1name']}
                                </strong>
                            </td>
                            <td>
                                {(srec.ts1name) ? e['v2name'] + '(' + e['s2voy'] : ''}
                            </td>
                            <td>
                                <strong>
                                    {e['ts2name']}
                                </strong>
                            </td>
                            <td>
                                {(srec.ts2name) ? e['v3name'] + '(' + e['s3voy'] : ''}
                            </td>
                            <td>
                                <strong>
                                    {e['dname']}
                                </strong>
                            </td>
                            <td>
                                <strong>
                                    {
                                        (showDestination) ? rec.destination : ''
                                    }
                                </strong>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                {
                                    (showOrigin) ? <LCL origin='1' /> : ''
                                }
                            </td>
                            <td>{showOrigin ? <LCL /> : <LCL left='1' />}</td>
                            <td>
                                {
                                    (e['POR Name'] === e['oname']) ?
                                        <Line /> :
                                        <LCL />
                                }
                            </td>
                            <td>
                                <Line />
                            </td>
                            <td>
                                {e['ts1name'] ? <LCL /> : <Line />}
                            </td>
                            <td>
                                <Line />
                            </td>
                            <td>
                                {e['ts2name'] ? <LCL /> : <Line />}
                            </td>
                            <td>
                                <Line />
                            </td>
                            <td>
                                {
                                    (showDestination) ?
                                        <LCL /> :
                                        <LCL right='1' />
                                }
                            </td>
                            <td>
                                {
                                    (showDestination) ? <LCL destination='1' /> : ''
                                }
                            </td>
                        </tr>

                        <tr>
                            <td></td>
                            <td>{(e['POR Name'] === e['oname']) ? 'ETD ' + srec.oetd : ''}</td>
                            <td>{(e['POR Name'] !== e['oname']) ? 'ETD ' + srec.oetd : ''}</td>
                            <td>{e['r1code']}</td>
                            <td>{srec.ts1etd ? 'ETD ' + srec.ts1etd : ''}</td>
                            <td>{e['r2code']}</td>
                            <td>{srec.ts2etd ? 'ETD ' + srec.ts2etd : ''}</td>
                            <td>{e['r3code']}</td>
                        </tr>
                    </table>
                </div>
                <div className='column is-2' style={{ textAlign: 'center' }}>
                    {
                        ((rec.quoteValue + voldis) < 0) ?
                            <>
                                <div style={{ fontSize: '1.0rem' }}><strong>{Utils.N(rec.quoteValue)}</strong></div>
                                <div style={{ fontSize: '0.8rem' }}>{Utils.N(rec.quoteValueUSD, 'USD')}</div>
                                <div style={{ fontSize: '1.0rem' }}>(Less) Volume Discount <strong>{Utils.N(voldis)}</strong></div>
                            </> : <>
                                <div style={{ fontSize: '1.0rem' }}><strong>{Utils.N(rec.quoteValue + voldis)}</strong></div>
                                <div style={{ fontSize: '0.8rem' }}>{Utils.N(rec.quoteValueUSD + voldisusd, 'USD')}</div>
                            </>
                    }

                    <div style={{ fontSize: '0.6rem', color: 'grey' }}>(Taxes, as Applicable)</div>
                    {
                        ((!props.viewonly && !loginStore.isClient()) || (!props.viewonly && rec.quoteStatus == 'ACCEPTED')) ?
                            <button className='button is-rounded is-link is-small' onClick={() => {
                                bookingsStore.initialize()
                                bookingsStore.current.schedule = props.data
                                bookingsStore.current.contract = { ...rec }
                                bookingsStore.current.enquiry = { ...enquiryStore.current }
                                bookingsStore.current.fbaDetails = Utils.fbaDetails(rec)
                                configStore.scheduleOrCarrierform = 'carrierform'
                            }}>
                                Proceed to Booking
                            </button> : <></>
                    }
                </div>
            </div>
            <div className="columns" style={{ fontSize: '0.8rem' }}>
                {
                    (showCharges) ?
                        <button className='button is-warning is-small is-fullwidth' onClick={() => setShowCharges(false)}>
                            Hide Detailed Pricing
                            <span className="icon" style={{ marginLeft: '5px' }}>
                                <i className="fas fa-sort-up"></i>
                            </span>
                        </button> :
                        <button className='button is-info is-small is-fullwidth' onClick={() => setShowCharges(true)}>
                            View Detailed Pricing
                            <span className="icon" style={{ marginLeft: '5px' }}>
                                <i className="fas fa-sort-down"></i>
                            </span>
                        </button>
                }

            </div>
            {
                (showCharges) ?
                    <div className="columns" style={{
                        fontSize: '0.8rem',
                        display: 'flex', alignItems: 'center', justifyContent: 'center'
                    }}>
                        <FCLChargeClientViewv3 />
                    </div> : <></>
            }
        </div>
    )
}