import { useEffect, useState } from "react"
import { useSnapshot } from "valtio"
import { AgSelect } from "../components/AgComponents"
import { AgSegmentCard } from "../components/AgSegmentCard"
import { EntityV2Store, bookingsStore, configStore, entityStore,loginStore } from "../state/CommonState"
import Utils from "../util/Utils"
import Otheraction from "./act-otheraction"
import TaskDatetime from "./task-datetime"
import Tasklistsmall from "./task-list-small"
import { taskStore } from "./task-store"
import Taskapi, { Task } from "./tasks-api"
import { getAction, getfilenames, getActionList, getActionListNames, getActionsForShipmentType, getPayload, getTriggercomp } from "./tasks-static-data"
import BranchApi from "../state/branch-api"
import Api from "../state/Api"
import { SHIPMENT_TYPE ,SHIPMENT_SCOPE,VENDOR_TYPES } from "../util/constants";

const server = process.env.REACT_APP_FILE_SERVER;

export default function VendorAssignment(props) {
    useSnapshot(taskStore)
    useSnapshot(bookingsStore)
    useSnapshot(entityStore)
    useSnapshot(EntityV2Store)
    const Entity = EntityV2Store.EntityRec;
    const [oldActions, setOldActions] = useState([])
    const [otherActions, setOtherActions] = useState([])
    const [currentActionlastdate, setCurrentActionlastdate] = useState("")
    const [initialVendorData, setInitialVendorData] = useState({});
    const [openPending,setOpenPending] = useState(true)
    const [entities,setEntities] = useState([])
    const [dataList, setDataList] = useState([]);
    const [allDataList, setAllDataList] = useState({});
    const [contactList, setContactList] = useState({});
    const [contactFlag, setContactFlag] = useState({});
    const [userList, setUserList] = useState([]);
    const [showButtons, setShowButtons] = useState(false);
    let active = 'none'
    if (taskStore.vendorAssignment) active = 'block'
    let bookingData = bookingsStore.current
    let task = taskStore.current
    let action = getAction(taskStore.current.actionName)
    let Comp = action?.triggercomp

    const allShipmentTypes = Object.values(SHIPMENT_TYPE); 
    const fclShipmentTypes = [SHIPMENT_TYPE.fcl_cfs_cfs,SHIPMENT_TYPE.fcl_cfs_f,SHIPMENT_TYPE.fcl_f_cfs,SHIPMENT_TYPE.fcl_f_f];
    const airShipmentType = SHIPMENT_TYPE.air;
    const lclShipmentType = SHIPMENT_TYPE.lcl;
    const fclAndLclShipmentTypes = [SHIPMENT_TYPE.fcl_cfs_cfs,SHIPMENT_TYPE.fcl_cfs_f,SHIPMENT_TYPE.fcl_f_cfs,SHIPMENT_TYPE.fcl_f_f,SHIPMENT_TYPE.lcl];

    const allShipmentScopes = Object.values(SHIPMENT_SCOPE);
    const doorOriginScopes = [SHIPMENT_SCOPE.d2d,SHIPMENT_SCOPE.d2p]
    const doorDestinationScopes = [SHIPMENT_SCOPE.p2d,SHIPMENT_SCOPE.d2d]

    const dockToFactoryTypes = [SHIPMENT_TYPE.fcl_cfs_f,SHIPMENT_TYPE.fcl_cfs_cfs]
    const factoryToDockTypes = [SHIPMENT_TYPE.fcl_cfs_cfs,SHIPMENT_TYPE.fcl_f_cfs]

    const fbaOCC = "Yes";
    const fbaDCC = "Yes";
    const originCountry = "India";
    const dockType = "Dock"

    const originTransportCheck = (()=>allShipmentTypes.includes(bookingData.contract.shipmentType) && doorOriginScopes.includes(bookingData.contract.shipmentScope)) 
    const originWarehouseCheck = (()=>doorOriginScopes.includes(bookingData.contract.shipmentScope) && dockToFactoryTypes.some(type => bookingData.contract.shipmentType.includes(type)))
    const oceanCarrierCheck = (()=>fclShipmentTypes.includes(bookingData.contract.shipmentType) &&  fclShipmentTypes.some(type => bookingData.contract.shipmentType.includes(type)) && allShipmentScopes.includes(bookingData.contract.shipmentScope))
    const airFreightAndAWBCheck = (()=>bookingData.contract.shipmentType===airShipmentType && allShipmentScopes.includes(bookingData.contract.shipmentScope))
    const blReleaseCheck = (()=>fclAndLclShipmentTypes.includes(bookingData.contract.shipmentType) &&  fclAndLclShipmentTypes.some(type => bookingData.contract.shipmentType.includes(type)) && allShipmentScopes.includes(bookingData.contract.shipmentScope) && bookingData?.originCount !== originCountry)
    const doReleaseCheck = (()=>allShipmentTypes.includes(bookingData.contract.shipmentType) && bookingData.originCount === originCountry && bookingData.destinationCount !== originCountry)
    const destWarehouseCheck = (()=>fclShipmentTypes.includes(bookingData.contract.shipmentType) && doorDestinationScopes.includes(bookingData.contract.shipmentScope) &&  factoryToDockTypes.some(type => bookingData.contract.shipmentType.includes(type)))
    const destTransCheck = (()=>allShipmentTypes.includes(bookingData.contract.shipmentType) && doorDestinationScopes.includes(bookingData.contract.shipmentScope))
    const originCFSOperatorVendor = (()=>fclShipmentTypes.includes(bookingData.contract.shipmentType) && doorOriginScopes.includes(bookingData.contract.shipmentScope) && dockType.includes(bookingData.contract.stuffingType))
    const destCFSOperatorVendor = (()=>fclShipmentTypes.includes(bookingData.contract.shipmentType) && doorDestinationScopes.includes(bookingData.contract.shipmentScope) && dockType.includes(bookingData.contract.destuffingType))
    function setaction(name){
        let current = oldActions.filter(e => e.actionName === name)
        if(current.length>0){
            let date = Utils.formatToDateTime(current[current.length-1]['completedOn'])
            if(current[current.length-1]['actionName']=="Carrier Release Order"){
                taskStore.current.actionName = ""
                configStore.setModalMessage("This action has been previously completed on: "+date)
            }else{
                configStore.setModalMyacticecompleted("This action has been previously completed on: "+date)
            }
            setCurrentActionlastdate(date)
        }else{
            setCurrentActionlastdate("")
        }
        
    }

    useEffect(()=>{
      if (bookingsStore?.current?.vendor && Object.keys(bookingsStore?.current?.vendor).length > 0) {
        destinationsList.forEach(({ key }) => {
          if (bookingsStore.current?.vendor?.destinationTransport[key]?.vendorId) {
            Api.fetch(`${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/${bookingsStore.current?.vendor?.destinationTransport[key].vendorId}`,
              (data1) => {
                if (data1?.logisticInformation) {
                  setContactList((prev) => ({
                    ...prev,
                    [key]: data1?.logisticInformation
                  }));
                }

              })

          }
        })
      }

    Api.fetch(`${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/assignUsers?origin=${bookingsStore.current.originCount}&dest=${bookingsStore.current.destinationCount}&bookingType=${bookingsStore.current.contract.shipmentType}`,
      (data1)=>{
      if(data1){
        setUserList(data1)
      }                 
      
    })
    },[])
    const showdest = (key) =>{
        if(bookingData?.contract[key]!=undefined && bookingData?.contract[key].length>0){
            return <>
            <hr style={{margin:"0px"}}/>
            {bookingData?.contract[key]}
            </>
        }
    }
    useEffect(() => {
        let f = async () => {
            let ret = await Taskapi.getTasklist4Booking(bookingData._id)
            // if(ret.status === 400){
            //     return configStore.setModalMessage(ret.message)
            // }else {
                let old = ret?.filter(e => e.status === 'COMPLETED')
                if(JSON.stringify(old)!=JSON.stringify(oldActions)){
                    setOldActions(old)
                }
                ret = ret?.filter(e => e.status === 'PENDING')
                if(JSON.stringify(ret)!=JSON.stringify(otherActions)){
                    setOtherActions(ret)
                }
            // }
        }
        f()
    }, [])

    useEffect(() => {
        if (bookingData?.vendor) {
          setInitialVendorData(JSON.parse(JSON.stringify(bookingData.vendor)));
        }
      

        const shouldShowButtons =  originTransportCheck() || originWarehouseCheck() || (bookingData.contract?.fbaOCC===fbaOCC) ||
        (bookingData.contract.shipmentType===lclShipmentType) || oceanCarrierCheck() || airFreightAndAWBCheck() || doReleaseCheck() ||
        blReleaseCheck() || (bookingData.contract?.fbaDCC===fbaDCC) || destWarehouseCheck() || destTransCheck() || originCFSOperatorVendor() || destCFSOperatorVendor()

        setShowButtons(shouldShowButtons);
        Api.fetch(`${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/listvendors?origin=${bookingsStore.current.originCount}&shipmentType=${bookingsStore.current.contract.shipmentType}&dest=${bookingsStore.current.destinationCount}`,
          (data1)=>{          
          if(data1?.vendorsBySegment){
               setDataList(data1?.vendorsBySegment["Destination Transport"])                
               setAllDataList(data1?.vendorsBySegment)                
          }                 
          
      })
      }, [bookingData]);

      const handleSave = (e) => {  

        if (!validateMandatoryFields()) {
            return; 
        }
        
        Api.put(`${process.env.REACT_APP_API_SERVER}/api/v1/bookings/updateVendor`,
            {
                bookingId : bookingsStore.current._id,
                bookingData : bookingsStore.current.vendor
            },(data1)=>{
            if(data1.status === 'success'){
                taskStore.vendorAssignment = false
            }                 
            
        })
      };


      const destinationsList = [];
      for (let i = 0; i <= 5; i++) {
        const key = i==1 ? `destination`:`destination${i}`;
        if (bookingData.contract[key]) {
          destinationsList.push({
            key,
            label: `Destination-${i} ${bookingData.contract[key]}`,
          });
        }
      }

    
      useEffect(() => {
        if (!bookingData?.vendor) {
          bookingData.vendor = {};
        }
    
        if (!bookingData.vendor.destinationTransport) {
          bookingData.vendor.destinationTransport = {
            assignUser: "",
          };
        }
    
        destinationsList.forEach(({ key }) => {
          if (!bookingData.vendor.destinationTransport[key]) {
            bookingData.vendor.destinationTransport[key] = {
              vendorId: "",
              doc: {
                filename: "",
                pkey: ""
              },
              remarks: "",
            };
          }
        });
      }, [bookingData, destinationsList]); 
    
    
      if (!bookingData?.vendor?.destinationTransport) {
        return <div>Loading...</div>;
      }
   
    
      function change(files, filetype, i) {
        const formData = new FormData();
        formData.append("myFile", files[0]);
        formData.append("label", filetype);
        formData.append("key", i);
        fetch(`${process.env.REACT_APP_FILE_SERVER}/api/v1/filestore/storefile`, {
          method: "POST",
          headers: Api.H1(),
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) configStore.setModalMessage(data.error);
            else{
              bookingData.vendor.destinationTransport[filetype].doc = {
                filename: data.key.filename,
                pkey: data.key.pkey
              };
            }
          })
          .catch((error) => {
            console.error(error);
          });
        }
    
      const handleVendorChange = (e, destinationKey) => {
        bookingData.vendor.destinationTransport[destinationKey].vendorId = e.target.value;
        bookingData.vendor.destinationTransport.assignUser = ""
        bookingData.vendor.destinationTransport[destinationKey].doc={
          filename: "",
          pkey: ""
        }
        bookingData.vendor.destinationTransport[destinationKey].updatedAt = Date.now()
        bookingData.vendor.destinationTransport[destinationKey].updatedBy = loginStore.email
        setContactFlag({[destinationKey]:true})
        if(e.target.value !== ""){
        Api.fetch(`${process.env.REACT_APP_API_SERVER}/api/v1/stakeholder/entity/${e.target.value}`,
          (data1)=>{      
          if(data1?.logisticInformation?.length>0 && validateContactList(data1?.logisticInformation)){            
            setContactList((prev) => ({
              ...prev,
              [destinationKey]: data1?.logisticInformation
            }));
          }else{
            setContactList((prev) => ({
              ...prev,
              [destinationKey]: []
            }));
            bookingData.vendor.destinationTransport[destinationKey].vendorId=""
          }              
          
      })
    }
      };
    
      const handleRemarksChange = (e, destinationKey) => {
        const value = e.target.value;
        if (value.length <= 100) {
          bookingData.vendor.destinationTransport[destinationKey].remarks = value;
        }
      };
    
      const handleFileUpload = (data, destinationKey) => {
        bookingData.vendor.destinationTransport[destinationKey].doc = {
          filename: data.key.filename,
          pkey: data.key.pkey
        };
      };
    
      const handleDeleteFile = (destinationKey) => {
        bookingData.vendor.destinationTransport[destinationKey].doc = {
          filename: "",
          pkey: ""
        };
        };
    
      const handleUserChange = (e) => {
        bookingData.vendor.destinationTransport.assignUser = e.target.value;
       };
      const vendorCheck = ()=>{    
        return Object.values(bookingData.vendor.destinationTransport).every(
            (transportDetails) =>  transportDetails.vendorId === "" || transportDetails.vendorId === undefined 
        );
      }

      const validateMandatoryFields = () => {
        const originTransport = bookingsStore.current.vendor["originTransport"];
        
        if (originTransport?.vendorId !=="" && originTransport?.doc?.filename==="") {
            configStore.setModalMessage("Please upload the document for Origin Transport")
            return false;
        }

        const originWarehouse = bookingsStore.current.vendor["originWarehouse"];
        
        if (originWarehouse?.vendorId !=="" && originWarehouse?.doc?.filename==="") {
            configStore.setModalMessage("Please upload the document for Origin Warehouse")
            return false;
        }

        const destinationWarehouse = bookingsStore.current.vendor["destinationWarehouse"];
        
        if (destinationWarehouse?.vendorId !=="" && destinationWarehouse?.doc?.filename==="") {
            configStore.setModalMessage("Please upload the document for destination Warehouse")
            return false;
        }


        const destinationTransport = bookingsStore.current.vendor["destinationTransport"];

        if(destinationTransport){
            for (const [key, data] of Object.entries(destinationTransport)) {
                if (data?.vendorId !== "" && data?.doc?.filename === "") {
                    configStore.setModalMessage(`Please upload the document for ${key}`);
                    return false; 
                }
            }
        }
        
        return true;
    };

    const isValidContact = (contact) => {
       return contact.customercontact && contact.department && contact.contactnum && contact.email;
    };
    
    const validateContactList = (contactList) => {
      const validContacts = contactList.filter((r) => isValidContact(r));
      if (validContacts.length === 0) {
        return false;
      }
       return validContacts;
    };
    

    return (
        <div  style={{ display: active, width: '100%' }}>
            <div>
                <div style={{ height: '70vh' }}>
                    <div className="columns">
                        <div className="column is-7">
                            <h5 className="title is-4" style={{ color: '#2c358a', fontFamily: 'product_sans_bold' }}>
                                Vendor Detail
                            </h5>
                            {
                                (currentActionlastdate.length>0?
                                <>
                                <p className="title is-6" style={{ color: '#2c358a', fontFamily: 'product_sans_bold' }}>
                                This action has been previously completed on: {currentActionlastdate}
                            </p>
                                </>:<></>)
                            }
                            <div className="box">
                            <tr>
                                <td><strong>Booking ID</strong></td>
                                <td style={{ width: '100px' }}></td>
                                <td>{bookingData?._id}</td>
                            </tr>
                            <tr>
                                <td>{bookingData?.contract?.origin}</td>
                                <td style={{ fontSize: '1.2rem', fontWeight: 'bold', paddingLeft:'10px' }}> &#8594; </td>
                                <td>{bookingData?.contract?.destination}
                                {showdest("destination2")}
                                        {showdest("destination3")}
                                        {showdest("destination4")}
                                        {showdest("destination5")}
                                </td>
                            </tr>
                            <tr>
                                <td>{bookingData?.contract?.shipmentType}</td>
                                <td style={{ width: '20px' }}></td>
                                <td>{bookingData?.contract?.shipmentScope}</td>
                            </tr>
                            </div>
                        </div>
                        <div className="column is-5" style={{ fontSize: '0.80rem' }}>
                            <button style={{ float: 'right' }} className="button is-small islink"
                                onClick={async (e) => {
                                    if (bookingsStore?.current?.vendor && Object.keys(initialVendorData).length>0) {
                                        bookingsStore.current.vendor = JSON.parse(JSON.stringify(initialVendorData)); 
                                    }else{
                                      bookingsStore.current.vendor = {};
                                    }
                                    taskStore.vendorAssignment = false
                                }}>
                                <span className="icon ">
                                    <i className="fas fa-xmark"></i>
                                </span>
                            </button>
                            <div>
                            </div>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-12">
                                {originTransportCheck() && 
                                <AgSegmentCard type={VENDOR_TYPES.ORIGIN_TRANSPORT} userList={userList} dataList={allDataList}></AgSegmentCard>}

                                {originWarehouseCheck() &&
                                <AgSegmentCard type={VENDOR_TYPES.ORIGIN_WAREHOUSE} userList={userList} dataList={allDataList}></AgSegmentCard>}
                       
                                {bookingData.contract?.fbaOCC===fbaOCC &&
                                <AgSegmentCard type={VENDOR_TYPES.ORIGIN_CUSTOMS_CLEARANCE} userList={userList} dataList={allDataList}></AgSegmentCard>}

                                {bookingData.contract.shipmentType===lclShipmentType &&
                                <AgSegmentCard type={VENDOR_TYPES.LCL_CONSOLIDATOR} userList={userList} dataList={allDataList}></AgSegmentCard>}

                                
                                {oceanCarrierCheck() &&
                                <AgSegmentCard type={VENDOR_TYPES.OCEAN_CARRIER} userList={userList} dataList={allDataList}></AgSegmentCard>}

                                {airFreightAndAWBCheck() &&
                                <>
                                 <AgSegmentCard type={VENDOR_TYPES.AIR_FREIGHT} userList={userList} dataList={allDataList} />
                                 <AgSegmentCard type={VENDOR_TYPES.AWB_RELEASE} userList={userList} dataList={allDataList} />
                                </>
                                }

                                {originCFSOperatorVendor() &&
                                <AgSegmentCard type={VENDOR_TYPES.ORIGIN_CFS_OPERATOR} userList={userList} dataList={allDataList}></AgSegmentCard>}

                                {destCFSOperatorVendor() &&
                                <AgSegmentCard type={VENDOR_TYPES.DESTINATION_CFS_OPERATOR} userList={userList} dataList={allDataList}></AgSegmentCard>}

                                {blReleaseCheck() &&
                                <AgSegmentCard type={VENDOR_TYPES.BL_RELEASE} userList={userList} dataList={allDataList}></AgSegmentCard>}

                                {doReleaseCheck() && 
                                <AgSegmentCard type={VENDOR_TYPES.DO_RELEASE} userList={userList} dataList={allDataList}></AgSegmentCard>}

                                {bookingData.contract?.fbaDCC===fbaDCC &&
                                    <AgSegmentCard type={VENDOR_TYPES.DESTINATION_CUSTOMS_CLEARANCE} userList={userList} dataList={allDataList}></AgSegmentCard>}

                                {destWarehouseCheck() &&
                                <AgSegmentCard type={VENDOR_TYPES.DESTINATION_WAREHOUSE} userList={userList} dataList={allDataList}></AgSegmentCard>}

                                {destTransCheck() && 
                                 (
                                    <div style={{ marginBottom: "16px" }}>
                                      <h6 className="title is-6" style={{ fontFamily: "product_sans_bold" }}>
                                        {VENDOR_TYPES.DESTINATION_TRANSPORT}
                                      </h6>
                                      <div className={`shadow-custom`} style={vendorCheck()?{ width: "100%", boxSizing: "border-box", padding: "1rem" }:{ width: "100%", boxSizing: "border-box", paddingTop: "6rem", paddingLeft:'1rem',paddingRight:'1rem', paddingBottom:'1rem' }}>
                                        {/* Assign User Dropdown */}
                                        {vendorCheck() &&
                                        <div className="flex justify-end mb-4">
                                          <div style={{ width: "300px" }}>
                                            <label className="block text-gray-700 text-sm font-bold mb-2">
                                              Assign User
                                            </label>
                                            <select
                                              value={bookingData.vendor.destinationTransport.assignUser}
                                              onChange={handleUserChange}
                                              className="select is-small w-full"
                                            >
                                              <option value="">
                                                Select User
                                              </option>
                                              {userList?.length>0 && userList.map((user, index) => (
                                                <option key={index} value={user}>
                                                  {user}
                                                </option>
                                              ))}
                                            </select>
                                          </div>
                                        </div>}
                                
                               
                                        {destinationsList.map(( { key, label }, index) => (
                                          <div key={key} className="mb-6" style={index === 0 ? { marginTop: '-68px' } : {}}>
                                            <h6 className="text-lg font-bold mb-5">{label}</h6>
                                          
                                
                                                <div className="select is-small" style={{ width: "250px",marginBottom: "0.7rem"  }}>
                                                <select
                                                  value={bookingData?.vendor?.destinationTransport[key]?.vendorId || ""}
                                                  onChange={(e) => handleVendorChange(e, key)}
                                                  className="select is-small w-full"
                                                >
                                                  <option value="">
                                                    Select Vendor
                                                  </option>
                                                  {dataList.length>0 && dataList.map((val) => (
                                                    <option key={val.entityID} value={val.entityID}>
                                                    {val.entityName}
                                                  </option>
                                                  ))}
                                                </select>
                                              </div>
                                              
                                              
                                
                                            
                                            <div style={{ display: "flex", alignItems: "center", marginBottom: "0.7rem" }}>
                                            <label
                                                  style={{
                                                    display: "block",
                                                    fontSize: "0.9rem",
                                                    marginBottom: "0.5rem",
                                                    marginLeft: "0rem",
                                                    marginRight: "0.5rem",
                                                    marginTop: "1rem",
                                                  }}
                                                >
                                                  Upload Supporting Documents for Cost
                                                </label>
                                                {!bookingData.vendor.destinationTransport[key].doc.filename && (
                                                  <input type="file" disabled={false} style={{fontSize: '0.8rem'}}
                                                    onChange={(e1) => {
                                                      change(e1.target.files,key,bookingData.entityId+"_"+bookingData._id+"_"+key)
                                                    }}
                                                  />
                                                )}
                                                {bookingData.vendor.destinationTransport[key].doc.filename && (
                                                  <div>
                                                    <a
                                                      target="_blank"
                                                      href={server + '/api/v1/filestore/getfile/' +bookingData.vendor.destinationTransport[key].doc.pkey+'/' +bookingData.vendor.destinationTransport[key].doc.filename}
                                                      rel="noreferrer"
                                                    >
                                                      {bookingData.vendor.destinationTransport[key].doc.filename}
                                                    </a>
                                                    &nbsp;
                                                    <button
                                                      className="button is-small is-danger"
                                                      onClick={() => handleDeleteFile(key)}
                                                    >
                                                      <i className="fa fa-trash" aria-hidden="true"></i>
                                                    </button>
                                                  </div>
                                                )}
                                              </div>
                                
                                             
                                                <input
                                                  type="text"
                                                  value={bookingData.vendor.destinationTransport[key].remarks}
                                                  onChange={(e) => handleRemarksChange(e, key)}
                                                  name="specialRemarks"
                                                  placeholder="Enter Remarks (if any)"
                                                  className="input is-small w-full"
                                                  style={{
                                                    marginRight: "10px",
                                                    width: "250px",
                                                    marginLeft: "0rem",
                                                    marginTop: "0.5rem",
                                                    marginBottom:"-1rem"
                                                  }}
                                                />
                                {contactList[key]?.length>0 && validateContactList(contactList[key]) ?
                                        <div className="px-4 mt-2">
                                                <div className="is-flex pt-4">
                                                  <table
                                                    className="table_caf_fin"
                                                    style={{
                                                      width: "50%",
                                                      margin: "10px auto",
                                                      borderCollapse: "collapse",
                                                      fontSize: "14px",
                                                      marginLeft: "-15px",
                                                      tableLayout: "fixed",
                                                      border: "1px solid #ccc",
                                                    }}
                                                  >
                                                    <thead style={{ backgroundColor: "#f5f5f5" }}>
                                                      <tr>
                                                        <th style={{ padding: "6px", border: "1px solid #ccc" }}>Contact Person</th>
                                                        <th style={{ padding: "6px", border: "1px solid #ccc" }}>Description/Department</th>
                                                        <th style={{ padding: "6px", border: "1px solid #ccc" }}>Contact Number</th>
                                                        <th style={{ padding: "6px", border: "1px solid #ccc" }}>Email</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody style={{ backgroundColor: "white" }}>
                                                      {contactList[key]?.map((r, i) => (
                                                        <tr key={i} style={{ backgroundColor: "white" }}>
                                                          <td style={{ border: "1px solid #ccc", padding: "4px" }}>
                                                            <input
                                                              className="inp_table_caf"
                                                              value={r.customercontact}
                                                              disabled
                                                              style={{
                                                                fontSize: "12px",
                                                                width: "100%",
                                                                border: "none",
                                                                backgroundColor: "white",
                                                              }}
                                                            />
                                                          </td>
                                                          <td style={{ border: "1px solid #ccc", padding: "4px" }}>
                                                            <input
                                                              className="inp_table_caf"
                                                              value={r.department}
                                                              disabled
                                                              style={{
                                                                fontSize: "12px",
                                                                width: "100%",
                                                                border: "none",
                                                                backgroundColor: "white",
                                                              }}
                                                            />
                                                          </td>
                                                          <td style={{ border: "1px solid #ccc", padding: "4px" }}>
                                                            <input
                                                              type="number"
                                                              className="inp_table_caf"
                                                              value={r.contactnum}
                                                              disabled
                                                              style={{
                                                                fontSize: "12px",
                                                                width: "100%",
                                                                border: "none",
                                                                backgroundColor: "white",
                                                              }}
                                                            />
                                                          </td>
                                                          <td style={{ border: "1px solid #ccc", padding: "4px" }}>
                                                            <input
                                                              className="inp_table_caf"
                                                              value={r.email}
                                                              disabled
                                                              style={{
                                                                fontSize: "12px",
                                                                width: "100%",
                                                                border: "none",
                                                                backgroundColor: "white",
                                                              }}
                                                            />
                                                          </td>
                                                        </tr>
                                                      ))}
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                              :  contactFlag[key] === true  &&<div
                                              style={{
                                                height: "100px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                textAlign: "center",
                                              }}
                                            >
                                              Entity does not have any contact information. Please add contact details in Entity to assign vendor.
                                            </div>  
                                             } 
                                              
                                            
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  )}
                          

                            {(bookingData.status !== "CANCELLED" && bookingData.status !== "ARCHIVED") && showButtons  && 
                                <div style={{ marginTop: "20px", textAlign: "right" }}>
                                <button className="button is-danger" onClick={()=>{          
                                      if (bookingsStore?.current?.vendor && Object.keys(initialVendorData).length>0) {
                                        bookingsStore.current.vendor = JSON.parse(JSON.stringify(initialVendorData)); 
                                      }else{
                                        bookingsStore.current.vendor = {};
                                      }
                                       taskStore.vendorAssignment = false
                                }}>
                                Cancel
                                </button>
                                <button className="button is-primary" style={{backgroundColor:"#3b7ded", marginLeft: "10px" }} onClick={handleSave}>
                                Save
                                </button>
                            </div>}
                            {/* <p>
                                {
                                    (Comp) ? <Comp action={action} /> : <></>
                                }
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}