import { useSnapshot } from "valtio"

import {
  EntityV2Store,
  branchStore,
  configStore,
  loginStore,
  routerStore,
  DBbranchStore,
  AgmodalStore,
} from "../../state/CommonState"
import { $c, $cg, $cr, AgInput, AgSelect } from "../../components/AgComponents"
import EntityApi from "../../state/entities-api"
import AguserApi from "../../state/AguserApi"
import { quoteDataRec } from "../../state/CommonState"
import { BsFillEyeFill } from "react-icons/bs"
import {
  Columns,
  Col,
  Col2,
  Col3,
  Col4,
  Col5,
  Col6,
  Col12,
} from "../../Controls"
import { useEffect, useState } from "react"
import Api from "../../state/Api"
import { Country, State, City } from "country-state-city"
import { Roles, ServerLists } from "../../StaticData"
import { QuotationValidationStore } from "../../state/validation-stores"
//import stateList from "../../static-data/states";
import { Agyesno } from "../../components/Agyesno"
import { ImCancelCircle } from "react-icons/im"
import { HiMiniDocumentCheck } from "react-icons/hi2"
import { useRef } from "react"
import EntityUtils from "../../util/entityUtils"
import BranchUtils from "../../util/branchUtils"
import { DIVISION, BRANCH_STATUS, PERMISSIONS, VERTICALS, ROLES, SCOPE_OF_ACTIVITY, DEFAULT_AGRAGA_BRANCH, SALESVERTICAL } from "../../util/constants"
import BranchApi from "../../state/branch-api"
import { BiPlus } from "react-icons/bi"
import { MdDelete } from "react-icons/md"
import { BranchType } from "../../state/Types"
import { ReactComponent as Loader } from "../../assets/loader.svg"
import RoleUtils from "../../util/RoleUtils"
import uam_roles_data from "../../static-data/users_verticals"
import { loader } from "../../util/loader"
import GSTVerify from "../../dtactions/gst-verify"
import { dtactionStore } from "../../dtactions/dtaction-store"


export default function EditBranch(props) {
  function change(files, filetype, i, filekey) {
    const formData = new FormData()
    formData.append("myFile", files[0])
    formData.append("label", filetype)
    formData.append("key", i)
    fetch(`${process.env.REACT_APP_FILE_SERVER}/api/v1/filestore/storefile`, {
      method: "POST",
      headers: Api.H1(),
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          if (data.error) {
            configStore.setModalMessage(data.error)
          }
        } else {
          const documentObj = Branch.documents.filter(
            (r) => r.name === filetype
          )
          documentObj[0].fileName = files[0]?.name
          documentObj[0].fileLink = data?.key?.filename
          documentObj[0].pkey = data?.key?.pkey
          console.log(Branch)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  function isEditMode() {
    return props.mode === "edit"
  }

  function isViewMode() {
    return props.mode === "view"
  }
  function isNewMode() {
    return props.mode === "new"
  }

  function isPendingStatus() {
    return Branch?.status.toLowerCase() === BRANCH_STATUS.PENDING.toLowerCase()
  }

  function isVerifiedStatus() {
    return (
      Branch?.status.toLowerCase() === BRANCH_STATUS.VERIFIED.toLowerCase()
    )
  }

  const domain = loginStore.email.split("@")[1]
  const [branchesListForCBByVertical, setBranchesListForCBByVertical] = useState([]);
  const [agUsersList, setAGUsersList] = useState([]);
  const [salesList, setSalesList] = useState([])
  const [states, setstates] = useState([])
  const [cities, setcities] = useState([])
  const [csList, setCsList] = useState([])
  const [branchOpsDTList, setBranchOpsDTList] = useState([])
  const [relMgrSupplyList, setRelMgrSupplyList] = useState([])
  const [cadminList, setCadminList] = useState([])
  const [stateCode, setstateCode] = useState("")
  const [cursor, setCursor] = useState(0)
  const [verifyPopup, setVerifyPopup] = useState(false)
  const [approvalStatus, setApprovalStatus] = useState(null)
  const [message, setMessage] = useState("")
  const [appbtnstatus, setappbtnstatus] = useState(true)
  const [setcitysel, setsetcitysel] = useState({})
  const [setstatesel, setsetstatesel] = useState({})
  const [apprejbtnstatus, setapprejbtnstatus] = useState(true)
  const [val, setval] = useState("")
  const [state, setstate] = useState("")
  const ref = useRef(null)
  const ref1 = useRef(null)
  const refEntityCountry = useRef(null)
  const hiddenFileInput = useRef()
  const hiddenFileInput1 = useRef()
  const hiddenFileInput2 = useRef()
  const [advanceError, setAdvanceError] = useState(null)
  const refAdvanceReceived = useRef(null)
  const [pendingErrors, setPendingErrors] = useState({})
  const refAdvancePayable = useRef(null)
  const [advancePayableError, setAdvancePayableError] = useState(null)
  const [gstAvailable, setGstAvailable] = useState({})
  const [gstType, setgstType] = useState("")
  const [errors, setErrors] = useState({})
  const [updVehCopy, setupdVehCopy] = useState("")
  const [branchAddressArray, setbranchAddressArray] = useState([])
  const [alertcomment, setalertcomment] = useState("")
  const [Countries, setCountries] = useState([])
  const Entity = EntityV2Store.EntityRec
  const Branch = branchStore.BranchRec
  const [loading, setloading] = useState(false)
  const [CB_Checked, setCB_Checked] = useState(false)
  const [DT_Checked, setDT_Checked] = useState(false)
  const dbBranch = DBbranchStore.BranchRec


  Branch.branchName = Entity.entityName

  Branch.entityId = Entity.entityId

  const c = false;
  if (Entity.status == "INCOMPLETE" && props.mode == "new") {
    c = true;
  }

  // if (!Branch.relationshipManager) {
  //   Branch.relationshipManager = Entity.customer.relationshipManager
  // }
  // if (!Branch.customerService) {
  //   Branch.customerService = Entity.customer.customerService
  // }
  // if (!Branch.branchOperationManager) {
  //   Branch.branchOperationManager = Entity.customer.branchOperationManager
  // }

  // if (!Branch.relationShipManagerSupply) {
  //   Branch.relationShipManagerSupply = Entity.vendor.relationShipManagerSupply
  // }

  let title = ""
  function checkExistingCity(city) {
    return City?.getCitiesOfState(
      Entity?.entityCountryCode,
      Branch.state.split("/")[1]
    ).some((r1) => {
      let f = city.toLowerCase().includes(r1.name.toLowerCase());
      if(city.toLowerCase()==r1.name.toLowerCase()){
        return f === true;

      }
    });
  }

  const emptyFields = () => {
    if (!EntityUtils.isIndia(Entity)) {
      // Branch.gst = "";
      // const documentObj2 = Branch?.documents?.filter((r) => r.name === "Gst");
      // console.log("documentObj2--", documentObj2);
      // documentObj2[0].fileName = "";
      // documentObj2[0].fileLink = "";
      // documentObj2[0].pkey = "";
      if (EntityUtils?.isSoleProp(Entity) && EntityUtils.isIndia(Entity)) {
        const documentObj3 = Entity?.documents?.filter(
          (r) => r.name === "gstDeclaration"
        )
        documentObj3[0].fileName = ""
        documentObj3[0].fileLink = ""
        documentObj3[0].pkey = ""
      }
    }
  }

  emptyFields()
  const getStateandCityValue = async () => {
    const Branchh = await branchStore.BranchRec

    // check and debug line by line use console
    // const EntityCountryy = await Branchh?.country;
    const EntityCountryy = await Entity?.entityCountry
    const BranchCountry = Country.getAllCountries().filter(
      (r) => r.name === Entity?.entityCountry
    )

    const Statess = State.getStatesOfCountry(BranchCountry[0]?.isoCode)

    setstates(Statess)
    const Statecode = Statess.filter((r) => r.name === Branchh?.state)

    setstateCode(Statecode[0]?.isoCode)
    const cities = City.getCitiesOfState(
      BranchCountry[0]?.isoCode,
      Statecode[0]?.isoCode
    )

    setcities(cities)
  }

  useEffect(() => {
    async function setStateCity() {
      getStateandCityValue()
    }
    filterCountries()
    setStateCity()
    Branch.country = Entity.entityCountry
    Branch.branchcountryCode = Entity.entityCountryCode
    // setbranchAddressArray(Branch.branchAddresses)
    if (isNewMode()) {
      Branch.advanceReceivableForDT = Entity.advanceReceivableForDT
      Branch.advancePayable = Entity.advancePayable
    }
  }, [])

  useEffect(() => {
    const input = ref1.current
    console.log(input, cursor)
    if (input) {
      input.setSelectionRange(cursor, cursor)
    }
  }, [ref1, cursor])

  const filterCountries = () => {
    const array = Country.getAllCountries().filter((x, i) =>
      State.getStatesOfCountry(x.isoCode).length !== 0
    )
    setCountries(array)
  }

  switch (props.mode) {
    case "edit": //(${Branch?._id}) ${Branch?.status}
      title = `Modify (${Branch?.branchId}) ${Branch?.status}`
      break
    case "view":
      title = `View (${Branch?.branchId}) ${Branch?.status}`
      break
    case "new":
      title = "Add New Branch"
      break
    default:
      title = "Branch"
      break
  }
  useSnapshot(EntityV2Store)
  useSnapshot(QuotationValidationStore)
  useSnapshot(branchStore)
  useSnapshot(AgmodalStore)
  const _params = (label, name, type = "text", dataList = []) => {
    return {
      type,
      name,
      label,
      record: quoteDataRec,
      dataList,
    }
  }

  const [hideEarlierRMMessage, setHideEarlierRMMessage] = useState(false);
  const [hideEarlierCSMessage, setHideEarlierCSMessage] = useState(false);
  // const [agragaBranchSelected, setAgragaBranchSelected] = useState("default");


  const [selectedCustomerService, setSelectedCustomerService] = useState("default");

  const handleDropdownChange = (e, section, field) => {
    const value = e.target.value;
    if (field === "customerService") {
      setSelectedCustomerService(value);
    }
    handleChange(e, section, field);
  };

  const [selectedRelationshipManager, setSelectedRelationshipManager] = useState("default");

  //const [historicalRM, setHistoricalRM] = useState(null);

  const handleDropdownChange2 = (e, section, field) => {
    const value = e.target.value;
    if (field === "relationshipManager") {
      setSelectedRelationshipManager(value); // Update selection state
    }
    handleChange(e, section, field); // Call the existing handleChange function
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }
  const handleClick1 = (event) => {
    hiddenFileInput1.current.click()
  }
  const filterUsers1 = (users, criteria) => {
    return users.filter(criteria)
  }

  const setLists = (users) => {
    let agusers = users.filter(
      (e) => e?.email?.endsWith("agraga.com") && e.status === "ACTIVE"
    )
    setAGUsersList(agusers)
    const filterByRoleAndVertical = (role, vertical) => (e) =>
      e.vertical === vertical && e.role?.includes(role)
    agusers.sort((a, b) => a.firstName?.localeCompare(b.firstName))

    let relMgrSupplyUsersList =
      agusers.filter((e) => ((e.vertical === VERTICALS.DOMESTIC_TRANSPORT && e.role?.includes(ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY))))


    const filterByRoleAndVerticalAndBranch = (role, vertical) => (e) => {
      // let branchCondition
      // if (Branch?.scopeOfBusiness?.includes("Cross Border") && Branch?.scopeOfBusiness?.includes("Domestic Transport")) {
      //   if (vertical === VERTICALS.CROSS_BORDER) {
      //     if (Branch?.crossBorder?.agragaBranch?.length > 0)
      //       branchCondition = e?.branch?.trim() === Branch?.crossBorder?.agragaBranch
      //   } else if (vertical === VERTICALS.DOMESTIC_TRANSPORT) {
      //     if (Branch?.domesticTransport?.agragaBranch?.length > 0)
      //       branchCondition = e?.branch?.trim() === Branch?.domesticTransport?.agragaBranch
      //   }
      // }
      // else if (Branch.scopeOfBusiness.includes("Cross Border")) {
      //   if (vertical === VERTICALS.CROSS_BORDER) {
      //     if (Branch?.crossBorder?.agragaBranch.length > 0)
      //       branchCondition = e?.branch?.trim() === Branch?.crossBorder?.agragaBranch
      //   }
      // } else if (Branch.scopeOfBusiness.includes("Domestic Transport")) {

      //   if (vertical === VERTICALS.DOMESTIC_TRANSPORT) {
      //     if (Branch?.domesticTransport?.agragaBranch.length > 0)
      //       branchCondition = e?.branch?.trim() === Branch?.domesticTransport?.agragaBranch
      //   }
      // }
      // return e.vertical === vertical && e.role === role && branchCondition
      const crossBorder = Branch?.scopeOfBusiness?.includes("Cross Border");
      const domesticTransport = Branch?.scopeOfBusiness?.includes("Domestic Transport");
    
      const isCrossBorder = vertical === VERTICALS.CROSS_BORDER;
      const isDomesticTransport = vertical === VERTICALS.DOMESTIC_TRANSPORT;
    
      const agragaBranch = isCrossBorder ? Branch?.crossBorder?.agragaBranch : Branch?.domesticTransport?.agragaBranch;
      const branchExists = agragaBranch?.trim().length > 0;
    
      const isBothScopes = crossBorder && domesticTransport;
      const isAnyScope = crossBorder || domesticTransport;
    
      const branchCondition = (isBothScopes || isAnyScope) && branchExists && e?.branch?.trim() === agragaBranch;
    
      return e.vertical === vertical && e.role?.includes(role) && branchCondition;
    }

    const getZonalByBranchAndVertical = (branch, vertical) => {
      const { originalDTData, originalCBData } = uam_roles_data;
  
      const findZonal = (data, branch) => {
        const result = data.find(entry => entry.Branch.toLowerCase() === branch.toLowerCase());
        return result ? result.Zonal : 'Zonal information not found';
      };
  
      if (vertical === VERTICALS.CROSS_BORDER) {
        return findZonal(originalCBData, branch);
      } else if(vertical ===  VERTICALS.DOMESTIC_TRANSPORT) {
        return findZonal(originalDTData, branch);
      } else {
        return 'Invalid vertical';
      }
    }
  
    const getRegionalByBranchAndVertical = (branch, vertical) => {
      const { originalDTData, originalCBData, originalCBDTData } = uam_roles_data;
  
      const findRegional = (data, branch) => {
        const result = data.find(entry => entry.Branch.toLowerCase() === branch.toLowerCase());
        return result ? result.Regional : 'Regional information not found';
      };
  
      if (vertical === VERTICALS.CB_DT) {
        return findRegional(originalCBDTData, branch);
      } else if(vertical ===  VERTICALS.DOMESTIC_TRANSPORT) {
        return findRegional(originalDTData, branch);
      } else {
        return 'Invalid vertical';
      }
    }

    const filterByRoleAndVerticalAndBranchForVendors = (vertical, primaryRole, secondaryRole, branchRole,departmentRole,nationalRole,productRole) => (e) => {
      let branchCondition = true
      const {  originalCBData } = uam_roles_data;
      const isBranchSelected = Entity?.agragaBranch?.length > 0;
      const selectedZone = isBranchSelected ? getZonalByBranchAndVertical(Entity?.agragaBranch,VERTICALS.CROSS_BORDER) : null;
      const selectedZones = selectedZone && selectedZone !== 'Zonal information not found' 
        ? selectedZone.split("&").map(zone => zone.trim().toLowerCase()) 
        : [...new Set(originalCBData.map(item => item.Zonal.toLowerCase()))];
    const userZone = e.zonal ? e.zonal.trim().toLowerCase().split("&").map(zone => zone.trim()) : [];
    // const isRelevantZonalUser = selectedZones.length > 0 && userZone.some(zone => selectedZones.includes(zone));
    const isRelevantZonalUser =
        selectedZones.length > 0 &&
        selectedZones.some((selectedZone) => {
          const zones = selectedZone.split("&").map((z) => z.trim());
          return zones.every((zone) => userZone.includes(zone));
        });
    const isNationalUser = e.division === "National";
    if (vertical === VERTICALS.CROSS_BORDER && primaryRole === ROLES.CB.PROCUREMENT) {
      const branch = Entity?.agragaBranch?.trim();
      const userBranch = e?.branch?.trim();
      branchCondition = isRelevantZonalUser || isNationalUser ||
        (isBranchSelected &&
          (branch === "All" ? userBranch : branch === userBranch));
    }
     else if(vertical === VERTICALS.DOMESTIC_TRANSPORT){
      if (primaryRole?.includes(ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY) || secondaryRole?.includes(ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING)) {
        const branch = Entity?.agragaBranch?.trim();
        const userBranch = e?.branch?.trim();
        branchCondition = isRelevantZonalUser ||
            (branch === "All" ? userBranch : isBranchSelected && branch === userBranch) ||
            !isBranchSelected;
        }
  } 
      else if (vertical === VERTICALS.CENTRAL) {
        if (e.department?.includes(departmentRole) && e.vertical === VERTICALS.CENTRAL) {
          return e.department.includes(ROLES.CENTRAL.AGRAGA_ADMIN);
        }
        branchCondition = e.department?.includes(primaryRole);
      }  
     const additionalRolesCondition =
     (e.department?.includes(departmentRole) && e.vertical === VERTICALS.CENTRAL) ||
     (e.department?.includes(nationalRole)&& e.vertical === VERTICALS.CENTRAL)||
     (e.department?.includes(productRole)&&(e.division==="National"|| (isBranchSelected && (e.branch === Entity?.agragaBranch || isRelevantZonalUser))));
    const isProductAdoption = e.department?.includes(ROLES.CENTRAL.PRODUCT_ADOPTION);
    const isAdminUser = e.role?.includes('admin'); 
    if (isAdminUser) {
    return false;
  }
  const isMatch = (
      (e.vertical === vertical &&
          (e.department?.includes(primaryRole) || e.department?.includes(secondaryRole) || isProductAdoption) &&
          (branchCondition || isNationalUser)) ||
      (e.department?.includes(branchRole) && e.vertical === VERTICALS.CENTRAL) || 
      additionalRolesCondition ||
      (isProductAdoption && vertical === VERTICALS.CENTRAL)
  );
  return isMatch;
};
    // setRelMgrSupplyList(relMgrSupplyUsersList)
    if (EntityUtils.isCustomer(Entity)) {
      setSalesList(filterUsers1(agusers, filterByRoleAndVertical(ROLES.DT.RELATIONSHIP_MANAGER_DEMAND, VERTICALS.DOMESTIC_TRANSPORT)))
      setCsList(filterUsers1(agusers, (e) => e.vertical === VERTICALS.CROSS_BORDER && [ROLES.CB.CUSTOMER_SUCCESS, ROLES.DT.CUSTOMER_SUCCESS].includes(e.role)))
      // setSalesListForCB(filterUsers(agusers, filterByRoleAndVerticalAndBranch(ROLES.CB.RELATIONSHIP_MANAGER, VERTICALS.CROSS_BORDER)))
      // setSalesListForDT(filterUsers(agusers, filterByRoleAndVerticalAndBranch(ROLES.DT.RELATIONSHIP_MANAGER_DEMAND, VERTICALS.DOMESTIC_TRANSPORT)))
      // setCsListForCB(filterUsers(agusers, filterByRoleAndVerticalAndBranch(ROLES.CB.CUSTOMER_SUCCESS, VERTICALS.CROSS_BORDER)))
      // setCsListForDT(filterUsers(agusers, filterByRoleAndVerticalAndBranch(ROLES.DT.CUSTOMER_SUCCESS, VERTICALS.DOMESTIC_TRANSPORT)))
      // setBranchOpsDTList(filterUsers(agusers, filterByRoleAndVerticalAndBranch(ROLES.DT.DT_OPERATIONS, VERTICALS.DOMESTIC_TRANSPORT)))
      const baseRolesCB= [
        {role: ROLES.CB.BRANCH_HEAD,vertical: VERTICALS.CROSS_BORDER},
        {role: ROLES.CB.ADMIN,vertical: VERTICALS.CROSS_BORDER},
        {role: ROLES.CB_DT.BRANCH_HEAD,vertical: VERTICALS.CB_DT},
        {role: ROLES.CB_DT.ADMIN,vertical: VERTICALS.CB_DT},
        {role: ROLES.CENTRAL.PRODUCT_ADOPTION,vertical: VERTICALS.CENTRAL},
        {role: ROLES.CENTRAL.AGRAGA_ADMIN,vertical: VERTICALS.CENTRAL},
      ]
      const baseRolesDT= [
        {role: ROLES.CB.BRANCH_HEAD,vertical: VERTICALS.DOMESTIC_TRANSPORT},
        {role: ROLES.CB.ADMIN,vertical: VERTICALS.DOMESTIC_TRANSPORT},
        {role: ROLES.CB_DT.BRANCH_HEAD,vertical: VERTICALS.CB_DT},
        {role: ROLES.CB_DT.ADMIN,vertical: VERTICALS.CB_DT},
        {role: ROLES.CENTRAL.PRODUCT_ADOPTION,vertical: VERTICALS.CENTRAL},
        {role: ROLES.CENTRAL.AGRAGA_ADMIN,vertical: VERTICALS.CENTRAL},
      ]
      const rolesRMCB = [
        {role: ROLES.CB.RELATIONSHIP_MANAGER,vertical: VERTICALS.CROSS_BORDER},
        {role: ROLES.CB_DT.RELATIONSHIP_MANAGER,vertical: VERTICALS.CB_DT}, 
        ...baseRolesCB
      ]
      const rolesRMDT = [
        {role: ROLES.DT.RELATIONSHIP_MANAGER_DEMAND,vertical: VERTICALS.DOMESTIC_TRANSPORT},
        {role: ROLES.CB_DT.RELATIONSHIP_MANAGER,vertical: VERTICALS.CB_DT},
        ...baseRolesDT
      ]
      const rolesCSCB = [
        {role: ROLES.CB.CUSTOMER_SUCCESS,vertical: VERTICALS.CROSS_BORDER},
        {role: ROLES.CB_DT.CUSTOMER_SUCCESS,vertical: VERTICALS.CB_DT},
        ...baseRolesCB
      ]
      const rolesCSDT = [
        {role: ROLES.DT.CUSTOMER_SUCCESS,vertical: VERTICALS.DOMESTIC_TRANSPORT},
        {role: ROLES.CB_DT.CUSTOMER_SUCCESS,vertical: VERTICALS.CB_DT},
        ...baseRolesDT
      ]
      const rolesBOpsDT = [
        {role: ROLES.DT.DT_OPERATIONS,vertical: VERTICALS.DOMESTIC_TRANSPORT},
        {role: ROLES.DT.BRANCH_HEAD,vertical: VERTICALS.DOMESTIC_TRANSPORT},
        {role: ROLES.DT.ADMIN,vertical: VERTICALS.DOMESTIC_TRANSPORT},
        {role: ROLES.CENTRAL.PRODUCT_ADOPTION,vertical: VERTICALS.CENTRAL},
        {role: ROLES.CENTRAL.AGRAGA_ADMIN,vertical: VERTICALS.CENTRAL},
      ]
      // CB
      const filterUsers = (user, roleCondition, branchCondition) => {
        if (!user.role || !user.vertical) return false;
    
        const hasCrossBorder = Branch?.scopeOfBusiness.includes("Cross Border");
        const crossBorderBranch = Branch?.crossBorder?.agragaBranch?.trim();
        const crossBorderZone = getZonalByBranchAndVertical(crossBorderBranch, user.vertical);
        const crossBorderRegion = getRegionalByBranchAndVertical(crossBorderBranch, user.vertical);
    
        const selectedBranch = crossBorderBranch === user?.branch?.trim();
        const selectedBranchZone = crossBorderZone === user?.zonal?.trim();
        const selectedBranchRegion = crossBorderRegion === user?.regional?.trim();
    
        // console.log("User Details:", user);
        // console.log("Cross Border Details:", { crossBorderBranch, crossBorderZone, crossBorderRegion });
        // console.log("Selected Branch Conditions:", { selectedBranch, selectedBranchZone, selectedBranchRegion });
        // console.log("Has Cross Border:", hasCrossBorder);
    
        if (!hasCrossBorder || !crossBorderBranch?.length) return false;
    
        const isCrossBorderVertical = [VERTICALS.CROSS_BORDER, VERTICALS.CB_DT, VERTICALS.CENTRAL].includes(user.vertical);
        if (!isCrossBorderVertical) return false;
    
        // console.log("Cross Border and Vertical Check Passed");
    
        const branchConditionResult = 
            (isCrossBorderVertical && selectedBranch) || 
            (isCrossBorderVertical && crossBorderZone === user?.zonal?.trim());
    
        // console.log("Branch Condition Result:", branchConditionResult);
    
        const usersConditionResult = roleCondition(user, selectedBranch, selectedBranchZone, selectedBranchRegion);
        // console.log("Users Condition Result:", usersConditionResult);
    
        const finalResult = rolesRMCB.some((role) => {
            const roleMatch = role.role.includes(user.role) && role.vertical === user.vertical;
            // console.log("Role Match Check:", roleMatch);
            return usersConditionResult;
        });
    
        return finalResult;
    };
      
      const filteredUsersForCBRM = agusers.filter((user) => {
        return filterUsers(user, (u, b, bz, br) => {
            const userEmail = user?.email;
            const userRMSalesVertical = user?.RMSalesVertical;
            const userAdminSalesVertical = user?.AdminSalesVertical;
            const entitySalesVertical = Branch?.crossBorder?.salesVertical;
            const userBranch = user?.branch;
            const entityAgragaBranch = Branch?.crossBorder?.agragaBranch;
            const userDepartment = u?.department;
        
            // console.log("Logs sales:", userEmail, userRMSalesVertical, userAdminSalesVertical, entitySalesVertical, userBranch, entityAgragaBranch);
        
            const verticalCBDT = u?.vertical === VERTICALS.CROSS_BORDER || u?.vertical === VERTICALS.CB_DT;
            // console.log("Vertical CBDT:", verticalCBDT, user);
        
            const isAdmin = ROLES.CB.ADMIN === (userDepartment);
            const isNational = user?.division === DIVISION.NATIONAL;
            const isZonal = user?.division === DIVISION.ZONAL;
            const isBranchHeadOrRM = ROLES.CB.BRANCH_HEAD.includes(userDepartment) || ROLES.CB.RELATIONSHIP_MANAGER === (userDepartment);
            const isRM = ROLES.CB.RELATIONSHIP_MANAGER === (userDepartment);
            const isAAGRAAdmin = ROLES.CENTRAL.AGRAGA_ADMIN === userDepartment;
            const isProductAdoption = ROLES.CENTRAL.PRODUCT_ADOPTION === userDepartment;
        
            const adminSalesVerticalMatch = userAdminSalesVertical?.some(vertical => entitySalesVertical === (vertical));
            const rmsalesVerticalMatch = userRMSalesVertical?.some(vertical => entitySalesVertical === (vertical));
        
            const adminCondition = verticalCBDT && isAdmin && (isNational ? adminSalesVerticalMatch : (isZonal ? (adminSalesVerticalMatch && bz) : false));
            const branchHeadOrRMCondition = verticalCBDT && isBranchHeadOrRM && b;
            const rmCondition = verticalCBDT && isRM && (bz || br || isNational) && rmsalesVerticalMatch;
            const centralCondition = isAAGRAAdmin || isProductAdoption;
    
            // console.log("Conditions Admin:", adminCondition, 
            //   "\n  - Vertical CBDT:", verticalCBDT, 
            //   "\n  - Is Admin:", isAdmin, 
            //   "\n  - Is National:", isNational, 
            //   "\n  - Admin Sales Vertical Match:", adminSalesVerticalMatch, 
            //   "\n  - Is Zonal:", isZonal, 
            //   "\n  - Bz:", bz);
            
            // console.log("Conditions Branch Head or RM:", branchHeadOrRMCondition, 
            //   "\n  - Vertical CBDT:", verticalCBDT, 
            //   "\n  - Is Branch Head or RM:", isBranchHeadOrRM, 
            //   "\n  - B:", b);
            
            // console.log("Conditions RM:", rmCondition, 
            //   "\n  - Vertical CBDT:", verticalCBDT, 
            //   "\n  - Is RM:", isRM, 
            //   "\n  - Bz or Br:", bz || br, 
            //   "\n  - RM Sales Vertical Match:", rmsalesVerticalMatch, 
            //   "\n  - Branch Match:", br);
            
            // console.log("Conditions Central:", centralCondition, 
            //   "\n  - Is AGRAGA Admin:", isAAGRAAdmin, 
            //   "\n  - Is Product Adoption:", isProductAdoption);
        
            return (
                adminCondition ||
                branchHeadOrRMCondition ||
                rmCondition ||
                centralCondition
            );
        }, (u) => true);
    });
      
      setSalesListForCB(filteredUsersForCBRM);

      const filteredUsersForCBCS = agusers.filter((user) => {
        return filterUsers(user, (u, b, bz, br) => {
            const userEmail = user?.email;
            const userRMSalesVertical = user?.RMSalesVertical;
            const userAdminSalesVertical = user?.AdminSalesVertical;
            const entitySalesVertical = Branch?.crossBorder?.salesVertical;
            const userBranch = user?.branch;
            const entityAgragaBranch = Branch?.crossBorder?.agragaBranch;
            const userDepartment = u?.department;
    
            // console.log("Logs sales:", userEmail, userRMSalesVertical, userAdminSalesVertical, entitySalesVertical, userBranch, entityAgragaBranch);
    
            const verticalCBDT = u?.vertical === VERTICALS.CROSS_BORDER || u?.vertical === VERTICALS.CB_DT;
            // console.log("Vertical CBDT:", verticalCBDT, user);
    
            const isAdmin = ROLES.CB.ADMIN === (userDepartment);
            const isNational = user?.division === DIVISION.NATIONAL;
            const isZonal = user?.division === DIVISION.ZONAL;
            const isBranchHeadOrCS = ROLES.CB.BRANCH_HEAD.includes(userDepartment) || ROLES.CB.CUSTOMER_SUCCESS.includes(userDepartment);
            const isBranchHeadOrRM = ROLES.CB.BRANCH_HEAD.includes(userDepartment) || ROLES.CB.RELATIONSHIP_MANAGER === (userDepartment);
            const isCS = ROLES.CB.CUSTOMER_SUCCESS.includes(userDepartment);
            const isRM = ROLES.CB.RELATIONSHIP_MANAGER.includes(userDepartment);
            const isAAGRAAdmin = ROLES.CENTRAL.AGRAGA_ADMIN === userDepartment;
            const isProductAdoption = ROLES.CENTRAL.PRODUCT_ADOPTION === userDepartment;
    
            const adminSalesVerticalMatch = userAdminSalesVertical?.some(vertical => entitySalesVertical === (vertical));
            const rmsalesVerticalMatch = userRMSalesVertical?.some(vertical => entitySalesVertical === (vertical));
    
            const adminCondition = verticalCBDT && isAdmin && (isNational ? adminSalesVerticalMatch : (isZonal ? (adminSalesVerticalMatch && bz) : false));
            const branchHeadOrCSCondition = verticalCBDT && (isBranchHeadOrCS || isBranchHeadOrRM) && b;
            const csCondition = verticalCBDT && isCS && (bz || br);
            const rmCondition = verticalCBDT && isRM && (bz || br || isNational) && rmsalesVerticalMatch;
            const centralCondition = isAAGRAAdmin || isProductAdoption;
    
            // console.log("Conditions Admin:", adminCondition, 
            //     "\n  - Vertical CBDT:", verticalCBDT, 
            //     "\n  - Is Admin:", isAdmin, 
            //     "\n  - Is National:", isNational, 
            //     "\n  - Admin Sales Vertical Match:", adminSalesVerticalMatch, 
            //     "\n  - Is Zonal:", isZonal, 
            //     "\n  - Bz:", bz);
            
            // console.log("Conditions Branch Head or CS:", branchHeadOrCSCondition, 
            //     "\n  - Vertical CBDT:", verticalCBDT, 
            //     "\n  - Is Branch Head or CS:", isBranchHeadOrCS, 
            //     "\n  - B:", b);
            
            // console.log("Conditions CS:", csCondition, 
            //     "\n  - Vertical CBDT:", verticalCBDT, 
            //     "\n  - Is CS:", isCS, 
            //     "\n  - Bz or Br:", bz || br);

            //     console.log("Conditions RM:", rmCondition, 
            //       "\n  - Vertical CBDT:", verticalCBDT, 
            //       "\n  - Is RM:", isRM, 
            //       "\n  - RM Sales Vertical Match:", rmsalesVerticalMatch, 
            //       "\n  - Bz or Br:", bz || br);
            
            // console.log("Conditions Central:", centralCondition, 
            //     "\n  - Is AGRAGA Admin:", isAAGRAAdmin, 
            //     "\n  - Is Product Adoption:", isProductAdoption);
    
            return (
                adminCondition ||
                branchHeadOrCSCondition ||
                csCondition ||
                rmCondition ||
                centralCondition
            );
        }, (u) => true);
    });

      setCsListForCB(filteredUsersForCBCS);

      //DT
      // DT
      
      const filterUsersDT = (user, roleCondition, branchCondition) => {
        if (user.role && user.vertical) {
          const hasDomesticTransport = Entity?.scopeOfBusiness.includes("Domestic Transport");
          const domesticTransportBranch = Entity?.customer?.domesticTransport?.agragaBranch?.trim();
          const domesticTransportZone = getZonalByBranchAndVertical(domesticTransportBranch, user.vertical);
          const domesticTransportRegion = getRegionalByBranchAndVertical(domesticTransportBranch, user.vertical);
      
          const selectedBranch = domesticTransportBranch === user?.branch?.trim();
          const selectedBranchZone = domesticTransportZone === user?.zonal?.trim();
          const selectedBranchRegion = domesticTransportRegion === user?.regional?.trim();
      
          let branchConditionResult = false;
          let usersConditionResult = false;
      
          if (
            hasDomesticTransport &&
            (user.vertical === VERTICALS.DOMESTIC_TRANSPORT || user.vertical === VERTICALS.CB_DT || user.vertical === VERTICALS.CENTRAL) &&
            domesticTransportBranch?.length > 0
          ) {
            branchConditionResult =
              ((user.vertical === VERTICALS.DOMESTIC_TRANSPORT || user.vertical === VERTICALS.CB_DT) && selectedBranch) ||
              ((user.vertical === VERTICALS.DOMESTIC_TRANSPORT || user.vertical === VERTICALS.CB_DT) && domesticTransportZone === user?.zonal?.trim());
      
            usersConditionResult = roleCondition(user, selectedBranch, selectedBranchZone, selectedBranchRegion);
          }
      
          return rolesRMCB.some((role) => {
            return (role.role.includes(user.role) && role.vertical === user.vertical && branchConditionResult) || usersConditionResult;
          });
        }
        return false;
      };
      
      const filteredUsersForDTRM = agusers.filter((user) => {
        return filterUsersDT(user, (u, b, bz, br) => {
          const verticalCBDT = u?.vertical === VERTICALS.DOMESTIC_TRANSPORT || u?.vertical === VERTICALS.CB_DT;
          return (
            (verticalCBDT && (ROLES.CB.ADMIN.includes(u?.role))) ||
            (verticalCBDT && (ROLES.CB.BRANCH_HEAD.includes(u?.role) || ROLES.CB.RELATIONSHIP_MANAGER.includes(u?.role)) && b) ||
            (verticalCBDT && (ROLES.CB.RELATIONSHIP_MANAGER.includes(u?.role)) && (bz || br)) ||
            (ROLES.CENTRAL.AGRAGA_ADMIN.includes(u?.role) || ROLES.CENTRAL.PRODUCT_ADOPTION.includes(u?.role))
          );
        }, (u) => true);
      });
      
      setSalesListForDT(filteredUsersForDTRM);
      
      const filteredUsersForDTCS = agusers.filter((user) => {
        return filterUsersDT(user, (u, b, bz, br) => {
          const verticalCBDT = u?.vertical === VERTICALS.DOMESTIC_TRANSPORT || u?.vertical === VERTICALS.CB_DT;
          return (
            (verticalCBDT && (ROLES.CB.ADMIN.includes(u?.role))) ||
            (verticalCBDT && (ROLES.CB.BRANCH_HEAD.includes(u?.role) || ROLES.CB.CUSTOMER_SUCCESS.includes(u?.role)) && b) ||
            (verticalCBDT && (ROLES.CB.CUSTOMER_SUCCESS.includes(u?.role)) && (bz || br)) ||
            (ROLES.CENTRAL.AGRAGA_ADMIN.includes(u?.role) || ROLES.CENTRAL.PRODUCT_ADOPTION.includes(u?.role))
          );
        }, (u) => true);
      });
      
      setCsListForDT(filteredUsersForDTCS);

      const filteredUsersForDTBOps = agusers.filter((user) => {
        return filterUsersDT(user, (u, b, bz, br) => {
          const verticalDT = u?.vertical === VERTICALS.DOMESTIC_TRANSPORT
          return (
            (verticalDT && (ROLES.DT.ADMIN.includes(u?.role))) ||
            (verticalDT && (ROLES.DT.BRANCH_HEAD.includes(u?.role) || ROLES.DT.DT_OPERATIONS.includes(u?.role)) && b) ||
            (verticalDT && (ROLES.DT.DT_OPERATIONS.includes(u?.role)) && (bz || br)) ||
            (ROLES.CENTRAL.AGRAGA_ADMIN.includes(u?.role) || ROLES.CENTRAL.PRODUCT_ADOPTION.includes(u?.role))
          );
        }, (u) => true);
      });
      setBranchOpsDTList(filteredUsersForDTBOps)
    } else {
      if (EntityUtils.isRoadTransport(Entity)) {
        // For Road Transport entities, include DT and Central roles, along with Procurement users
        const roadTransportUsers = filterUsers1(
          agusers,
          filterByRoleAndVerticalAndBranchForVendors(
            VERTICALS.DOMESTIC_TRANSPORT,
            ROLES.DT.RELATIONSHIP_MANAGER_SUPPLY,
            ROLES.DT.RMSUPPLY_OPERATIONSANDINVOICING,
            ROLES.CENTRAL.CENTRAL_OPERATIONS,
            ROLES.CENTRAL.PRODUCT_ADOPTION,
            ROLES.CENTRAL.AGRAGA_ADMIN
          )
        );
      
        // Procurement users filter with branch and zone conditions
        const procurementUsers = filterUsers1(
          agusers,
          filterByRoleAndVerticalAndBranchForVendors(
            VERTICALS.CROSS_BORDER,
            ROLES.CB.PROCUREMENT
          )
        );
      
        // Combine the lists and sort alphabetically by firstName
        const combinedUsers = [...roadTransportUsers, ...procurementUsers].sort((a, b) =>
          a.firstName?.localeCompare(b.firstName)
        );
      
        setRelMgrSupplyList(combinedUsers);
      } else {
               const centralUsers = filterUsers1(
          agusers,
          filterByRoleAndVerticalAndBranchForVendors(
            VERTICALS.CENTRAL,
            ROLES.CENTRAL.CENTRAL_OPERATIONS,
            ROLES.CENTRAL.PRODUCT_ADOPTION,
            ROLES.CENTRAL.AGRAGA_ADMIN
          )
        );
        const procurementUsers = filterUsers1(
          agusers,
          filterByRoleAndVerticalAndBranchForVendors(
            VERTICALS.CROSS_BORDER,
            ROLES.CB.PROCUREMENT
          )
        );
        const combinedUsers = [...centralUsers, ...procurementUsers].sort((a, b) =>
          a.firstName?.localeCompare(b.firstName)
        );
      
        setRelMgrSupplyList(combinedUsers);
      }
    }
  }

  const filterByRoleAndVerticalForVendors =(vertical1,vertical2) => (e) =>{
    return (e.vertical === vertical1 || e.vertical === vertical2)
  }

  const customerDTProperties = [
    "DT_relationshipManager",
    "DT_customerService",
    "DT_branchOperationManager",
  ]
  const customerCBProperties = [
    "Customer_Service",
    "CB_relationshipManager"
  ]
  const vendorProperties = ["relationShipManagerSupply"]



  function optimizeProperties(categoryValues, properties, branch, selectedDropdown) {
    if (categoryValues.length > 0) {
      for (const property of properties) {
        if (property === selectedDropdown) {
          const selectedOption = categoryValues.find(
            (type) => type?._id === branch
          )

          if (!selectedOption) {
            branch = ""
            pendingErrors[property] = true
          }
        }
      }
    }
  }

  useEffect(() => {
    optimizeProperties(salesList, vendorProperties, Branch)
    optimizeProperties(salesListForDT, customerDTProperties, Branch?.domesticTransport?.relationshipManager, "DT_relationshipManager")
    optimizeProperties(salesListForCB, customerCBProperties, Branch?.crossBorder?.relationshipManager, "CB_relationshipManager")
    optimizeProperties(csListForDT, customerDTProperties, Branch?.domesticTransport?.customerService, "DT_customerService")
    optimizeProperties(csListForCB, customerCBProperties, Branch?.crossBorder?.customerService, "Customer_Service")
    optimizeProperties(branchOpsDTList, customerDTProperties, Branch?.domesticTransport?.branchOperationManager, "DT_branchOperationManager")
  }, [salesList])

  function trimStr(str) {
    if (!str) return str
    return str.replace(/^\s+/g, "")
  }

  const renderSalesOptions = (categoryValues, selectedValue) => {
    return categoryValues.map((type) => (
      <option
        key={type?._id}
        selected={shouldSelectOption(type?._id, selectedValue)}
        value={type._id}
      >
        {type?.firstName?.charAt(0).toUpperCase() +
          type?.firstName?.slice(1) +
          " " +
          type?.lastName +
          "  " +
          " ( " +
          type?._id +
          " ) "}
      </option>
    ))
  }

  const handleChange = (e, r, x) => {
    const value = e.target.value

    const clearErrorsAndSetEntityValue = async (
      key,
      entityKey,
      errorMessage
    ) => {
      clearErrorMessages(key)

      // if(key === "gst"){
        setGstVerify()
        
        pendingErrors.gstVerify=""

        

      if (value.length === 15) {
        // let response;
        // if(key === "gst"){
          if(gstData){
            Branch.state=""
            Branch.branchAddresses[0].city=""
            Branch.branchAddresses[0].address=""
            Branch.branchAddresses[0].pincode=""
          }
        await BranchApi.checkGst(value.toUpperCase(), (response) => {
          console.log(gstAvailable)
          // if (response?.data?.entityId !== Entity?.entityId) {
          setGstAvailable(response)
          if (response.status) {
            clearErrorMessages(r)
            pendingErrors["gst"] = `GST Exists - ${response?.data?.entityId}`
          } else {
            clearErrorMessages(r)
          }
          // }
          console.log(gstAvailable)
        })

        clearErrorMessages(r)
      } else if (value.length === 14) {
        if(gstData){
          Branch.state=""
          Branch.branchAddresses[0].city=""
          Branch.branchAddresses[0].address=""
          Branch.branchAddresses[0].pincode=""
        }
        setGstAvailable({})
        setGstData()
        clearErrorMessages(r)

      }


      if (entityKey) {
        Branch[entityKey] = trimStr(value)
      }

      if (errorMessage) {
        setErrors({ ...errors, [key]: errorMessage })
      }
    }

    clearErrorsAndSetEntityValue(x)

    if (r === "gst") {

      clearErrorMessages(r)
      if (e.target.value.length === 15) {
       

        BranchApi.checkGst(e.target.value.toUpperCase(), setGstAvailable)
      } else if (e.target.value.length === 14) {
        if(gstData){
          Branch.state=""
          Branch.branchAddresses[0].city=""
          Branch.branchAddresses[0].address=""
          Branch.branchAddresses[0].pincode=""
        }
        setGstAvailable({})
        setGstData()
        console.log("DFfd1")

      }
      setCursor(e.target.selectionStart)
    }

    if (r === "pincode") {
      let re = new RegExp("^([0-9]{0,6})$")
      if (!re.test(e.target.value)) {
        return null
      }

      const name = e.target.value
      errors[r] = name.length > 0 ? "" : "This field is required."
      setErrors(errors)
      return (Branch[r] = trimStr(name))
    } else if (r === "zipcode") {
      let re = new RegExp("^([0-9]{0,10})$")
      if (!re.test(e.target.value)) {
        return null
      }

      const name = e.target.value
      errors[r] = name.length > 0 ? "" : "This field is required."
      setErrors(errors)
      return (Branch[r] = trimStr(name))
    } else if (r === "address") {
      const name = e.target.value
      errors[r] = name.length > 0 ? "" : "This field is required."
      setErrors(errors)
      return (Branch[r] = trimStr(name.replace(/[^\x00-\x7F]/g, '')))
    } else if (r === "gst") {
      const name = e.target.value

      errors[r] = name.length > 0 ? "" : "This field is required."
      setErrors(errors)
      return (Branch[r] = trimStr(name.toUpperCase()))
    } else if (r === "city") {
      const name = e.target.value
      errors[r] = name.length > 0 ? "" : "This field is required."
      setErrors(errors)
      return (Branch[r] = trimStr(name.replace(/[^\x00-\x7F]/g, '')))
    } else if (r === "state") {
      const name = e.target.value
      errors[r] = name.length > 0 ? "" : "This field is required."
      setErrors(errors)
      BranchCityClear()

      return (Branch[r] = trimStr(name.replace(/[^\x00-\x7F]/g, '')))
    }
    else if (r === "domesticTransport") {
      const customerValue2 = trimStr(value)
      if (x === "DT_customerService") {
        errors.DT_customerService =
          customerValue2.length > 0 ? "" : "Please select a valid option."
        return Branch.domesticTransport.customerService = customerValue2

      }
      if (x === "DT_relationshipManager") {
        errors.DT_relationshipManager =
          customerValue2.length > 0 ? "" : "Please select a valid option."
        return Branch.domesticTransport["relationshipManager"] = customerValue2
      }
      if (x === "branchOperationManager") {
        errors.branchOperationManager =
          customerValue2.length > 0 ? "" : "Please select a valid option."
        return Branch.domesticTransport.branchOperationManager = customerValue2
      }
      setErrors(errors)
    }
    else if (r === "crossBorder") {
      const customerValue2 = trimStr(value)
      if (x === "customerService") {
        errors.DT_customerService =
          customerValue2.length > 0 ? "" : "Please select a valid option."
        return Branch.crossBorder.customerService = customerValue2

      }
      if (x === "relationshipManager") {
        errors.DT_relationshipManager =
          customerValue2.length > 0 ? "" : "Please select a valid option."
        return Branch.crossBorder["relationshipManager"] = customerValue2
      }
      if(x === "CB_agragaBranch") {
          errors.CB_agragaBranch =
          customerValue2.length > 0 ? "" : "Please select a valid option."
          AguserApi.getList(setLists)
        return Branch.crossBorder.agragaBranch = customerValue2
      }
      setErrors(errors)
    }
    else if(r === "CB_salesVertical") {
      if (isEditMode() || isNewMode()) {
            Branch.crossBorder.customerService = ""
            Branch.crossBorder.relationshipManager = ""
            Branch.crossBorder.agragaBranch = ""
          }
          const customerValue2 = trimStr(value)
      errors.CB_salesVertical =
        customerValue2.length > 0 ? "" : "Please select a valid option."
      setErrors(errors)
      // AguserApi.getList(setLists)
      return Branch.crossBorder.salesVertical = customerValue2
    }
    // else if (r === "CB_agragaBranch") {
    //   if (isEditMode() || isNewMode()) {
    //     Branch.crossBorder.customerService = ""
    //     Branch.crossBorder.relationshipManager = ""
    //   }
    //   const customerValue2 = trimStr(value)
    //   errors.CB_agragaBranch =
    //     customerValue2.length > 0 ? "" : "Please select a valid option."
    //   setErrors(errors)
    //   AguserApi.getList(setLists)
    //   return Branch.crossBorder.agragaBranch = customerValue2
    // }
    else if (r === "DT_agragaBranch") {
      if (isEditMode() || isNewMode()) {
        Branch.domesticTransport.customerService = ""
        Branch.domesticTransport.relationshipManager = ""
        Branch.domesticTransport.branchOperationManager = ""
      }
      const customerValue2 = trimStr(value)
      errors.DT_agragaBranch =
        customerValue2.length > 0 ? "" : "Please select a valid option."
      setErrors(errors)
      AguserApi.getList(setLists)
      return Branch.domesticTransport.agragaBranch = customerValue2
    }
    else if (r === "agragaBranch") {
      if (isEditMode() || isNewMode()) {
        Branch.relationShipManagerSupply = ""
      }
      const customerValue2 = trimStr(value)
      errors.agragaBranch =
        customerValue2.length > 0 ? "" : "Please select a valid option."
      setErrors(errors)
      AguserApi.getList(setLists)
      return Branch.agragaBranch = customerValue2
    }

    // else if (r === "branchOperationManager") {
    //   const name = e.target.value;
    //   errors[r] = name.length > 0 ? "" : "This field is required.";
    //   setErrors(errors);
    //   return (Branch[r] = trimStr(name));
    // }
    if (r === "uidName" || r === "uidRefNo") {
      if (r === "uidName" || r === "uidRefNo") {
        let re = new RegExp("^([A-Za-z0-9_ ]{0,20})$")
        if (!re.test(e.target.value)) {
          return null
        }
      }
      Branch[r] = trimStr(e.target.value)
    } else if (r === "advanceReceivableForDT") {
      const value = e.target.value

      let re = new RegExp("^([0-9]{0,3})$")
      if (!re.test(value)) {
        return null
      }
      const newValueReceivable = parseInt(value, 10)
      if (newValueReceivable < 1 || newValueReceivable > 100) {
        setAdvanceError("Value must be between 1 and 100")
        if (value < 1 || value > 100) {
          return 100
        }
        //  return null
      } else {
        setAdvanceError(null)
        Branch[r] = newValueReceivable.toString()
      }
    } else if (r === "advancePayable") {
      const value = e.target.value
      let re = new RegExp("^([0-9]{0,3})$")
      if (!re.test(value)) {
        return null
      }

      const newValue = parseInt(value, 10)
      if (newValue < 1 || newValue > 100) {
        setAdvancePayableError("Value must be between 1 and 100")
        return null
        // if( value < 1 || value > 100){
        //   return 100
        // }
      } else {
        setAdvancePayableError(null)
        Branch[r] = newValue.toString()
      }
    }
    Branch[r] = trimStr(e.target.value)
  }

  const BranchCityClear = () => {
    const branches = Branch.branchAddresses
    Branch.branchAddresses.map((x, i) => {
      x.city = ""
    })
  }

  const StatesforBranch = (code) => {
    const states = State.getStatesOfCountry(code)

    setstates(states)
  }

  const selectState = (e) => {
    clearErrorMessages("state")
    const statee = State.getStateByCodeAndCountry(
      e.target.value,
      Entity?.entityCountryCode
    )
    // console.log("statee--", statee);
    Branch.state = statee?.name
    errors.state = statee?.name.length > 0 ? "" : "This field is required."
    setErrors(errors)
    //console.log(" statee.isoCode", statee.isoCode);
    setstateCode(statee?.isoCode)
    const cities = City?.getCitiesOfState(
      Entity?.entityCountryCode,
      statee?.isoCode
    )

    setcities(cities)
  }

  const EntityCountrycode = () => {
    const EntityCountry = Country.getAllCountries().filter(
      (r) => r.name === Entity?.entityCountry
    )
    const CountryCode = EntityCountry[0].isoCode

    return CountryCode
  }

  const shouldSelectOption = (optionType, selectedValue) => {
    return (
      (isEditMode() || isViewMode() || isNewMode()) &&
      optionType === selectedValue
    )
  }

  useEffect(() => {
    AguserApi.getList(setLists)
    if (domain === "agraga.com")
      AguserApi.getUsers4role(Roles.CLADMIN, setCadminList)
    else {
      let list = [
        {
          email: loginStore.email,
          firstName: loginStore.userRec.aguserRec.firstName,
          lastName: loginStore.userRec.aguserRec.lastName,
        },
      ]
      setCadminList(list)
    }
    if(Branch.timeline === null || !Branch.timeline) {
      Branch.timeline = []
    }
  }, [])

  const entity = EntityV2Store.EntityRec

  const renderLink = (fileName) => {
    if (fileName && fileName.length > 3) {
      return (
        <a
          href={process.env.REACT_APP_API_SERVER + "/clientfiles/" + fileName}
          target="_blank">
          {fileName}
        </a>
      )
    } else return <></>
  }

  const renderOptions = (categoryValues, selectedValue) => {
    return categoryValues.map((type) => (
      <option key={type} selected={shouldSelectOption(type, selectedValue)}>
        {type.charAt(0).toUpperCase() + type.slice(1)}
      </option>
    ))
  }

  let Remarks
  const handleMessageChange = (event) => {
    if (event.target.value.length > 0) {
      // if(setappbtnstatus(true))

      document.getElementById("approvebtnid").style.opacity = "1"
      setappbtnstatus(false)
    } else if (event.target.value.length === 0) {
      document.getElementById("approvebtnid").style.opacity = "0.4"
      setappbtnstatus(true)
    }

    setMessage(event.target.value)
    //  setappbtnstatus(false);
  }

  const UpdatetheBranch = async() => {
   await BranchApi.updateBranch(Branch?._id, Branch?.gst, setloading,async(d)=>{
    if(AgmodalStore.modalPage === "branchAPP"){
      AgmodalStore.pageVisible = false;
      AgmodalStore.apiCall = true;
    }
    if (props.ch) {
      return routerStore.creditApplicationView ="list"
    }
   })
  }

  const CreatetehBranch = () => {
    // if (branchStore.BranchRec.documents[1].fileLink.length === 0) {
    //   configStore.setModalMessage("Please Upload GST Copy");
    //   return;
    // }
    BranchApi.createBranch()
  }
  const branchAddressline = [
    {
      address: "",
      city: "",
      pincode: "",
      zipcode: "",
    },
  ]
  const addNewBranchAddress = () => {
    //setGstData()
    setCity(true)
    const array = [...Branch.branchAddresses]

    array.push(branchAddressline[0])

    // setbranchAddressArray(array);
    Branch.branchAddresses = array

  }

  const removeBranchAddressLine = (i) => {
    const array = [...Branch.branchAddresses]
    if (array.length > 1) {
      array.splice(i, 1)
    }
    // setbranchAddressArray(array);
    Branch.branchAddresses = array
  }

  const setBranchAddress = async (e, type, index) => {

    if (type === "pincode" && e.length > 6) {
      return null
    }
    if (type === "zipcode" && e.length > 10) {
      return null
    }
    if (Branch.branchAddresses.length !== 0) {
      const array = [...Branch.branchAddresses]
      array[index][type] = e
      // setbranchAddressArray(array);
      Branch.branchAddresses = array
    }
  }
  const branchAddressFilled = async () => {
    let isValid = true
    if (Branch.state === "") {
      return configStore.setModalMessage(`Please Select the state`)
    }
    const data = await Branch.branchAddresses?.map((r, i) => {
      if (r.address === "") {
        isValid = false
        return configStore.setModalMessage(`Please Enter the address`)
        // return false;
      } else if (r.city === "") {
        isValid = false
        return configStore.setModalMessage(`Please Select the city`)
        // return false;
      } else if (EntityUtils.isIndia(Entity) && r.pincode === "") {
        isValid = false
        return configStore.setModalMessage(`Please Select the pincode`)
        // return false;
      } else if (!EntityUtils.isIndia(Entity) && r.zipcode === "") {
        isValid = false
        return configStore.setModalMessage(`Please Enter the zipcode`)
        // return false;
      }

      if (i === Branch.branchAddresses.length - 1 && isValid) {
        return true
      }
    })
    return data
  }
  const onFormSubmit = async () => {
    setloading(true)
    const fieldsCheck = await doMandatoryCheck()
    let isFormValid = fieldsCheck.mandatoryCheck
    Branch.mandatoryCheck = isFormValid
    Branch.country = Entity.entityCountry
    Branch.branchcountryCode = Entity.entityCountryCode
    const checkAddresses = await branchAddressFilled()
    if (!fieldsCheck.isError && !gstAvailable.status && checkAddresses?.includes(true)) {
      if (isEditMode() || isNewMode()) {
        if (!fieldsCheck.isPendingErrors) {
          if (isNewMode()) {
            Branch.status = isFormValid
              ? BRANCH_STATUS.PENDING
              : Branch.status === BRANCH_STATUS.REJECTED
                ? BRANCH_STATUS.PENDING
                : Branch.status
          } else if (isEditMode()) {
            if (
              Branch.status === BRANCH_STATUS.REJECTED 
            ) {
              if (Branch.gst !== dbBranch.gst) {
                Branch.status = BRANCH_STATUS.REJECTED
              } else {
                if(Branch.status === BRANCH_STATUS.REJECTED)
                Branch.Remarks = ""
                      Branch.status = BRANCH_STATUS.PENDING

              }
            } 
          }
        const check = await Branch.timeline.find((d)=>d.event === "Created")
          if (isFormValid && Branch.timeline.length === 0) {
            Branch.timeline.push({
              date: Date.now(),
              event: "Created",
              user: loginStore.email,
              role: RoleUtils.getUserRole()
            })
          }
          if (isFormValid && check && Branch.status !=="VERIFIED" && Branch.status !=="REJECTED") {
            Branch.timeline.push({
              date: Date.now(),
              event: "Resubmited",
              user: loginStore.email,
              role: RoleUtils.getUserRole()
            })
          }
          if (isFormValid && check && Branch.status ==="PENDING" && Branch.timeline[Branch.timeline.length-1]?.event !=="Resubmited") {
            Branch.timeline.push({
              date: Date.now(),
              event: "Resubmited",
              user: loginStore.email,
              role: RoleUtils.getUserRole()
            })
          }
          Branch.isErpEmailForBranch =  true
          if (isNewMode()) {
            BranchApi.createBranch(setloading)
          } else {         
            UpdatetheBranch()
          }
        } else {
          configStore.setModalMessage("Recheck the fields")
          setloading(false)
        }
      } else {
        if (isNewMode()) {
          Branch.status = isFormValid
            ? BRANCH_STATUS.PENDING
            : Branch.status === BRANCH_STATUS.REJECTED
              ? BRANCH_STATUS.PENDING
              : Branch.status
        } else if (isEditMode()) {
          if (
            Branch.status === BRANCH_STATUS.REJECTED &&
            (Branch.address !== dbBranch.address ||
              Branch.pincode !== dbBranch.pincode ||
              Branch.state !== dbBranch.state ||
              Branch.city !== dbBranch.city ||
              // Branch.status !== dbBranch.status ||
              Branch.advanceReceivableForDT !==
              dbBranch.advanceReceivableForDT ||
              Branch.gst !== dbBranch.gst ||
              Branch.relationshipManager !== dbBranch.relationshipManager ||
              Branch.relationShipManagerSupply !==
              dbBranch.relationShipManagerSupply ||
              Branch.customerService !== dbBranch.customerService ||
              Branch.branchOperationManager !== dbBranch.branchOperationManager)
          ) {
            if (Branch.gst !== dbBranch.gst) {
              Branch.status = BRANCH_STATUS.REJECTED
            } else {
              Branch.status = BRANCH_STATUS.PENDING
            }
          }
        }
        BranchApi.createBranch(setloading)
      }
    } else {
      // configStore.setModalMessage("Enter all required fields");
      setloading(false)
    }
  }

  const renderBranchesCBOptions = (categoryValues, selectedValue, salesVertical) => {
    let branchesList = new Set()
    if(Branch?.crossBorder?.salesVertical !== "")
      branchesList.add(DEFAULT_AGRAGA_BRANCH)

    sortedbranchesForCB.forEach(branch => {
      const user = agUsersList.find(user => user.branch === branch && user?.vertical === VERTICALS.CROSS_BORDER && user?.department === ROLES.CB.RELATIONSHIP_MANAGER)
      if (user?.RMSalesVertical?.some(vertical => Branch?.crossBorder?.salesVertical === (vertical))) {
        branchesList.add(user.branch)
      }
    })
  if (branchesList.length === 0) {
    return (
      <option disabled value="">
        No branches available
      </option>
    )
  }
    return Array.from(branchesList).map((branch) => (
      <option
        key={branch}
        selected={shouldSelectOption(branch, selectedValue)}
        value={branch}>
{branch}
      </option>
    ))
  };

  const doMandatoryCheck = async () => {
    function isValid(value) {
      return (
        (!Array.isArray(value) &&
          value &&
          value.trim().length > 0 &&
          value !== "default") ||
        (Array.isArray(value) &&
          value.some((item) => item.trim() !== "default"))
      )
    }

    function getSelectedDropDownValue(id) {
      let dropdownSelect = document.getElementById(`${id}`)
      return dropdownSelect ? dropdownSelect.value : null
    }

    function getSelectedInputValue(id) {
      return document.getElementById(`${id}`)?.value
    }

    function getSelectedDocumentValue(id) {
      if (document.getElementById(`${id}`) !== null) {
        let obj = Branch.documents.filter((r) => r.name === `${id}`)
        if (obj[0]?.fileLink.length > 0) {
          return obj[0].fileLink
        }
      }
    }

    function getSelectedValuesFromMultipleSelectCheckbox(id) {
      const checkboxes = document.querySelectorAll(
        `input[type="checkbox"][id="${id}"]:checked`
      );
      const selectedValues = Array.from(checkboxes).map(
        (checkbox) => checkbox.value
      );

      return selectedValues.length > 0 ? selectedValues : null;
    }

    let fieldsWithValues = {
      address: getSelectedInputValue("address"),
      country: getSelectedDropDownValue("Country"),

      scopeOfBusiness:
        getSelectedValuesFromMultipleSelectCheckbox("scopeOfBusiness"),
      // city: getSelectedDropDownValue("city"),
      // state: getSelectedDropDownValue("state"),
      // pincode: getSelectedInputValue("pincode"),
      // zipcode: getSelectedInputValue("zipcode"),
      branchOperationManager: getSelectedDropDownValue(
        "branchOperationManager"
      ),
      gst: getSelectedInputValue("gst"),
      relationshipManager: getSelectedDropDownValue("relationshipManager"),
      customerService: getSelectedDropDownValue("customerService"),
      relationShipManagerSupply: getSelectedDropDownValue(
        "relationShipManagerSupply"
      ),
      gstCertificate: getSelectedDocumentValue("gstCert"),
      advanceReceivableForDT: getSelectedInputValue("advanceReceivableForDT"),
      advancePayable: getSelectedInputValue("advancePayable"),
      uniqueIdentifierName: getSelectedInputValue("uniqueIdentifierName"),
      uniqueIdentifierRefNO: getSelectedInputValue("uniqueIdentifierRefNo"),
      CB_agragaBranch: getSelectedDropDownValue("CB_agragaBranch"),
      DT_agragaBranch: getSelectedDropDownValue("DT_agragaBranch"),
      DT_customerService: getSelectedDropDownValue("DT_customerService"),
      DT_relationshipManager: getSelectedDropDownValue("DT_relationshipManager"),

    }

    let fieldsToValidate = [
      // { title: "Address", field: "address", required: true },
      // { title: "Pincode", field: "pincode", required: true },
      // { title: "State", field: "state", required: true, dropdown: true },
      // { title: "City", field: "city", required: true, dropdown: true },
      //{ title: "Branch Operation Manager", field: "branchOperationManager", required: true, dropdown: true },
      // { title: "Gst", field: "gst", required: true  },
      // { title: "Zipcode", field: "zipcode", required: true  },
    ]

    let fieldsToSubmit = [
      // { title: "Address", field: "address", required: true },
      // { title: "Pincode", field: "pincode", required: true },
      // { title: "State", field: "state", required: true, dropdown: true },
      // { title: "City", field: "city", required: true, dropdown: true },
      // { title: "Branch Operation Manager", field: "branchOperationManager", required: true, dropdown: true },
      // { title: "Gst", field: "gst", required: true  },
      // { title: "Zipcode", field: "zipcode", required: true  },
    ]
    let scope_of_business = [
      {
        title: "Scope Of Business",
        field: "scopeOfBusiness",
        required: true,
        multiSelect: true,
      },

    ]
    if (EntityUtils.isCustomer(Entity)) {
      fieldsToSubmit.push(...scope_of_business)
      fieldsToValidate.push(...scope_of_business)
    }
    if (EntityUtils.isCustomer(Entity) && Branch.scopeOfBusiness.includes("Cross Border")) {
      let CB_agragaBranch = [
        {
          title: "CB Agraga Branch",
          field: "CB_agragaBranch",
          required: true,
          dropdown: true,
        },
        {
          title: "CB relationship Manager",
          field: "relationshipManager",
          required: true,
          dropdown: true,
        },
        {
          title: "CB Customer Service",
          field: "customerService",
          required: true,
          dropdown: true,
        },

      ]
      fieldsToSubmit.push(...CB_agragaBranch)
      fieldsToValidate.push(...CB_agragaBranch)
    }
    if (EntityUtils.isCustomer(Entity) && Branch.scopeOfBusiness.includes("Domestic Transport")) {
      let DT_agragaBranch = [
        {
          title: "DT Agraga Branch",
          field: "DT_agragaBranch",
          required: true,
          dropdown: true,
        },
        {
          title: "DT relationship Manager",
          field: "DT_relationshipManager",
          required: true,
          dropdown: true,
        },
        {
          title: "DT Customer Service",
          field: "DT_customerService",
          required: true,
          dropdown: true,
        },
        {
          title: "branchOperationManager",
          field: "branchOperationManager",
          required: true,
          dropdown: true,
        },
      ]
      fieldsToSubmit.push(...DT_agragaBranch)
      fieldsToValidate.push(...DT_agragaBranch)
    }
    if (EntityUtils.isIndia(Entity)) {
      fieldsToValidate.push(
        // { title: "Pincode", field: "pincode", required: true },
        { title: "Gst", field: "gst", required: true }
        //{ title: "Branch Operation Manager", field: "branchOperationManager", required: true, dropdown: true },
      )

      fieldsToSubmit.push(
        // { title: "Pincode", field: "pincode", required: true },
        { title: "Gst", field: "gst", required: true }
        //{ title: "Branch Operation Manager", field: "branchOperationManager", required: true, dropdown: true },
      )
    }

    // if (!EntityUtils.isIndia(Entity)) {
    //   fieldsToValidate.push({
    //     title: "Zipcode",
    //     field: "zipcode",
    //     required: true,
    //   });

    //   fieldsToSubmit.push({
    //     title: "Zipcode",
    //     field: "zipcode",
    //     required: true,
    //   });
    // }
    let errors = {}
    let fieldsCheck = {}
    let pendingErrors = {}

    fieldsToSubmit.forEach(({ title, field, required, dropdown }) => {
      let value = fieldsWithValues[field]
      if (required && !isValid(value)) {
        errors[field] = `${title} is required`
        // errors["address"] = "Address is required.";
        // errors["pincode"] = "Pincode is required.";
        // errors["gst"] = "Gst is required.";
        fieldsCheck.isError = true
      }
      if (dropdown && value === "default") {
        errors[field] = `Please select a valid ${title}.`
        //  errors["state"] = "Please select a valid  State.";
        //  errors["city"] = "Please select a valid City.";
        fieldsCheck.isError = true
      }
    })

    setErrors(errors)

    console.log("valdidte +", fieldsToValidate)
    console.log("fieldwithvalue +", fieldsWithValues)
    console.log("fieldsToSubmit +", fieldsToSubmit)
    const regexGSTPattern =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/

    let hasErrors = false

    await fieldsToValidate.forEach(
      async ({ field, required, dropdown, multiSelect, document }) => {
        let value = fieldsWithValues[field]

        if (field === "gst" && value?.length > 0) {
          if (!regexGSTPattern.test(value.trim())) {
            gstAvailable.status = false
            pendingErrors[field] =
              "Invalid GST format. Eg: 22AAAAA0000A1Z5 or 29ABCDE1234F1ZK"
            fieldsCheck.isPendingErrors = true
            hasErrors = required
          } else if(EntityUtils.isIndia(Entity) && value && Entity.panNum && value?.length === 15 && Entity?.panNum?.length === 10) {
              const extractedPan = EntityUtils.extractPanFromGST(value)
              if (extractedPan !== Entity?.panNum) {
                pendingErrors[field] =
              "PAN & GST Mismatch, please rectify it"
                fieldsCheck.isPendingErrors = true
                setloading(false)
                return
            }
          }
          if (gstAvailable?.status) {
            clearErrorMessages(field)
            pendingErrors[
              field
            ] = `GST Exists - ${gstAvailable?.data?.entityId}`
            fieldsCheck.isPendingErrors = true
            hasErrors = required
          }

          if (
            regexGSTPattern.test(value.trim()) &&
            !gstAvailable?.status &&
            Branch?.documents[1]?.fileLink?.length < 1
          ) {
            // configStore.setModalMessage("Upload GST CRT Copy");
            // return;
            pendingErrors[field] = "Upload GST document"
            fieldsCheck.isPendingErrors = true
            hasErrors = required
          }
        }

        if (required) {
          const isValueValid =
            (multiSelect && Array.isArray(value) && value.length > 0) ||
            (!multiSelect && isValid(value))

          const isDropdownValid =
            !dropdown ||
            (value !== "default" &&
              !(multiSelect && (!Array.isArray(value) || value.length === 0)))

          if (!isValueValid || !isDropdownValid) {
            hasErrors = true
          }
        }
      }
    )

    let mandatoryCheck = !hasErrors
    setPendingErrors(pendingErrors)
    console.log("mandatory check", mandatoryCheck)
    fieldsCheck.mandatoryCheck = mandatoryCheck
    return fieldsCheck
  }

  const updateStatusForBranch = () => {
    // console.log("approvalStatus", approvalStatus);
    setloading(true)
    if (approvalStatus === "approve") {
      Branch.status = BRANCH_STATUS.VERIFIED
    } else {
      Branch.status = BRANCH_STATUS.REJECTED
      Branch.remarks = message
    }
    BranchApi.updateBranch(Branch?._id, Branch.gst, setloading)
  }
  const handleVerifyRadioChange = (event) => {
    setApprovalStatus(event.target.value)
    setappbtnstatus(true)
    document.getElementById("approvebtnid").style.opacity = "0.4"
    setMessage("")
  }
  const cancelpopup = () => {
    setVerifyPopup(false)
    setApprovalStatus(null)
    setMessage("")
    document.getElementById("editBranch").style.filter = "none"
    document.getElementById("editBranch").style.pointerEvents = "auto"
  }
  const openVerifyPopup = () => {
    setVerifyPopup(true)
    document.getElementById("editBranch").style.filter = "blur(15px)"
    document.getElementById("editBranch").style.pointerEvents = "none"
  }

  const approvebtnnfn = () => {
    if (document.getElementById("approvebtnid").disabled == true) {
      document.getElementById("approvebtnid").style.opacity = "1"
      setappbtnstatus(false)
      return (document.getElementById("approvebtnid").disabled = false)
    } else if (document.getElementById("approvebtnid").disabled == false) {
      document.getElementById("approvebtnid").style.opacity = "0.4"
      setappbtnstatus(true)
      return (document.getElementById("approvebtnid").disabled = true)
    }
  }

  const selectcity = (e) => {
    Branch.state = state
    Branch.city = e.target.value
    let cityy = cities.filter((r) => r.name == e.target.value)
    setsetcitysel(cityy)
    // Branch.country = "IN";
  }

  const backFn = () => {
    routerStore.branchCurrentView = "list"
    branchStore.BranchRec.set(new BranchType())
  }

  const delDocs = (r) => {
    Branch.documents[r].fileLink = ""
    Branch.documents[r].fileKey = ""
  }

  useEffect(() => {
    const fieldsToCheck = [
      { field: "state" },
      { field: "city" },
      { field: "gst" },
      { field: "address" },
      { field: "pincode" },
      { field: "relationshipManager" },
      { field: "customerService" },
      { field: "branchOperationManager" },
      { field: "relationShipManagerSupply" },
    ]

    const checkField = (fieldData) => {
      const { field } = fieldData
      if (field !== 'relationshipManager' || field !== 'customerService') {
        return isEditMode() && Branch?.crossBorder[field] === ""
      } else {
        return isEditMode() && Branch?.[field] === ""
      }

    }

    fieldsToCheck.forEach((fieldData) => {
      pendingErrors[fieldData.field] = checkField(fieldData)
    })
  }, [])
  useEffect(() => {
    Branch.scopeOfBusiness = Entity.scopeOfBusiness
    Branch.crossBorder = Entity.customer.crossBorder
    Branch.domesticTransport = Entity.customer.domesticTransport
    if (!props.ch) {
      Branch.agragaBranch = Entity.agragaBranch
      Branch.relationShipManagerSupply = Entity.vendor.relationShipManagerSupply
    }
   
  }, [])

  const addscopeofbusiness = (e, r) => {
    console.log(e, r)
    if (r === "Domestic Transport") {
      setDT_Checked(!DT_Checked)
    }
    if (r === "Cross Border") {
      setCB_Checked(!CB_Checked)
    }

    if (e.target.checked === true) {
      Branch.scopeOfBusiness.push(r)
      if (isEditMode()) {
        pendingErrors.scopeOfBusiness = false
      }
      setPendingErrors(pendingErrors)
    } else if (
      e.target.checked === false &&
      (!isVerifiedStatus() ||
        (Branch.status === "VERIFIED" &&
          Branch.scopeOfBusiness.length > 1))
    ) {
      Branch.scopeOfBusiness = Branch.scopeOfBusiness.filter(
        (e) => e !== r
      )
    }

    if (Branch.scopeOfBusiness.length === 0) {
      errors.scopeOfBusiness = "Please select a valid option."
    } else {
      errors.scopeOfBusiness = ""
    }
    setErrors(errors)
  }
  const checkScopeBs = (r) => {
    return Branch.scopeOfBusiness.includes(r)
  }
  const [branchesForTypes, setbranchesForTypes] = useState([])
  const [branchesForTypesCB, setbranchesForTypesCB] = useState([])
  const [branchesForTypesDT, setbranchesForTypesDT] = useState([])
  const [salesListForCB, setSalesListForCB] = useState([])
  const [salesListForDT, setSalesListForDT] = useState([])
  const [formObject, setformObject] = useState({})
  const [csListForCB, setCsListForCB] = useState([])
  const [csListForDT, setCsListForDT] = useState([])
  const [gstVerify, setGstVerify] = useState();
  const [gstData, setGstData] = useState();
  const [gstData1, setGstData1] = useState(false);
  const [city,setCity] =  useState(false)

  const [{ oldRM } = {}] = Branch.crossBorder.rmHistory || [];
  const [{ oldCS } = {}] = Branch.crossBorder.csHistory || [];

  const getAllBranchesList = async () => {
    let v = {
      vertical: "all",
      division: "all",
    }
    let cbFilter = {
      vertical: "Cross-Border",
      division: "all",
    }
    let dtFilter = {
      vertical: "Domestic Transport",
      division: "all",
    }
    AguserApi.getbranchesForTypes(v, setbranchesForTypes)
    AguserApi.getbranchesForTypes(cbFilter, setbranchesForTypesCB)
    AguserApi.getbranchesForTypes(dtFilter, setbranchesForTypesDT)
  }
  let sortedbranches = branchesForTypes.sort()
  let sortedbranchesForCB = branchesForTypesCB.sort()
  let sortedbranchesForDT = branchesForTypesDT.sort()
  useEffect(() => {
    getAllBranchesList()
    // setformObject(EntityV2Store.EntityRec)
  }, [])
  ///assets/Back.png
    if(gstData1==true)
  {
    console.log("D",gstData)
    {State.getStatesOfCountry(Entity.entityCountryCode).map(
      (r, i) => {
       let f = r.name.includes(gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.stateName)
       if(f==true){
         Branch.state=r.name + "/" + r.isoCode

       }
      }
    )}
  
    {City?.getCitiesOfState(
      Entity?.entityCountryCode,
      Branch.state.split("/")[1]
    ).map((r, i) => {

    let f = gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.districtName.toLowerCase().includes(r.name.toLowerCase())
    console.log("C",r.name.toLowerCase(),f,gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.districtName.toLowerCase())
    if(f==true){
      setCity(true)
      console.log("DC",r.name)
      Branch.branchAddresses[0].city= r.name

    } else{
      setCity(false)

      Branch.branchAddresses[0].city = gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.districtName
    }
    })} 
    console.log("ddf",Branch.state,Branch.city)
    Branch.branchAddresses[0].address = gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.floorNumber +" "+gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.buildingNumber +" " +
    gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.buildingName + " " +
    gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.streetName
    ///Entity.branch[0].branchAddresses[0].city = gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.location
    //Entity.branch[0].state= gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.stateName
    Branch.branchAddresses[0].pincode =  gstData?.principalPlaceOfBusinessFields?.principalPlaceOfBusinessAddress?.pincode
    if( gstData?.tradeName=="" || gstData.tradeName=="-"){
      console.log("Test..") } else{
      Entity.entityName =  gstData?.tradeName
    }
   
    Entity.branch[0].natureOfBusinessActivity= gstData?.natureOfBusinessActivity.join(", ")

    dtactionStore.modalVisible1=false
    
   
    //dtactionStore.modalVisible1=false
    

    console.log(  Branch.state, Branch.branchAddresses[0].city)
    setGstData1(false)
  } 
  return (
    <>
          <GSTVerify data={gstData} gstDataClick={()=>{setGstData1(!gstData1)}} gstDataCancel={()=>{
        dtactionStore.modalVisible1=false
        //Entity.entityName = ""
        Branch.state=""
        Branch.branchAddresses[0].city=""
        Branch.branchAddresses[0].address = ""
        Branch.branchAddresses[0].state= ""
        Branch.branchAddresses[0].pincode= ""
        pendingErrors.gstVerify=""
        setGstVerify()
        setGstData()}}></GSTVerify>

      <div className="container is-fullheight" id="editBranch">
        <h1 className="title">
          {title}

          <span style={{ position: "relative", left: "10em" }}>
            {(isEditMode() && Branch?.status === BRANCH_STATUS.PENDING && ((RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.PENDING_CUSENTITYV2_VR) && Entity.entityType == 'Customer') || (RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.PENDING_VENENTITYV2_VR) && Entity.entityType == 'Vendor') || (RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.PENDING_VENENTITYV2RT_VR) && Entity.entityType == 'Vendor' && Entity.vendor.vendorType == 'Road Transport'))) ? (
              <a>
                <HiMiniDocumentCheck
                  onClick={openVerifyPopup}></HiMiniDocumentCheck>
              </a>
            ) : null}{" "}
            <span></span>
          </span>
        </h1>

        <div style={{ padding: "0rem 2rem" }}>
          <Columns>
            <Col4>
              <p className="label is-small" style={{ marginBottom: "0px" }}>
                State *
              </p>
              <select
                // type="text"
                className="select is-small"
                style={{ width: "100%", border: "1px solid #d1d1e0" }}
                // ref={ref}
                value={Branch?.state}
                onChange={(e) => handleChange(e, "state")}
                // onChange={(e) => handleChange(e, "state")}
                {...(isViewMode()
                  ? { disabled: true }
                  : {
                    ...(isEditMode() &&
                      (isPendingStatus() || isVerifiedStatus())
                      ? { disabled: true }
                      : {}),
                  })}
                id="state">
                <option value="">Select state</option>
                {State.getStatesOfCountry(Entity.entityCountryCode).map(
                  (x, i) => {
                    //console.log("statesssall -",states)
                    return (
                      <option value={x.name + "/" + x.isoCode}>{x.name}</option>
                    )
                  }
                )}
                {/* {State.getStatesOfCountry(CountryCode).map((r, i) => {
                  return <option value={r.isoCode}>{r.name}</option>;
                })} */}
              </select>
              {errors.state && (
                <p className="help is-input-danger-message">{errors.state}</p>
              )}
            </Col4>

            {EntityUtils.isIndia(Entity) ? (
              <>
                <Col4>
                  <p className=" has-text-danger">{alertcomment}</p>
                  <p className="label is-small" style={{ marginBottom: "0px" }}>
                    GST Number*
                  </p>
                  <div className="is-flex">
                    <input
                      maxLength={15}
                      type="text"
                      className="input is-small "
                      value={Branch?.gst}
                      ref={ref1}
                      id="gst"
                      pattern="[A-Z0-9]{15}"
                      onChange={(e) => handleChange(e, "gst")}
                      {...(isViewMode()
                        ? { disabled: true }
                        : {
                          ...(isEditMode() &&
                            (isPendingStatus() || isVerifiedStatus())
                            ? { disabled: true }
                            : {}),
                        })}
                    />
                    <span class="icon mt-1 ml-3 is-size-4">
                      {Branch?.documents[1]?.fileLink?.length > 0 ||
                        (!isNewMode() &&
                          Branch?.documents[1]?.fileLink?.length > 0) ? (
                        <>
                          <span className="ml-2 mr-1">
                            <a
                              href={
                                process.env.REACT_APP_FILE_SERVER +
                                "/api/v1/filestore/getfile/" +
                                Branch?.documents[1]?.pkey +
                                "/" +
                                Branch?.documents[1]?.fileLink
                              }
                              target="_blank"
                              rel="noreferrer"
                              alt="">
                              <BsFillEyeFill />
                            </a>
                          </span>
                          {isNewMode() ||
                            (isEditMode() &&
                              !isPendingStatus() &&
                              !isVerifiedStatus()) ? (
                            <>
                              <span>
                                <MdDelete
                                  onClick={() => (
                                    delDocs(1),
                                    (hiddenFileInput2.current.value = "")
                                  )}
                                />
                              </span>
                            </>
                          ) : null}
                        </>
                      ) : null}
                      {isNewMode() || (isEditMode() && !isVerifiedStatus()) ? (
                        <i
                          class="fa-solid fa-file-arrow-up"
                          style={{
                            ...(Branch?.documents[1]?.fileLink?.length > 0
                              ? { display: "none" }
                              : null),
                          }}
                          onClick={() => hiddenFileInput2.current.click()}
                          id="gstCert">
                          <input
                            type="file"
                            onChange={(e1) => {
                              change(e1.target.files, "gstCert", Branch.gst)
                              clearErrorMessages("gst")
                            }}
                            name="image"
                            accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                            multiple=""
                            ref={hiddenFileInput2}
                            data-original-title="upload photos"
                            style={{ display: "none" }}
                            {...(isViewMode()
                              ? { disabled: true }
                              : {
                                ...(isEditMode() &&
                                  (isPendingStatus() || isVerifiedStatus())
                                  ? { disabled: true }
                                  : {}),
                              })}
                          />
                        </i>
                      ) : null}
                    </span>
                  </div>
                  {errors.gst && <p className="help is-danger">{errors.gst}</p>}
                  {pendingErrors.gst && (
                    <p className="help is-danger">{pendingErrors.gst}</p>
                  )}
                  {gstData?.gstnStatus?( <p className="help is-input-success-message">
                              {pendingErrors.gstVerify }
                            </p> ):""}
                            {!gstData?.gstnStatus&& pendingErrors.gstVerify && (
                            <p className="help is-input-danger-message">
                              {pendingErrors.gstVerify}
                            </p>
                          )}
                </Col4>
              </>
            ) : (
              <>
                <Col4>
                  <p className="label is-small" style={{ marginBottom: "0px" }}>
                    Unique Identifier Document Name
                  </p>
                  <input
                    type="text"
                    className="input is-small "
                    value={Branch?.uidName}
                    ref={ref}
                    maxLength={20}
                    pattern="[a-zA-Z0-9]{0,20}"
                    onChange={(e) => handleChange(e, "uidName")}
                    {...(isViewMode()
                      ? { disabled: true }
                      : {
                        ...(isEditMode() &&
                          (isPendingStatus())
                          ? { disabled: true }
                          : {}),
                      })}
                    id="uniqueIdentifierName"
                  />
                </Col4>
                <Col4>
                  <p className="label is-small" style={{ marginBottom: "0px" }}>
                    Unique Identifier Document Reference Number
                  </p>
                  <div className="is-flex">
                    <input
                      type="text"
                      className="input is-small "
                      value={Branch?.uidRefNo}
                      ref={ref}
                      maxLength={20}
                      pattern="[a-zA-Z0-9]{0,20}"
                      onChange={(e) => handleChange(e, "uidRefNo")}
                      {...(isViewMode()
                        ? { disabled: true }
                        : {
                          ...(isEditMode() &&
                            (isPendingStatus())
                            ? { disabled: true }
                            : {}),
                        })}
                      id="uniqueIdentifierRefNo"
                    />
                    <span class="icon mt-1 ml-3 is-size-4 is-clickable">
                      {Branch?.documents[0]?.fileLink?.length > 0 ? (
                        <>
                          <span className="ml-2 mr-1">
                            <a
                              href={
                                process.env.REACT_APP_FILE_SERVER +
                                "/api/v1/filestore/getfile/" +
                                Branch?.documents[0]?.pkey +
                                "/" +
                                Branch?.documents[0]?.fileLink
                              }
                              target="_blank"
                              rel="noreferrer"
                              alt="">
                              <BsFillEyeFill />
                            </a>
                          </span>
                          {isNewMode() ||
                            (isEditMode() &&
                              !isPendingStatus() &&
                              !isVerifiedStatus()) ? (
                            <>
                              <span>
                                <MdDelete
                                  onClick={() => (
                                    delDocs(0),
                                    (hiddenFileInput1.current.value = "")
                                  )}
                                />
                              </span>
                            </>
                          ) : null}
                        </>
                      ) : null}

                      {isNewMode() || isEditMode() ? (
                        <i
                          class="fa-solid fa-file-arrow-up is-clickable"
                          style={{
                            ...(Branch?.documents[0]?.fileLink?.length > 0
                              ? { display: "none" }
                              : null),
                          }}
                          onClick={() => hiddenFileInput1.current.click()}
                          id="identifer_refer_number">
                          <input
                            type="file"
                            onChange={(e1) => {
                              const pattern = /^\S[\s\S]*\S$|^\S$/
                              if (pattern.test(e1.target.files[0]["name"])) {
                                change(
                                  e1.target.files,
                                  "Identifer Reference Number",
                                  "identifer_refer_number"
                                )
                              } else {
                                configStore.setModalMessage(
                                  "Please Check File Name and Format"
                                )
                                return (e1.target.value = "")
                              }
                            }}
                            name="image"
                            accept="image/gif,image/jpeg,image/jpg,image/png,application/pdf"
                            multiple=""
                            ref={hiddenFileInput1}
                            data-original-title="upload photos"
                            style={{ display: "none" }}
                            {...(isViewMode()
                              ? { disabled: true }
                              : {
                                ...(isEditMode() &&
                                  (isPendingStatus() || isVerifiedStatus())
                                  ? { disabled: true }
                                  : {}),
                              })}
                          />
                        </i>
                      ) : null}
                    </span>
                  </div>
                </Col4>
              </>
            )}
            {(isNewMode()|| Branch.status =="REJECTED") &&Branch?.gst.length==15 && EntityUtils.isIndia(Entity)   ?<> <Col2 >  <p className="label is-small" style={{ marginBottom: "0px" }}>
                  <br></br>
                  </p>
                <button
                        className="button is-link"
                        style={{
                          border: "1px solid #ffffff",
                          borderRadius: "8px",
                          height: "35px",
                          color: "white",
                          // left: "14em",
                          position: "relative",
                          width: "100px",
                          marginLeft: "2%",
                        }}
                        //{...(isViewMode() ? { disabled: true } : {})}
                        onClick={async (event) => {
                        loader.show()
                        await EntityApi.verifyGST({
                            gstin:Branch?.gst
                          }, (response) => {
                          console.log("D1",response)
                          if(response.results.message=="Success" && response.results.code=='200'){
                            loader.hide()
                            console.log("Ds",response.results.response[0].responseStatus )
                            if(response.results.response[0].responseStatus == "ERROR"){
                              pendingErrors.gstVerify = "Please enter a valid GST number"
                              setGstVerify("Please enter a valid GST number")
                            }else{
                              
                             
                              if(response.results.response[0].response.gstnStatus=="Active"){
                                pendingErrors.gstVerify = "GST number verified succesfully"
                                setGstVerify("GST number verified succesfully")
                                setGstData(response.results.response[0].response)
                                dtactionStore.modalVisible1 = true
                              } else{
                                console.log("Testing..")
                                setGstData()
                                pendingErrors.gstVerify = "Please enter a valid GST number"

                                setGstVerify("Please enter a valid GST number")
                              }

                            }
                          } else
                          {
                            console.log("Testing Gst..")
                            loader.hide()
                            pendingErrors.gstVerify = "Please enter a valid GST number"

                            setGstVerify("Please enter a valid GST number")
                          }
                          // if (response?.data?.entityId !== Entity?.entityId) {
                          //   setGstAvailable(response);
                          // }
                           console.log(pendingErrors,gstData);
                        });
              
                       
                          //routerStore.entityCurrentViewNew = "edit"
                          event.stopPropagation()
                        }}
                        {...(isViewMode() ? { disabled: true } : {})}
                        >
                       Verify
                      </button>  </Col2> 
                  </>:null}
          </Columns>
          <Columns>
            {EntityUtils.isCustomer(Entity) ?
              <Col4>
                <p className="label is-small" style={{ marginBottom: "0px" }}>
                  Scope Of Business *
                </p>
                <div className="is-flex is-flex-direction-column">
                  {Object.values(SCOPE_OF_ACTIVITY).map((r, i) => {
                    return (
                      <>
                        <p className="is-flex is-flex-direction-row">
                          <input
                            id="scopeOfBusiness"
                            type="checkbox"
                            onClick={(e) => addscopeofbusiness(e, r)}
                            checked={checkScopeBs(r)}
                            {...(isViewMode() || isPendingStatus() || i === 1
                              ? { disabled: true }
                              : {})}></input>
                          <span className="is-size-7 ml-2">{r}</span>
                        </p>
                      </>
                    )
                  })}
                </div>
                {errors.scopeOfBusiness && (
                  <p class="help is-input-danger-message">
                    Please select this scope
                  </p>
                )}

              </Col4>
              : null}
          </Columns>
          {/* ========================== Cross-Border for Customer ===========================  */}

          {EntityUtils.isCustomer(Entity) && Branch.scopeOfBusiness.includes("Cross Border") ?
            <>
              <hr style={{ border: "2px solid grey", width: "100%", display: "block" }} />
              <p
                className="label is-3 mb-3"
                style={{ marginBottom: "0px" }}>
                Agraga Point of Contact- Cross-Border *
              </p>
              <Columns>
              <Col4>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Sales Vertical *
                      </p>
                      <select
                        className="select is-small "
                        style={{ width: "100%", border: "1px solid #d1d1e0" }}
                        onChange={(e) => handleChange(e,
                          "CB_salesVertical")}
                        {...(isViewMode() ? { disabled: true } : {})}
                        // value={Entity.agragaBranch}
                        id="CB_salesVertical">
                        <option selected disabled value="default">
                          Select Sales Vertical
                        </option>
                        {Object.values(SALESVERTICAL).map((r, i) => {
                          return (
                            <option
                              selected={shouldSelectOption(
                                r,
                                Branch?.crossBorder?.salesVertical
                              )}>
                              {r}
                            </option>
                          );
                        })}
                      </select>
                      {errors.CB_salesVertical && (
                        <p className="help is-input-danger-message">
                          {errors.CB_salesVertical}
                        </p>
                      )}
                    </Col4>
                <Col4>
                  <p className="label is-small" style={{ marginBottom: "0px" }}>
                    Agraga Branch *
                  </p>
                  <select
                    className="select is-small "
                    style={{ width: "100%", border: "1px solid #d1d1e0" }}
                    onChange={(e) => handleChange(e, "crossBorder", "CB_agragaBranch")}
                    {...(isViewMode() ? { disabled: true } : {})}
                    value={Branch?.crossBorder?.agragaBranch || "default"}
                    id="CB_agragaBranch">
                    <option selected disabled value=""
                    >
                      Select Agraga Branch
                    </option>
                    {/* {sortedbranchesForCB.map((r, i) => {
                      return (
                        <option
                          selected={shouldSelectOption(
                            r,
                            Branch.crossBorder.agragaBranch
                          )}
                        >
                          {r}
                        </option>
                      )
                    })} */}
                    {sortedbranches.map((r, i) => {
                          return <option
                            selected={shouldSelectOption(
                              r,
                              Branch.crossBorder?.agragaBranch
                            )}>
                            {r}
                          </option>

                        })}
                    {/* {renderBranchesCBOptions(
                              Object.values(branchesListForCBByVertical),
                              Branch.crossBorder.agragaBranch
                            )} */}
                  </select>
                  {errors.CB_agragaBranch && (
                    <p className="help is-input-danger-message">
                      {errors.CB_agragaBranch}
                    </p>
                  )}
                </Col4>
              </Columns>
              <Columns>

                {EntityUtils.isCustomer(Entity) ? (
                  <>
                    <Col4>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Relationship Manager *
                      </p>
                      <select
                        className={`select is-small ${
                          // pendingErrors.relationshipManager
                          // !isNewMode()
                         pendingErrors.relationshipManager
                            ? "is-select-danger"
                            : ""
                          }`}
                        style={{
                          width: "100%",
                          border: "1px solid #d1d1e0",
                        }}
                        onChange={(e) => {
                          handleDropdownChange2(
                            e,
                            "crossBorder",
                            "relationshipManager"
                          )
                          setHideEarlierRMMessage(true);
                        }}
                        {...(isViewMode() ? { disabled: true } : {})}
                        value={
                          // selectedRelationshipManager
                          //"default" //|| 
                          Branch?.crossBorder?.relationshipManager
                        }
                        id="relationshipManager">
                        <option selected  value="">
                          Select Relationship Manager
                        </option>

                        {renderSalesOptions(
                          Object.values(salesListForCB),
                          Branch?.crossBorder?.relationshipManager
                        )}
                      </select>

                      {errors.relationshipManager && (
                        <p className="help is-input-danger-message">
                          {errors.relationshipManager}
                        </p>
                      )}
                      {/* {title !== "Add New Branch" && !hideEarlierRMMessage && !isViewMode() && ( */}
                      {isVerifiedStatus() && !Branch.crossBorder.relationshipManager && isEditMode() && (
                        <p className="help is-input-info-message" style={{ color: "red" }}>
                            To be assigned, The Earlier RM was : {oldRM || "N/A"}
                        </p>
                      )}
                    </Col4>
                    <Col4>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Customer Service *
                      </p>
                      <select
                        className={`select is-small ${
                          // pendingErrors.customerService
                          // !isNewMode()
                           pendingErrors.customerService
                            ? "is-select-danger"
                            : ""
                          }`}
                        style={{
                          width: "100%",
                          border: "1px solid #d1d1e0",
                        }}
                        onChange={(e) => {
                          handleDropdownChange(e, "crossBorder", "customerService");
                          // setHideEarlierCSMessage(true);
                        }}
                        {...(isViewMode() ? { disabled: true } : {})}
                        id="customerService"
                        value={
                          // selectedCustomerService
                          Branch?.crossBorder?.customerService
                        }>
                        <option selected  value="">
                          Select Customer Service
                        </option>
                        {renderSalesOptions(
                          Object.values(csListForCB),
                          Branch?.crossBorder?.customerService
                        )}
                      </select>
                      {errors.customerService && (
                        <p className="help is-input-danger-message">
                          {errors.customerService}
                        </p>
                      )}
                      {/* {title !== "Add New Branch" && !hideEarlierCSMessage && !isViewMode() && ( */}
                      {isVerifiedStatus() && !Branch.crossBorder.customerService && isEditMode() && (
                        <p className="help is-input-info-message" style={{ color: "red" }}>
                            To be assigned, The Earlier RM was : {oldCS || "N/A"}
                        </p>
                      )}
                    </Col4>


                  </>
                ) : null}

              </Columns>
            </>

            : null
          }

          {EntityUtils.isCustomer(Entity) && Branch.scopeOfBusiness.includes("Domestic Transport") ?
            <>
              <hr style={{ border: "2px solid grey", width: "100%", display: "block" }} />
              <p
                className="label is-3 mb-3"
                style={{ marginBottom: "0px" }}>
                Agraga Point of Contact- Domestic Transport   *
              </p>
              <Columns>
                <Col4>
                  <p className="label is-small" style={{ marginBottom: "0px" }}>
                    Agraga Branch *
                  </p>
                  <select
                    className="select is-small "
                    style={{ width: "100%", border: "1px solid #d1d1e0" }}
                    onChange={(e) => handleChange(e, "DT_agragaBranch")}
                    {...(isViewMode() ? { disabled: true } : {})}
                    // value={Entity.agragaBranch}
                    id="DT_agragaBranch">
                    <option selected disabled value="default"
                    >
                      Select Agraga Branch
                    </option>
                    {sortedbranchesForDT.map((r, i) => {
                      return (
                        <option
                          selected={shouldSelectOption(
                            r,
                            Branch?.domesticTransport?.agragaBranch
                          )}
                        >
                          {r}
                        </option>
                      )
                    })}
                  </select>
                  {errors.DT_agragaBranch && (
                    <p className="help is-input-danger-message">
                      {errors.DT_agragaBranch}
                    </p>
                  )}
                </Col4>
              </Columns>
              <Columns>
                {EntityUtils.isCustomer(Entity) ? (
                  <>
                    <Col4>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Relationship Manager *
                      </p>
                      <select
                        className={`select is-small ${
                          // pendingErrors.relationshipManager
                          // !isNewMode()
                          (pendingErrors.DT_relationshipManager)
                            ? "is-select-danger"
                            : ""
                          }`}
                        style={{ width: "100%", border: "1px solid #d1d1e0" }}
                        onChange={(e) =>
                          handleChange(e, "domesticTransport", "DT_relationshipManager")
                        }
                        {...(isViewMode() ? { disabled: true } : {})}
                        value={
                          Branch?.domesticTransport?.relationshipManager || "default"
                        }
                        id="DT_relationshipManager">
                        <option selected disabled value="default">
                          Select Relationship Manager
                        </option>

                        {renderSalesOptions(
                          Object.values(salesListForDT),
                          Branch?.domesticTransport?.relationshipManager
                        )}
                      </select>
                      {errors.DT_relationshipManager && (
                        <p className="help is-input-danger-message">
                          {errors.DT_relationshipManager}
                        </p>
                      )}
                    </Col4>
                    <Col4>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Customer Service *
                      </p>
                      <select
                        className={`select is-small ${
                          // pendingErrors.customerService
                          // !isNewMode()
                          (pendingErrors.DT_customerService)

                            ? "is-select-danger"
                            : ""
                          }`}
                        style={{ width: "100%", border: "1px solid #d1d1e0" }}
                        onChange={(e) =>
                          handleChange(e, "domesticTransport", "DT_customerService")
                        }
                        {...(isViewMode() ? { disabled: true } : {})}
                        id="DT_customerService"
                        value={Branch?.domesticTransport?.customerService || "default"}>
                        <option selected disabled value="default">
                          Select Customer Service
                        </option>
                        {renderSalesOptions(
                          Object.values(csListForDT),
                          Branch?.domesticTransport?.customerService
                        )}
                      </select>
                      {errors.DT_customerService && (
                        <p className="help is-input-danger-message">
                          {errors.DT_customerService}
                        </p>
                      )}
                    </Col4>
                    <Col4>
                      <p
                        className="label is-small"
                        style={{ marginBottom: "0px" }}>
                        Branch Operation Manager (Domestic Transport) *
                      </p>
                      <select
                        className={`select is-small ${
                          // pendingErrors.branchOperationManager
                          (pendingErrors.DT_branchOperationManager)

                            // !isNewMode()
                            ? "is-select-danger"
                            : ""
                          }`}
                        style={{ width: "100%", border: "1px solid #d1d1e0" }}
                        onChange={(e) =>
                          handleChange(e, "domesticTransport", "branchOperationManager")
                        }
                        {...(isViewMode() ? { disabled: true } : {})}
                        id="branchOperationManager"
                        value={
                          Branch?.domesticTransport?.branchOperationManager || "default"
                        }>
                        <option selected disabled value="default">
                          Select Branch Operation Manager
                        </option>
                        {renderSalesOptions(
                          Object.values(branchOpsDTList),
                          Branch?.domesticTransport?.branchOperationManager
                        )}
                      </select>
                      {errors.branchOperationManager && (
                        <p className="help is-input-danger-message">
                          {errors.branchOperationManager}
                        </p>
                      )}
                    </Col4>

                  </>
                ) : null}

              </Columns>
            </>

            : null
          }

          {/* ========================== Cross-Border for Customer ===========================  */}


          {/* {EntityUtils.isCustomer(Entity) ? (
            <Columns>
              <Col4>
                <p className="label is-small" style={{ marginBottom: "0px" }}>
                  Advance Received for Domestic Transport ( % )
                </p>
                <div className="is-flex">
                  <input
                    maxLength={3}
                    type="number"
                    className="input is-small "
                    value={Branch?.advanceReceivableForDT}
                    // {...(isNewMode() ? { value: `${Entity?.advanceReceivableForDT}` } :  { value: `${Branch?.advanceReceivableForDT}` })}
                    //ref={refAdvanceReceived}
                    pattern="[0-9]{2}"
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                    }
                    min="1"
                    onChange={(e) => handleChange(e, "advanceReceivableForDT")}
                    {...(isViewMode() ? { disabled: true } : {})}
                  />
                </div>
                {advanceError && (
                  <p className="help is-danger">{advanceError}</p>
                )}
              </Col4>
            </Columns>
          ) : null} */}
          {EntityUtils.isVendor(Entity) &&
            EntityUtils.isRoadTransport(Entity) ? (
            <Columns>
              <Col4>
                <p className="label is-small" style={{ marginBottom: "0px" }}>
                  Advance Payable for Domestic Transport Bookings ( % )
                </p>
                <div className="is-flex">
                  <input
                    maxLength={3}
                    type="number"
                    className="input is-small "
                    value={Branch?.advancePayable}
                    //  ref={refAdvancePayable}
                    min="1"
                    pattern="[0-9]{2}"
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
                    } //
                    onChange={(e) => handleChange(e, "advancePayable")}
                    {...(isViewMode() ? { disabled: true } : {})}
                  />
                </div>
                {advancePayableError && (
                  <p className="help is-danger">{advancePayableError}</p>
                )}
              </Col4>
            </Columns>
          ) : null}

          {EntityUtils.isVendor(Entity) ? (
            <>
              <h3 className="subtitle">Point of Contact</h3>
              <Columns>
                {(EntityUtils.isIndia(Entity)) &&
                  <Col4>
                    <p className="label is-small" style={{ marginBottom: "0px" }}>
                      Agraga Branch *
                    </p>
                    <select
                      className="select is-small "
                      style={{ width: "100%", border: "1px solid #d1d1e0" }}
                      onChange={(e) => handleChange(e, "agragaBranch")}
                      {...(isViewMode() ? { disabled: true } : {})}
                      // value={Entity.agragaBranch}
                      id="agragaBranch">
                      <option selected disabled value="default"
                      >
                        Select Agraga Branch
                      </option>
                      {sortedbranches.map((r, i) => {
                        return (
                          <option
                            selected={shouldSelectOption(
                              r,
                              Branch?.agragaBranch
                            )}
                          >
                            {r}
                          </option>
                        )
                      })}
                    </select>
                    {errors.agragaBranch && (
                      <p className="help is-input-danger-message">
                        {errors.agragaBranch}
                      </p>
                    )}
                  </Col4>}
                <Col4>
                  <p className="label is-small" style={{ marginBottom: "0px" }}>
                    Relationship Manager Supply
                  </p>
                  <select
                    className="select is-small "
                    style={{ width: "100%", border: "1px solid #d1d1e0" }}
                    value={Branch.relationShipManagerSupply}
                    {...(isViewMode() ? { disabled: true } : {})}
                    onChange={(e) =>
                      handleChange(e, "relationShipManagerSupply")
                    }>
                    <option selected disabled value="">
                      Select Relationship Manager Supply
                    </option>
                    {renderSalesOptions(
                      Object.values(relMgrSupplyList),

                      Branch?.relationShipManagerSupply
                    )}
                  </select>
                  {/* {errors.branchOperationManager && (
                <p className="help is-input-danger-message">{errors.branchOperationManager}</p>
              )} */}
                </Col4>
              </Columns>
            </>
          ) : null}

          <hr
            style={{
              border: "0.5px solid grey",
              backgroundColor: "grey",
            }}></hr>
          <h3 className="subtitle">Branch Address</h3>
          {Branch.branchAddresses?.map((r, index) => {
            return (
              <Columns>
                <Col4>
                  <p className="label is-small" style={{ marginBottom: "0px" }}>
                    Address*
                  </p>
                  <input
                    type="text"
                    className="input is-small "
                    value={r?.address}
                    ref={ref}
                    pattern="[a-zA-Z0-9]"
                    onChange={(e) =>
                      setBranchAddress(e.target.value, "address", index)
                    }
                    {...(isViewMode() ? { disabled: true } : {})}
                    id="address"
                  />
                  {errors.address && (
                    <p className="help is-danger">{errors.address}</p>
                  )}
                </Col4>
                { (EntityUtils.isIndia(Entity)) ? (
                
                <Col4>
                  <p className="label is-small" style={{ marginBottom: "0px" }}>
                    City*
                  </p>
                  {city==false && gstData ?<>
                    <input
                      type="text"
                      className="input is-small "
                      value={r?.city}
                      onChange={(e) =>
                        setBranchAddress(e.target.value, "city", index)
                      }
                      {...(isViewMode() ? { disabled: true } : {})}
                      id="city"
                      
                    />
                     
                      
                          
                    </>:<>
                    { r.city!=""  ?  
                    <>
                    
                  {checkExistingCity(r.city)!=true?
                   <input
                   type="text"
                   className="input is-small "
                   value={r?.city}
                   onChange={(e) =>
                     setBranchAddress(e.target.value, "city", index)
                   }
                   {...(isViewMode() ? { disabled: true } : {})}
                   id="city"
                   
                 />:<select
                 className="select is-small"
                 // type="text"
                 style={{ width: "100%", border: "1px solid #d1d1e0" }}
                 value={r?.city}
                 onChange={(e) =>
                   setBranchAddress(e.target.value, "city", index)
                 }
                 {...(isViewMode() ? { disabled: true } : {})}
                 id="city">
                 <option value="">Select city</option>
                 {City?.getCitiesOfState(
                   Entity?.entityCountryCode,
                   Branch.state.split("/")[1]
                 ).map((r, i) => {
                   //console.log("cities",cities)
                   return <option value={r.name}>{r.name}</option>
                 })}
               </select>}
                    </>
                    :  
                      
                     <select
                    className="select is-small"
                    // type="text"
                    style={{ width: "100%", border: "1px solid #d1d1e0" }}
                    value={r?.city}
                    onChange={(e) =>
                      setBranchAddress(e.target.value, "city", index)
                    }
                    {...(isViewMode() ? { disabled: true } : {})}
                    id="city">
                    <option value="">Select city</option>
                    {City?.getCitiesOfState(
                      Entity?.entityCountryCode,
                      Branch.state.split("/")[1]
                    ).map((r, i) => {
                      //console.log("cities",cities)
                      return <option value={r.name}>{r.name}</option>
                    })}
                  </select>}
                 
                  {errors.city && (
                    <p className="help is-input-danger-message">
                      {errors.city}
                    </p>
                  )}</>}
                  
                </Col4> ) : (
                  <Col4>
                  <p className="label is-small" style={{ marginBottom: "0px" }}>
                    City*
                  </p>
                  <input
                      type="text"
                      className="input is-small "
                      value={ Branch.branchAddresses[0].city}
                      onChange={(e) =>
                        setBranchAddress(e.target.value, "city", index)
                      }
                      {...(isViewMode() ? { disabled: true } : {})}
                      id="city"
                    />
                    {errors.city && (
                      <p className="help is-danger">{errors.city}</p>
                    )}
                </Col4>
                )}
                {EntityUtils.isIndia(Entity) ? (
                  <Col3>
                    <p
                      className="label is-small"
                      style={{ marginBottom: "0px" }}>
                      Pincode *
                    </p>
                    <input
                      type="number"
                      className="input is-small "
                      value={r?.pincode}
                      // ref={ref}
                      onKeyDown={(e) =>
                        ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
                      }
                      // pattern="[a-zA-Z0-9]"
                      onChange={(e) =>
                        setBranchAddress(e.target.value, "pincode", index)
                      }
                      {...(isViewMode() ? { disabled: true } : {})}
                      id="pincode"
                    />
                    {errors.pincode && (
                      <p className="help is-danger">{errors.pincode}</p>
                    )}
                  </Col3>
                ) : (
                  <Col3>
                    <p
                      className="label is-small"
                      style={{ marginBottom: "0px" }}>
                      Zipcode*
                    </p>
                    <input
                      type="text"
                      className="input is-small "
                      value={r?.zipcode}
                      // ref={ref}
                      pattern="[a-zA-Z0-9]{}"
                      onChange={(e) =>
                        setBranchAddress(e.target.value, "zipcode", index)
                      }
                      {...(isViewMode() ? { disabled: true } : {})}
                      id="zipcode"
                    />
                    {errors.zipcode && (
                      <p className="help is-danger">{errors.zipcode}</p>
                    )}
                  </Col3>
                )}
                {!isViewMode() ? (
                  <>
                    <span className="ml-3 mt-5 icon is-medium">
                      <BiPlus
                        className="is-clickable fas fa-lg"
                        style={{ color: "red" }}
                        onClick={addNewBranchAddress}
                      />
                    </span>
                    <span className="mt-5 icon is-medium">
                      <MdDelete
                        className="is-clickable fas fa-lg"
                        onClick={() => removeBranchAddressLine(index)}
                      />
                    </span>
                  </>
                ) : null}
              </Columns>
            )
          })}
        </div>
        <div>
          {Branch.status === "REJECTED" ? (
            <div className="is-size-5 mt-5">
              <span className="ml-6 has-text-weight-bold ">Remarks :</span>
              <span className="ml-3">{Branch.remarks}</span>
            </div>
          ) : null}
        </div>


        <div className="is-flex">
          {/* {  isViewMode()  ? null : (
            <> */}
          <button
            //className="is-clickable"
            className="button is-primary"
            onClick={() => {
              if(AgmodalStore.modalPage === "branchAPP"){
                AgmodalStore.pageVisible = false;
                AgmodalStore.apiCall = true;
              }
              if (props.ch) {
                return routerStore.creditApplicationView ="list"
              }
              routerStore.branchCurrentView = "list"
              //document.getElementById("editBranch").style.pointerEvents = "none";
            }}
            style={{
              backgroundColor: "#32a893",
              border: "none",
              padding: "1%",
              borderRadius: "8px",
              color: "white",
              fontWeight: "600",
              marginTop: "5%",
              marginLeft: "83%",
              width: "70px",
              cursor: "pointer",
            }}>
            CANCEL
          </button>
          {/* </>
          )} */}
          {isViewMode() ? null : (
            <>
              <button
                //className="is-clickable"
                className="button is-primary"
                onClick={() => (setloading(true), onFormSubmit())}
                style={{
                  backgroundColor: "#32a893",
                  border: "none",
                  padding: "1%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "5%",
                  marginLeft: "1%",
                  width: "70px",
                  cursor: "pointer",
                }}
                disabled={loading}>
                {!loading ? "SUBMIT" : <Loader className="spinner" />}
              </button>
            </>
          )}
        </div>
      </div>
      {verifyPopup ? (
        <div
          style={{
            width: "450px",
            // height: "150px",
            backgroundColor: "#ffffff",
            borderRadius: "10px",
            border: "1px solid black",
            position: "absolute",
            top: `45%`,
            left: `35%`,
            padding: "1% 1% 1% 1%",
            zIndex: 1,
          }}>
          <div className="is-flex is-justify-content-space-between">
            <span className="is-size-6" style={{ marginTop: "5%" }}>
              Document verification for{" "}
              <span className="is-size-5" style={{ fontWeight: "bold" }}>
                {Branch?.branchName}
              </span>
              <div>
                <label>
                  <input
                    type="radio"
                    className="is-size-2"
                    name="approvalStatus"
                    value="approve"
                    checked={approvalStatus === "approve"}
                    onChange={handleVerifyRadioChange}
                  />
                  Approve
                </label>
                <label style={{ marginLeft: "10px" }}>
                  <input
                    type="radio"
                    className="is-size-2"
                    name="approvalStatus"
                    value="reject"
                    checked={approvalStatus === "reject"}
                    onChange={handleVerifyRadioChange}
                  />
                  Reject
                </label>
              </div>
            </span>
            <span>
              <ImCancelCircle onClick={cancelpopup} className="is-clickable" />
            </span>
          </div>
          {approvalStatus === "reject" && (
            <div>
              <textarea
                //  id="rejectremarksid"
                rows="4"
                cols="50"
                placeholder="Remarks"
                value={message}
                onChange={handleMessageChange}
                //onChange={approvebtnnfn}
                style={{ marginTop: "10px", border: "1px solid #d1d1e0" }}
              />
            </div>
          )}
          {approvalStatus === "approve" && (
            <div>
              Do you want to approve? <span></span>
              <input
                type="checkbox"
                className="is-size-2"
                name="approvalStatus"
                value="approve"
                onChange={approvebtnnfn}
              />
            </div>
          )}

          <div>
            <span>
              <button
                style={{
                  backgroundColor: "#00ccff",
                  border: "none",
                  padding: "2%",
                  borderRadius: "8px",
                  color: "white",
                  fontWeight: "600",
                  marginTop: "4%",
                  marginLeft: "80%",
                  opacity: 0.4,
                  width: "75px",
                }}
                id="approvebtnid"
                disabled={appbtnstatus}
                onClick={updateStatusForBranch}>
                {!loading ? "SUBMIT" : <Loader className="spinner" />}
              </button>
            </span>
          </div>
        </div>
      ) : null}
    </>
  )

  function clearErrorMessages(r) {
    pendingErrors[r] = ""
    setPendingErrors(pendingErrors)
  }
}
