import { subscribe, useSnapshot } from "valtio"
import { useEffect, useRef, useState } from "react"
import { AgmodalStore, configStore, creditformStore, EntityV2Store, loginStore } from "../state/CommonState";
import Utils from "../util/Utils";
import { OneInput, OneLabel } from "./act-common";
import TaskDatetime from "./task-datetime";
import Taskfileapproval, { validateFileApproval } from "./task-file-approval";
import Taskfiledisplay from "./task-file-display";
import Taskfiletable from "./task-file-table";
import { taskStore } from "./task-store";
import Taskapi from "./tasks-api";
import { MdDelete } from "react-icons/md";
import { BiPlus } from "react-icons/bi";
import Api from "../state/Api";
import CreditsApis from "../state/credit-api";
import Taskfiletable1 from "./task-file-table1";

export default function InvoiceAcceptances(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    useSnapshot(AgmodalStore)
    let task = taskStore.current
    const Entity = task.financeInformation
    // const [errors1, setErrors1] = useState({});
    function checkfloat(val){
        let a = val.replace(/[^0-9.]/g, '').split('.');
        if(a.length>1){
            a = a[0]+'.'+a[1];
        }else{
            a = a[0]
        }
        return a
    }
    let stage = task.stage
    const [disableButton, setDisableButton] = useState(true)
    function check(){
        // if(task.netAmount) task.netAmount = checkfloat(task.netAmount)
        // if(task.gstAmount) task.gstAmount = checkfloat(task.gstAmount)
        let disable = false
        let checkfile = []
        taskStore.current.files.map((e)=>{
          if(e.invoiceNo ===''||e.invoiceDate===''||e.label===''||e.approved===''){
              disable = true
          }else{
              disable = false
          }
      })
        taskStore.current.files.map((e) =>{
            if(checkfile.indexOf(e.label) == -1){
                if(taskStore.current.original_file.indexOf(e.label) != -1 && e.filename.length==0){
                    disable = true
                }else{
                    if(taskStore.current.original_file.indexOf(e.label) != -1){
                        checkfile.push(e.label)
                    }
                }
            }
        })
        // if(Utils.toNum(task.netAmount)==0 || Utils.toNum(task.gstAmount)<0 ){
        //     disable = true
        // }
        // task.totalAmount = Utils.toNum(task.netAmount) + Utils.toNum(task.gstAmount)
        setDisableButton(disable)
    }
    const handleInputChange = (index, field, value) => {
      const updatedData = [...task.currency];
      updatedData[index][field] = value;
  
      // Recalculate totalAmount if netAmount or gstAmount changes
      if (field === "netAmount" || field === "gstAmount") {
        const netAmount = parseFloat(updatedData[index].netAmount) || 0;
        const gstAmount = parseFloat(updatedData[index].gstAmount) || 0;
        updatedData[index].totalAmount = Utils.toNum(netAmount) + Utils.toNum(gstAmount);
      }
    };
    useEffect(() => {
        check()
        let unsubscribe = subscribe(taskStore.current, () => {

            check()
        })
        return unsubscribe
    }, [taskStore.current])

    if (stage === 1) {
        return (
            <div className="box">
                <p>
                    Please Approve the attached invoice by <strong>{Utils.formatToDateTime1(task.dueDate, task.dueTime)}</strong>
                </p>
                {
                    (task.remarks) ?
                        <p>Remarks : {task.remarks}</p>
                        : <></>
                }
                <hr />
                <Taskfileapproval additionalField={true} />
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
          {task.currency.map((e, index) => (
            <>
              <p style={{ fontFamily: 'bold', fontSize: '1rem' }}>{e.label}</p>
              {e.currencyType?<tr>
                <td>
                  <div style={{ marginRight: '5px', marginTop: '3px' }}>Currency Type </div>
                </td>
                <td>
                  {e.currencyType}
                </td>
              </tr>:<></>}
              {/* <tr>
              <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>Remarks For </div>
              </td>
              <td>
                {e.remarks}
              </td>
            </tr> */}
              <tr>
                <td>
                  <div style={{ marginRight: '5px', marginTop: '3px' }}>Net Invoice Amount</div>
                </td>
                <td>{e.netAmount}</td>
              </tr>
              <tr>
                <td>
                  <div style={{ marginRight: '5px', marginTop: '3px' }}>GST Amount</div>
                </td>
                <td>{e.gstAmount}
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ marginRight: '5px', marginTop: '3px' }}>Total Amount</div>
                </td>
                <td>
                  {e.totalAmount}
                </td>
              </tr>
              <hr />
            </>
          ))}
        </table>
                {Entity && Entity.length >0 && Entity.some(e => Object.values(e) != '') ?
                <div className="px-4">
              <div>
                <p className="is-size-5 has-text-weight-bold mt-4">
                  Finance Contact Information
                </p>
                <div className="is-flex pt-4 ">
                  <table className="table_caf_fin wd_80">
                    <thead>
                      <td>Contact Person</td>
                      <td>Description/Department</td>
                      <td>Contact Number</td>
                      <td>Email</td>
                      </thead>
                    <tbody>
                      {Entity?.map((r, i) => {
                        return (
                          <tr>
                            <td>
                              <input
                                className={`inp_table_caf is-size-6`}
                                value={
                                  Entity[i]?.customercontact
                                }
                                disabled={true}>
                                </input>
                            </td>
                            <td>
                              <input
                                className={`inp_table_caf is-size-6`}
                                value={Entity[i]?.department}
                                disabled={true}>
                                </input>
                            </td>
                            <td
                              style={{ borderRight: "none" }}
                              className={`is-flex wd_101`}>
                              <span className="contNumcodespn mt-1"></span>
                              <input
                                type="number"
                                className={`inp_table_caf is-size-6`}
                                value={Entity[i]?.contactnum}
                                disabled={true}
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-", "."].includes(e.key) &&
                                  e.preventDefault()
                                }
                                min={0}></input>
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              <input
                                className={`inp_table_caf is-size-6`}
                                value={Entity[i]?.email}
                                disabled={true}
                                ></input>
                            </td>
                            </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>:<></>}

                <p>
                    <span>
                        <button style={{ margin:'10px',float: 'left' }} className="button islink" disabled={disableButton}
                            onClick={async (e) => {
                                let task = taskStore.current
                                let ret = validateFileApproval(task)
                                if (ret.errors.length > 0) {
                                    configStore.setModalMessage(ret.errors.join(','))
                                    return
                                }
                                if (ret.rejected){
                                    [task.stage, task.pendingWith] = [2, 'AGRAGA']
                                }else{
                                    [task.stage, task.pendingWith] = [4, 'CUSTOMER']
                                    let creditCustomer = EntityV2Store.EntityRec.isCreditCustomer;
                                    let isInvoice = taskStore.current.files.some(file=>file.label === 'Invoice');
                                    let isCustomsDuties = taskStore.current.files.some(file=>file.label.indexOf('Custom Duties') != -1);
                                    if ((creditCustomer === "No" || !creditCustomer)) {
                                      console.log("inside1234 invoice customs cash");
                                      taskStore.current.creditCustomer = 'No'
                                      taskStore.current.files.map((e) => {
                                        if (e.label === 'Others' && e.paymentTerms === 'Custom') {
                                          const initialDate = new Date(e.invoiceDate);
                                          const updatedDate = new Date(initialDate);
                                          updatedDate.setDate(initialDate.getDate() + Number(e.creditDays));
                                          e['triggered'] = true
                                          e['scheduledDate'] = updatedDate.toISOString().split("T")[0]
                                        }
                                      })
                                      await Api.triggerPaymentAction(taskStore.current)
                                    }
                                    if (creditCustomer === "Yes") {
                                      console.log("inside1234 customs credit");
                                      taskStore.current.creditCustomer = 'Yes'
                                      taskStore.current.files.map((e) => {
                                        if (e.label === 'Others' && (e.paymentTerms === 'Custom' || e.paymentTerms === 'As per Credit Facility Agreed')) {
                                          const initialDate = new Date(e.invoiceDate);
                                          const updatedDate = new Date(initialDate);
                                          updatedDate.setDate(initialDate.getDate() + Number(e.paymentTerms === 'Custom' ? e.creditDays : creditformStore.CreditformRec.duration));
                                          task.scheduledTrigger = true
                                          e['scheduledDate'] = updatedDate.toISOString().split("T")[0]
                                          e['triggered'] = true
                                        }
                                        if (e.label === 'Invoice') {
                                          const initialDate = new Date(e.invoiceDate);
                                          const updatedDate = new Date(initialDate);
                                          updatedDate.setDate(initialDate.getDate() + Number(creditformStore.CreditformRec.duration));
                                          task.scheduledTrigger = true
                                          e['scheduledDate'] = updatedDate.toISOString().split("T")[0]
                                          e['triggered'] = true
                                        }
                                      })
                                      await Api.triggerPaymentAction(taskStore.current)
                                    }    
                                    taskStore.setComplete()                                
                                }    
                                taskStore.setCustomerComplete()
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                                if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                  AgmodalStore.pageVisible = false
                                  AgmodalStore.apiCall = true
                                }
                            }}>Submit</button>
                    </span>
                </p>
            </div>
        )
    }
    if (stage === 2) {
        return (
            <>
                <p><strong>The Invoice was rejected.</strong></p>
                <TaskDatetime />
                <Taskfiletable1 showRejectReason={true} additionalField={true} />
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
          {task.currency.map((e, index) => (
            <>
              <p style={{ fontFamily: 'bold', fontSize: '1rem' }}>{e.label}</p>
              <tr>
                <td>
                  <div style={{ marginRight: '5px', marginTop: '3px' }}>Currency Type </div>
                </td>
                <td>
                  <select value={e.currencyType} onChange={(e) =>
                    handleInputChange(index, "currencyType", e.target.value)
                  } class="currency-dropdown">
                    <option value="">Select</option>
                    <option value="INR">INR</option>
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                  </select>
                </td>
              </tr>
              {/* <tr>
              <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>Remarks For </div>
              </td>
              <td>
                <input
                  type="text"
                  onChange={(e) =>
                    handleInputChange(index, "remarks", e.target.value)
                  }
                />
              </td>
            </tr> */}
              <tr>
                <td>
                  <div style={{ marginRight: '5px', marginTop: '3px' }}>Net Invoice Amount</div>
                </td>
                <td><input
                  type="number"
                  value={e.netAmount}
                  onChange={(e) =>
                    handleInputChange(index, "netAmount", e.target.value)
                  }
                /></td>
              </tr>
              <tr>
                <td>
                  <div style={{ marginRight: '5px', marginTop: '3px' }}>GST Amount</div>
                </td>
                <td><input
                  type="number"
                  value={e.gstAmount}
                  onChange={(e) =>
                    handleInputChange(index, "gstAmount", e.target.value)
                  }
                />
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ marginRight: '5px', marginTop: '3px' }}>Total Amount</div>
                </td>
                <td>
                  {e.totalAmount}
                </td>
              </tr>
              <hr />
            </>
          ))}
        </table>
                <p>
                    <span><strong>Remarks</strong></span>
                    <br />
                    <span><textarea cols='50'
                        value={taskStore.current.remarks} onChange={(e) => {
                            taskStore.current.remarks = e.target.value
                        }} /></span>
                </p>
                <p>
                    <span>
                        <button style={{ float: 'left' }} className="button islink" disabled={disableButton}
                            onClick={async (e) => {
                                taskStore.current.stage = 1
                                taskStore.current.pendingWith = 'CUSTOMER'
                                taskStore.current.customerStatus = 'PENDING'
                                await Taskapi.updateTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                                if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                  AgmodalStore.pageVisible = false
                                  AgmodalStore.apiCall = true
                                }
                            }}>Submit</button>
                    </span>
                </p>
            </>
        )
    }
    if (stage >= 3) {
        return (
            <>
                {task.files.find((e)=>e.approved =='Reject')?<p>The Invoice was rejected.</p>:<p>The Invoice was approved.</p>}
                <hr />
                <Taskfiledisplay additionalField={true} />
                <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
          {task.currency.map((e, index) => (
            <>
              <p style={{ fontFamily: 'bold', fontSize: '1rem' }}>{e.label}</p>
              {e.currencyType?<tr>
                <td>
                  <div style={{ marginRight: '5px', marginTop: '3px' }}>Currency Type </div>
                </td>
                <td>
                  {e.currencyType}
                </td>
              </tr>:<></>}
              {/* <tr>
              <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>Remarks For </div>
              </td>
              <td>
                {e.remarks}
              </td>
            </tr> */}
              <tr>
                <td>
                  <div style={{ marginRight: '5px', marginTop: '3px' }}>Net Invoice Amount</div>
                </td>
                <td>{e.netAmount}</td>
              </tr>
              <tr>
                <td>
                  <div style={{ marginRight: '5px', marginTop: '3px' }}>GST Amount</div>
                </td>
                <td>{e.gstAmount}
                </td>
              </tr>
              <tr>
                <td>
                  <div style={{ marginRight: '5px', marginTop: '3px' }}>Total Amount</div>
                </td>
                <td>
                  {e.totalAmount}
                </td>
              </tr>
              <hr />
            </>
          ))}
        </table>
                {Entity && Entity.length >0 ?
                <div className="px-4">
              <div>
                <p className="is-size-5 has-text-weight-bold mt-4">
                  Finance Contact Information
                </p>
                <div className="is-flex pt-4 ">
                  <table className="table_caf_fin wd_80">
                    <thead>
                      <td>Contact Person</td>
                      <td>Description/Department</td>
                      <td>Contact Number</td>
                      <td>Email</td>
                      </thead>
                    <tbody>
                      {Entity?.map((r, i) => {
                        return (
                          <tr>
                            <td>
                              <input
                                className={`inp_table_caf is-size-6`}
                                value={
                                  Entity[i]?.customercontact
                                }
                                disabled={true}>
                                </input>
                            </td>
                            <td>
                              <input
                                className={`inp_table_caf is-size-6`}
                                value={Entity[i]?.department}
                                disabled={true}>
                                </input>
                            </td>
                            <td
                              style={{ borderRight: "none" }}
                              className={`is-flex wd_101`}>
                              <span className="contNumcodespn mt-1"></span>
                              <input
                                type="number"
                                className={`inp_table_caf is-size-6`}
                                value={Entity[i]?.contactnum}
                                disabled={true}
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-", "."].includes(e.key) &&
                                  e.preventDefault()
                                }
                                min={0}></input>
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              <input
                                className={`inp_table_caf is-size-6`}
                                value={Entity[i]?.email}
                                disabled={true}
                                ></input>
                            </td>
                            </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>:<></>}
                
            </>
        )
    }
}
export function InvoiceTrigger(props) {
    useSnapshot(taskStore)
    const [disableButton, setDisableButton] = useState(true)
    const [cursor, setCursor] = useState(0)
    let task = taskStore.current
    const inputRef = useRef(null)
    const [errors, setErrors] = useState({});
    useEffect(() => {
      async function fetchdata() {
        const response = await Api.getFinanceDetails(task.entityId)
        taskStore.financeInformation = response.financeInformation || [{
          customercontact: "",
          department: "",
          contactnum: "",
          email: "",
        }]
      }
      fetchdata()
  }, [])
  useEffect(() => {
    const input = inputRef.current
    if (input) {
      input.setSelectionRange(cursor, cursor)
  }
  }, [inputRef, cursor])
    const handleChange3 = (e, i, r) => {
        setCursor(e.target.selectionStart);
        let re = new RegExp("^([A-Za-z0-9- ]{0,100})$");
        if (r !== "email" && !re.test(e.target.value)) {
          return null;
        }
        if (r === "contactnum" && e.target.value.length > 15) {
          return null;
        }
        if (e.target.value.length > 100) {
          return null;
        }
        taskStore.financeInformation[i][r] = e.target.value;
      };
      let showFinPlusIcon = false
      if (
        taskStore.financeInformation[taskStore.financeInformation.length - 1]
          .customercontact != "" &&
          taskStore.financeInformation[taskStore.financeInformation.length - 1]
          .contactnum != "" &&
          taskStore.financeInformation[taskStore.financeInformation.length - 1]
          .department != "" &&
          taskStore.financeInformation[taskStore.financeInformation.length - 1].email !=
        ""
      ) {
        showFinPlusIcon = true;
      }
      const v =false
      const [errors1, setErrors1] = useState({});
      const finArray = [
        {
          customercontact: "",
          department: "",
          contactnum: "",
          email: "",
        },
      ];
      const addNewFinancerow1 = () => {
        const array = [...taskStore.financeInformation];
        array.push(finArray[0]);
        taskStore.financeInformation = array;
        if (errors1.financeInformation) {
          errors1.financeInformation = null;
        }
      };
      const removeFinancerow1 = (i) => {
        const array = [...taskStore.financeInformation];
        if (array.length > 1) {
          array.splice(i, 1);
        }
        taskStore.financeInformation = array;
        if (errors1.financeInformation) {
          errors.financeInformation = null;
        }
      };
      const dovalidationcheck1 = async () => {
        function isValid(value) {
          return (
            (!Array.isArray(value) &&
              value &&
              value.trim().length > 0 &&
              value !== "default") ||
            (Array.isArray(value) &&
              value.some((item) => item.trim() !== "default"))
          );
        }
        let errors1 = {};
        let fieldsCheck1 = { isError: false };
        
        errors1.financeInformation = {
          obj: [],
          error: false,
        }
        const emailRegexPattern =
          // /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9-]+.[A-Za-z0-9-]+$/;
          /^[A-Za-z0-9_!#$%&'*+/=?{|}~^.-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

        
        let valueEnterCheck = false
        taskStore.financeInformation.forEach((r,i)=>{
          if (r.customercontact || r.department || r.contactnum || r.email) {
            valueEnterCheck = true
          }
        })
        if(valueEnterCheck===true){
          taskStore.financeInformation.forEach((r, i) => {
            errors1.financeInformation.obj.push({
              customercontact: "",
              department: "",
              contactnum: "",
              email: "",
            });
            if (!isValid(r.customercontact)) {
              errors1.financeInformation.obj[
                i
              ].customercontact = `Please enter all values`;
              fieldsCheck1.isError = true;
              errors1.financeInformation.error = true;
            }
            if (!isValid(r.department)) {
              errors1.financeInformation.obj[
                i
              ].department = `Please enter all values`;
              fieldsCheck1.isError = true;
              errors1.financeInformation.error = true;
      
            }
            if (!isValid(r.contactnum)) {
              errors1.financeInformation.obj[
                i
              ].contactnum = `Please enter all values`;
              fieldsCheck1.isError = true;
              errors1.financeInformation.error = true;
            }
            if (!isValid(r.email)) {
              errors1.financeInformation.obj[i].email = `Please enter all values`;
              fieldsCheck1.isError = true;
              errors1.financeInformation.error = true;
            }
            if (isValid(r.contactnum) &&  (r.contactnum.length > 15 || r.contactnum.length < 7)) {
              errors1.financeInformation.obj[
                i
              ].contactnum = `Enter Valid Phone Number (7-15 Digits)`;
              fieldsCheck1.isError = true;
              errors1.financeInformation.error = true;
              errors1.financeInformation.contactNumber = `Enter Valid Phone Number (7-15 Digits)`;
            }
            if (isValid(r.email) && !emailRegexPattern.test(r.email.trim())) {
              errors1.financeInformation.obj[i].email = `Invalid email pattern`;
              fieldsCheck1.isError = true;
              errors1.financeInformation.error = true;
              errors1.financeInformation.email = `Invalid email`;
            }
          });
        }
        
    
        setErrors1(errors1);
        return fieldsCheck1;
      };

    function checkfloat(val) {
        let a = val.replace(/[^0-9.]/g, '').split('.');
        if(a.length>1){
            a = a[0]+'.'+a[1];
        }else{
            a = a[0]
        }
        return a
    }
    function check(){
        let disable = false
        // if(task.netAmount) task.netAmount = checkfloat(task.netAmount)
        // if(task.gstAmount) task.gstAmount = checkfloat(task.gstAmount)
        let checkfile = []
        taskStore.current.files.map((e)=>{
          if(e.invoiceNo ===''||e.invoiceDate===''||e.label===''||e.filename===''||taskStore.current.dueDate===''||taskStore.current.dueTime===''){
              disable = true
          }else{
              disable = false
          }
        if (e.label === 'Others' && (e.chargeDescription === '' || e.paymentTerms === '') ||
        e.label === 'Others' && (e.paymentTerms === 'Custom' && e.creditDays === '')) {
        disable = true
      }
      })
        taskStore.current.files.map((e) =>{
            if(checkfile.indexOf(e.label) == -1){
                if(taskStore.current.original_file.indexOf(e.label) != -1 && e.filename.length==0){
                    disable = true
                }else{
                    if(taskStore.current.original_file.indexOf(e.label) != -1){
                        checkfile.push(e.label)
                    }
                }
            }
        })
        taskStore.currencyData.map((e) => {
          if (Utils.toNum(e.netAmount) == 0 || Utils.toNum(e.gstAmount) < 0 || e.currencyType === '') {
            disable = true
          }
        })
        // if(Utils.toNum(task.netAmount)==0 || Utils.toNum(task.gstAmount)<0 || taskStore.current.dueDate.length == 0 ||task.currencyType == ''){
        //     disable = true
        // }
        // task.totalAmount = Utils.toNum(task.netAmount) + Utils.toNum(task.gstAmount)
        setDisableButton(disable)
    }
    useEffect(() => {
        check()
        let unsubscribe = subscribe(taskStore, () => {
            check()
        })
        return unsubscribe
    }, [taskStore])
    const handleInputChange = (index, field, value) => {
      if(field === 'currencyType'){
        taskStore.currencyData[index][field] = value;
      }else{
        taskStore.currencyData[index][field] = Utils.toNum(value);
      }
      // Recalculate totalAmount if netAmount or gstAmount changes
      if (field === "netAmount" || field === "gstAmount") {
        const netAmount = parseFloat(taskStore.currencyData[index].netAmount) || 0;
        const gstAmount = parseFloat(taskStore.currencyData[index].gstAmount) || 0;
        taskStore.currencyData[index].totalAmount = Utils.toNum(netAmount) + Utils.toNum(gstAmount);
      }
    };
    return (
        <>
            <TaskDatetime />
            <hr />
            <Taskfiletable1 additionalField={true} />
            <table className="table is-striped is-fullwidth is-narrow" style={{ fontSize: '0.8rem' }}>
        {taskStore.currencyData.map((e, index) => (
          <>
            <label style={{ fontSize: '1.8rem', fontWeight: 'bold' }}>{e.label}</label>
            <tr>
              <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>Currency Type </div>
              </td>
              <td>
                <select onChange={(e) =>
                  handleInputChange(index, "currencyType", e.target.value)
                } class="currency-dropdown">
                  <option value="">Select</option>
                  <option value="INR">INR</option>
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                </select>
              </td>
            </tr>
            {/* <tr>
              <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>Remarks For </div>
              </td>
              <td>
                <input
                  type="text"
                  onChange={(e) =>
                    handleInputChange(index, "remarks", e.target.value)
                  }
                />
              </td>
            </tr> */}
            <tr>
              <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>Net Invoice Amount</div>
              </td>
              <td><input
                value={e.netAmount}
                onChange={(e) =>
                  handleInputChange(index, "netAmount", e.target.value)
                }
              /></td>
            </tr>
            <tr>
              <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>GST Amount</div>
              </td>
              <td><input
                value={e.gstAmount}
                onChange={(e) =>
                  handleInputChange(index, "gstAmount", e.target.value)
                }
              />
              </td>
            </tr>
            <tr>
              <td>
                <div style={{ marginRight: '5px', marginTop: '3px' }}>Total Amount</div>
              </td>
              <td>
                {e.totalAmount}
              </td>
            </tr>
            <hr />
          </>
        ))}
      </table>
           
{/* final */}
<div className="px-4">
              <div>
                <p className="is-size-5 has-text-weight-bold mt-4">
                  Finance Contact Information
                </p>
                <div className="is-flex pt-4 ">
                  <table className="table_caf_fin wd_80">
                    <thead>
                      <td>Contact Person</td>
                      <td>Description/Department</td>
                      <td>Contact Number</td>
                      <td>Email</td>
                    </thead>
                    <tbody>
                      {taskStore.financeInformation?.map((r, i) => {
                        return (
                          <tr>
                            <td>
                              <input
                                className={`inp_table_caf is-size-6 ${errors1?.financeInformation?.obj[i]?.customercontact
                                  ? "input-danger"
                                  : ""
                                }`}
                                value={
                                  taskStore.financeInformation[i].customercontact
                                }
                                ref={inputRef}
                                onChange={(e) =>
                                  handleChange3(e, i, "customercontact")
                                }
                               ></input>
                            </td>
                            <td>
                              <input
                                className={`inp_table_caf is-size-6 ${errors1?.financeInformation?.obj[i]?.department
                                  ? "input-danger"
                                  : ""
                                }`}
                                value={taskStore.financeInformation[i].department}
                                ref={inputRef}
                                onChange={(e) =>
                                  handleChange3(e, i, "department")
                                }
                                ></input>
                            </td>
                            <td
                              style={{ borderRight: "none" }}
                              className={`is-flex wd_101`}>
                              <span className="contNumcodespn mt-1"></span>
                              <input
                                type="number"
                                className={`inp_table_caf is-size-6 ${errors1?.financeInformation?.obj[i]?.contactnum
                                  ? "input-danger"
                                  : ""
                                }`}
                                value={taskStore.financeInformation[i].contactnum}
                                ref={inputRef}
                                onChange={(e) =>
                                  handleChange3(e, i, "contactnum")
                                }
                                onKeyDown={(e) =>
                                  ["e", "E", "+", "-", "."].includes(e.key) &&
                                  e.preventDefault()
                                }
                                min={0}></input>
                            </td>
                            <td style={{ border: "1px solid black" }}>
                              <input
                                className={`inp_table_caf is-size-6 ${errors1?.financeInformation?.obj[i]?.email
                                  ? "input-danger"
                                  : ""
                                }`}
                                value={taskStore.financeInformation[i].email}
                                ref={inputRef}
                                onChange={(e) => handleChange3(e, i, "email")}
                                ></input>
                            </td>
                            {!v ? (
                              <>
                                <td>
                                  {showFinPlusIcon &&
                                    taskStore.financeInformation.length ==
                                    Math.abs(i + 1) ? (
                                    <span className=" icon is-medium">
                                      <BiPlus
                                        className="is-clickable fas fa-lg"
                                        style={{ color: "red" }}
                                        onClick={addNewFinancerow1}
                                      />
                                    </span>
                                  ) : (
                                    <span className=" icon is-medium"></span>
                                  )}
                                  <span className=" icon is-medium">
                                    <MdDelete
                                      className="is-clickable fas fa-lg"
                                      onClick={() => removeFinancerow1(i)}
                                    />
                                  </span>
                                </td>
                              </>
                            ) : null}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              {errors1.financeInformation?.error && (
                <p className="help is-input-danger-message">
                  {errors1.financeInformation.contactNumber ||
                    errors1.financeInformation.email
                    ? errors1.financeInformation.contactNumber ||
                    errors1.financeInformation.email
                    : "Please enter all details"}
                </p>
              )}
            </div>

            <p>
                <span>
                    <button style={{ margin: '10px', float: 'left' }} className="button islink" disabled={disableButton}
                        onClick={async (e) => {
                            task.currency = taskStore.currencyData
                            taskStore.current.stage = 1
                            taskStore.current.pendingWith = 'CUSTOMER'
                               const a =await dovalidationcheck1();
                               if(!a.isError){
                                taskStore.current.financeInformation = taskStore.financeInformation
                                setDisableButton(true)
                                await Taskapi.saveTask(taskStore.current)
                                taskStore.triggerActionVisible = false
                                taskStore.myActionVisible = false
                               }
                        }}>Trigger Action</button>
                </span>
            </p>
        </>
    )
}
