import { useEffect, useState } from "react"
import {  useSnapshot } from "valtio"
import Api from "../state/Api"
import { configStore, contractsStore, entityStore, loginStore, modalStore, enquiriesValidationStore, AgmodalStore } from "../state/CommonState"
import SchApi from "../state/sch-api"
import EnquiriesApi from "../state/enq-api"
import Agproducttab from "../components/Agproducttab"
import Utils from "../util/Utils"
import Newapi from "../state/new-api"
import ScheduleUtils from "../util/SchedulesUtils"
import QuotationUtils from "../util/quotationUtil"
import { useGlobalState } from "../GlobalState"
import { Cargo, Container, enquiryStore } from "../enquiry/enquiry-data"
import { BookingLocationsv3 } from "./booking-locations-v3"
import QuoteCalcV2 from "../util/quote-calc-v2"
import { loader } from "../util/loader"
import RoleUtils from "../util/RoleUtils";
import { ENTITY_STATUS, PERMISSIONS } from "../util/constants";
import { Button} from "@mui/material"
import EntityV2Api from "../state/entities-api"
import { DummySchedulev3 } from "./dummy-schedule-v3"
import AirScheduleCompv3 from "./AirScheduleComp-v3"
import CarrierWebFormv3 from "./CarrierWebForm-v3"
import LCLSchedulesv3 from "./LCLSchedules-v3"
import EnquiryConfirmationv3 from "./enquiry-confirmation-v3"
import { useForm } from "react-hook-form"
import { Route } from "../v3-componets/AgRoute"
import { Details } from "../v3-componets/AgDetails"
import Select from 'react-select'

const customStyles1 = {
    control: (provided) => ({
        ...provided,
        width:"100%",
        minHeight: '30px',
        border: '1px solid #ccc',
    }),

    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        display: 'none',
    }),
    option: (provided) => ({
        ...provided,
        background: '#fff',
        color: '#363636'
    }),
}


export default function HomeScreenLCLv3(props) {

    let client = loginStore.isClient()
    let isUserAuthorized = RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.CB_QUOTATION_CREATE_DUMMY)
    let isUserAuthorizedForSaveOnly = RoleUtils.isUserAuthorized(PERMISSIONS.EDIT.SAVE_UPLOAD_SCHEDULES_ONLY) 
    const { currentMenu } = useGlobalState();
    console.log('INSIDE HOMESCREEN LCL')
    useSnapshot(contractsStore)
    useSnapshot(enquiryStore)
    useSnapshot(configStore)
    useSnapshot(entityStore)
    useSnapshot(modalStore)
    const [show, setShow] = useState(false);
    const [listContract, setListContract] = useState([]);
    const [errorSchedules, setErrorSchedules] = useState([]);
    const [scacList, setScacList] = useState([]);
    const [selectscacList, setSelectScacList] = useState([]);
    const rec = contractsStore.current
    useEffect(()=>{
        loader.show()
        const callback = (data)=>{
            let k = new QuoteCalcV2(rec).recalc()
            if(JSON.stringify(k)!=JSON.stringify(rec)){
                contractsStore.current = k
            }
            loader.hide()
        }
        Api.getLiveExchangerates(callback)

        return()=>{
            enquiryStore.initialize();
            configStore.homescreenCurrentView = "list";
        }
    },[])

    useEffect(()=>{
        let k = JSON.parse(JSON.stringify(contractsStore.current))
        if(k.multicharge!=undefined && k.multicharge.length>0){
            let l = []
            let lc = []
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            for (let i = 0; i < k.multicharge.length; i++) {
                const d = new Date(k.multicharge[i]['validUpto']);
                if(d>=currentDate){
                    let k1 = JSON.parse(JSON.stringify(k))
                    let key = Object.keys(k.multicharge[i])
                    for (let j = 0; j < key.length; j++) {
                        k1[key[j]] = JSON.parse(JSON.stringify(k.multicharge[i][key[j]]))
                    }
                    delete k1.multicharge
                    l.push(k1)
                    if(activeProduct=='FCL' && k.multicharge[i]['hasToShowDummySchedule'] == false && k.multicharge[i]['quoteStatus']!="uploaded" && k.status != "SCHEDULES_UPLOADED"){
                        let carrier = k.multicharge[i]['carrier']
                        let scac = carrier.substring(carrier.lastIndexOf("(") + 1,carrier.lastIndexOf(")")).trim()
                        lc.push({ value: scac, label: scac })
                    }
                }
            }
            if(JSON.stringify(listContract)!=JSON.stringify(l)){
                setListContract(l)
            }
            if(activeProduct=='FCL'){
                if(lc.length==0){
                    configStore.scheduleOrCarrierform = 'schedule'
                    if(!configStore.displaySchedules) configStore.displaySchedules=true
                    if(contractsStore.current['quoteStatus']=='FINAL'){
                        contractsStore.current['quoteStatus'] = 'uploaded'
                    }
                }
                if(lc.length==0 && rec.status != "SCHEDULES_UPLOADED"){
                    setSaveEnabled(true)
                }
                if(JSON.stringify(lc)!=JSON.stringify(scacList)){
                    setScacList(lc)
                }
                if(JSON.stringify(lc)!=JSON.stringify(selectscacList)){
                    setSelectScacList(lc)
                }
            }
        }
    },[JSON.stringify(contractsStore.current)])






    console.log(contractsStore.current['quoteStatus'], "ddddddddddddddd")
    const [saveEnabled, setSaveEnabled] = useState(false)
    const [schedules, setSchedules] = useState([])
    const [fclSchedules, setFclSchedules] = useState([])
    const [activeProduct, setActiveProduct] = useState(configStore.homescreenCurrentShipmentType)
    let productList = ['LCL', 'FCL', 'Air']
    let e = enquiryStore.current
    if (loginStore.isClient() && contractsStore.current.isFromEnquiry && e.tsClientviewOn == undefined) {
        e.tsClientviewOn = Date.now()
        let id = contractsStore.current._id
        if (id.endsWith('C')) {
            id = id.substr(0, id.length - 1)
        }
        EnquiriesApi.updateToClientView(id)
    }

    // below line is a temporary fix to hide Air. To be removed.
    // productList = ['LCL', 'FCL']
    if (contractsStore.current['mode'] == 'CONTRACT' && contractsStore.current['quoteStatus'] != 'DECLINED' && contractsStore.current['quoteStatus'] != 'ACCEPTED') {
        contractsStore.current['quoteStatus'] = "ACCEPTED"
    }


    // let enquiryNum = configStore.enquiryModalNum

    const NmO_onClick = () => {
        let stat = EnquiriesApi.saveEnquiry()
        EnquiriesApi.getAllEnquiries()
        
        if (stat) {setActiveProduct("LCL")
        configStore.displaySchedules = false
        configStore.showEnquiries = true
        contractsStore.current = {}
        modalStore.checkingDestination = false
        configStore.homescreenCurrentShipmentType = "LCL"}
        
    }

    let enquiriesValidationStoreSnap = useSnapshot(enquiryStore.current)


    const onClick = () => {
        enquiriesValidationStore.cargoReadinessDate = enquiriesValidationStoreSnap.enqRoute.cargoReadinessDate.trim() === ''
        enquiriesValidationStore.origin = enquiriesValidationStoreSnap.enqRoute.origin.trim() === ''
        enquiriesValidationStore.destination = enquiriesValidationStoreSnap.enqRoute.destination.trim() === ''


        configStore.displaySchedules = true
        if (props.viewonly) {
            if (activeProduct.toLowerCase() === "fcl" && !enquiryStore.current.containerDetails.every(QuotationUtils.isValidContainer)) {
                configStore.setModalMessage("Invalid Container details.");
                return;
            }
            if (activeProduct.toLowerCase() !== "fcl" || (activeProduct.toLowerCase() === "fcl" && QuotationUtils.hasCargoDetail(enquiryStore.current.shipmentType, enquiryStore.current.shipmentScope))) {
                if (enquiryStore.current.cargoDetails.length === 0 || !enquiryStore.current.cargoDetails.every(cargo => QuotationUtils.isValidCargo(activeProduct, enquiryStore.current.shipmentScope, cargo))) {
                    console.log("🚀 ~ file: home-screen-lcl.js ~ line 170 ~ HomeScreenLCL ~ enquiryStore", enquiryStore.current.cargoDetails)
                    configStore.setModalMessage("Invalid Cargo details.");
                    return;
                }
            }

            contractsStore.current = enquiryStore.current.mergeIntoContract(rec)


        } else {
            Api.getMergedContract()
        }
    }

    useEffect(()=>{
        if(contractsStore.current.multicharge==undefined || contractsStore.current.multicharge.length==0){
            if(contractsStore.current && configStore.displaySchedules && ((activeProduct === 'LCL' && schedules.length==0) || (activeProduct != 'LCL' && fclSchedules.length==0)) && (contractsStore.current.hasToShowDummySchedule==undefined || contractsStore.current.hasToShowDummySchedule==false) && contractsStore.current.status=='SCHEDULES_UPLOADED'){
                contractsStore.current.hasToShowDummySchedule = true
            }
            if(contractsStore.current && configStore.displaySchedules && ((activeProduct === 'LCL' && schedules.length>0) || (activeProduct != 'LCL' && fclSchedules.length>0)) && contractsStore.current.hasToShowDummySchedule!=undefined && contractsStore.current.hasToShowDummySchedule!=false && contractsStore.current.status=='SCHEDULES_UPLOADED'){
                contractsStore.current.hasToShowDummySchedule = false
            }
        }
    },[fclSchedules,schedules])
    const cargoForm = useForm({
        defaultValues: {
            mode: "LCL",
            orginScope: false,
            destinationScope: false,
            originType: false,
            destinationType: false,
            showCargoDetailsOutGuage: false,
            showPalletLabel:false
        }
    })
    useEffect(()=>{loader.show()},[])
    useEffect(()=>{
        if(enquiryStore.current.enqRoute!=undefined && enquiryStore.current.enqRoute.origin != undefined && enquiryStore.current.enqRoute.origin.length>0){
            reset({
                mode: "LCL",
                orginScope: false,
                destinationScope: false,
                originType: false,
                destinationType: false,
                showCargoDetailsOutGuage: false,
                showPalletLabel:false
            })
            if(enquiryStore.current.shipmentType.length>3 && enquiryStore.current.shipmentType.toLowerCase().indexOf('fcl')!=-1){
                enquiryStore.current.shipmentType = 'FCL'
                setValue("mode",'FCL');
            }else{
                setValue("mode",enquiryStore.current.shipmentType);
            }
            setValue("multidest",enquiryStore.current.multidest)
            setValue("containerDetails",enquiryStore.current.containerDetails)
            setValue("fbaalways","Yes")
            setValue("origin",enquiryStore.current.enqRoute.origin)
            setValue("cargoreadinessdate",enquiryStore.current.enqRoute.cargoReadinessDate)
            setValue("scope",enquiryStore.current.shipmentScope)
            setValue("shipmentType",enquiryStore.current.shipmentType)
            setValue("customerreference",enquiryStore.current.enqRoute.customerReferenceNum)
            setValue("hazardous",enquiryStore.current.hazardous === "No" ? false :true)
            setValue("quoteClass",enquiryStore.current.quoteClass)
            setValue("packingGroup",enquiryStore.current.packingGroup)
            setValue("temperature",enquiryStore.current.temperature)
            setValue("cargoValue",enquiryStore.current.cargoValue)
            setValue("unnumber",enquiryStore.current.unnumber)
            setValue("nonStackable", enquiryStore.current.nonStackable === "No" ? false :true)
            setValue("temperatureControlled", enquiryStore.current.temperatureControlled === "No" ? false :true)
            setValue("minTemperature",enquiryStore.current.minTemperature)
            setValue("maxTemperature",enquiryStore.current.maxTemperature)
            setValue("stuffingType",enquiryStore.current.stuffingType)
            setValue("destuffingType",enquiryStore.current.destuffingType)
            setValue("status",enquiryStore.current.status)
            setValue("cargoDimensionUnit",enquiryStore.current.cargoDimensionUnit)
            setValue("enqRoute",enquiryStore.current.enqRoute)
            setValue("vehicledetails",enquiryStore.current.vehicles)
            setValue("isFba",enquiryStore.current.fba === "Yes" ? true :false)
            setValue("ior",enquiryStore.current.fbaIorRequired === "Yes" ? true :false)
            setValue("occ",enquiryStore.current.fbaOCC === "Yes" ? true :false)
            setValue("dcc",enquiryStore.current.fbaDCC === "Yes" ? true :false)
            setValue("survey",enquiryStore.current.fbaSurvey === "Yes" ? true :false)
            setValue("storageAtDestination",enquiryStore.current.fbaSAD === "Yes" ? true :false)
            setValue("palletizationandlabelling",enquiryStore.current.fbaPAL === "Yes" ? true :false)
            setValue("days",enquiryStore.current.fbaDays!=undefined?enquiryStore.current.fbaDays:0)
            setValue("pick_up_by_amazon",enquiryStore.current.pick_up_by_amazon === "Yes" ? true :false)
            // setValue("multidest",enquiryStore.current.multidest)
            setShow(true)
            loader.hide()
        }
    },[enquiryStore.current])
    const { control, watch, register, setValue, handleSubmit, reset, clearErrors, formState, trigger, getFieldState } = cargoForm;
    const updatecusref = (e) =>{
        if(contractsStore.current['customerReferenceNum']!=e){
            contractsStore.current['customerReferenceNum'] = e
        }
    }
    useEffect(() => {
        if (!configStore.displaySchedules) configStore.showEnquiries = true
        if (props.viewonly) {
            if (!configStore.displaySchedules) configStore.displaySchedules = true
        }
        if(schedules.length==0 && fclSchedules.length==0){
            if (props.viewonly || !props.viewonly) {
                const callbackcheck = async (data)=>{
                    let d = []
                    for (let i = 0; i < data.length; i++) {
                        if(ScheduleUtils.isValidSchedule(activeProduct.toLowerCase(), data[i], contractsStore.current)){
                            d.push(data[i])
                        }
                    }
                    if(d.length>0){
                        if(activeProduct === 'LCL'){
                            setSchedules(d)
                        }else{
                            setFclSchedules(d)
                        }
                        if(rec.status === "UPLOAD_SCHEDULES"){
                            SchApi.saveSchedules(rec._id, d)
                            await Newapi.releaseContractLock(rec._id)
                            rec.status = "SCHEDULES_UPLOADED"
                            setSaveEnabled(false)
                            configStore.scheduleOrCarrierform = 'schedule'
                        }
                    }
                }
                (activeProduct === 'LCL') ?
                    SchApi.getSchedules(rec._id, callbackcheck) :
                    SchApi.getSchedules(rec._id, callbackcheck)
            }
            if (currentMenu == "Create Bookingv3" || currentMenu == "Upload Schedulesv3") {
                if (contractsStore.current._id) {
                    let reqdata = {}
                    let shipmentType = configStore.homescreenCurrentShipmentType.toUpperCase()
    
                    if (configStore.homescreenCurrentShipmentType == 'LCL') {
                        let currentDate = new Date()
                        let cargoReadinessDate = (contractsStore.current.cargoReadinessDate && contractsStore.current.cargoReadinessDate.length >= 10) ?
                            new Date(contractsStore.current.cargoReadinessDate) : currentDate
                        if (cargoReadinessDate < currentDate) cargoReadinessDate = currentDate
                        if (isNaN(cargoReadinessDate)) cargoReadinessDate = currentDate
                        let startDays = (contractsStore.current.portOfLoading === contractsStore.current.portOfReceipt) ? 2 : 8 // the logic in mail is >= 4/13, equivalent to this > 3/12
                        let startDate = Utils.mmddyyyy(Utils.addDays(cargoReadinessDate, startDays))
                        let endDate = Utils.mmddyyyy(Utils.newDate(contractsStore.current.validUpto))
                        reqdata = {
                            shipmentType: shipmentType,
                            startDate: startDate,
                            endDate: endDate
                        }
                    }
                    if (configStore.homescreenCurrentShipmentType == 'FCL') {
                        reqdata = {
                            shipmentType: shipmentType
                        }
                    }
                    if (shipmentType == 'LCL' || shipmentType == 'FCL') {
                        Api.getSchedulesList(contractsStore.current._id, reqdata, (data) => {
                            if (data.length > 0) {
                                if (shipmentType == 'LCL' && (schedules.join('') != data.join(''))) {
                                    setSchedules(data)
                                }
                                if (shipmentType == 'FCL') {
                                    setFclSchedules(data)
                                }
                            }
                        })
                    }
                }
            }
        }
    }, [rec._id], e.stuffingType, e.destuffingType)
    //console.log('>>>>>>>>>>>', rec, props.viewonly)
    if (!rec._id && props.viewonly) return <></>
    let contractId = rec._id
    if (rec.mode === 'QUOTE') contractId = contractId.substr(0, contractId.length - 1)
    let title = `Create Booking - ${contractId}`
    if (!props.viewonly) title = 'Enquiries'

    const parent = "agi4"
    var parent_container
    var newHandle = function (e) {
        let parent_container1 = null
        if (parent) {
            parent_container = e.target;
            do {
                parent_container = parent_container?.parentNode;

                if (!parent_container || parent_container === document.body) {
                    break;
                }

                if (parent_container.id != parent) {
                    parent_container1 = null;
                } else {
                    parent_container1 = parent_container;
                }
            } while (parent_container1 == null);

        }
    };
    var newHandle1 = function (e) {
        if (parent && parent_container) {
            let i = parent_container.getElementsByTagName('input')
            i[0].files = e.clipboardData.files
            if (e.clipboardData.files.length > 0) {
                change(i[0].files)
            }
        }
    };
    if (parent) {
        document.getElementsByTagName('body')[0].onclick = function (e) { newHandle(e) }
        document.getElementsByTagName('body')[0].onpaste = function (e) { newHandle1(e) }

    }

    function change(files) {
        contractsStore.current.hasToShowDummySchedule = false;
        const formData = new FormData()
        formData.append('myFile', files[0])
        formData.append('action', 'schedule_upload')
        formData.append('shipmentType', rec.shipmentType)
        formData.append('id', rec._id)
        fetch(`${process.env.REACT_APP_API_SERVER}/api/v1/common/convertxltojson`, {
            method: 'POST',
            headers: Api.H1(),
            body: formData
        })
            .then(response => response.json())
            .then(data => {
                console.log('got data', data)
                if (data.error) { if (data.error != 'No Error' || data.error != 'Invalid Token') { 
                    configStore.setModalMessage(data.error) } }
                else {
                    if(data.length>0){
                        let keys = Object.keys(data[0])
                        let l = []
                        for (let i = 0; i < data.length; i++) {
                            let k = false
                            for (let j = 0; j < keys.length; j++) {
                                if(data[i][keys[j]].trim().length>0){
                                    k = true
                                }
                                j = keys.length+1
                            }
                            if(k){
                                l.push(data[i])
                            }
                        }
                        if(l.length>0){
                            data = l
                            let d = []
                            let ed = []
                            for (let i = 0; i < data.length; i++) {
                                if(activeProduct.toLowerCase()=='lcl'){
                                    if(ScheduleUtils.isValidSchedule(activeProduct.toLowerCase(), data[i], contractsStore.current)){
                                        d.push(data[i])
                                    }
                                }else{
                                    if(ScheduleUtils.isValidSchedule('uploadfcl', data[i], contractsStore.current)){
                                        d.push(data[i])
                                    }else{
                                        ed.push(data[i])
                                    }
                                }
                            }
                            if(activeProduct === 'LCL'){
                                if(d.length>0){
                                    contractsStore.current['quoteStatus'] = "uploaded"
                                    setSaveEnabled(true)
                                }else{
                                    setSchedules(data)
                                }
                            }else{
                                if(ed.length==0){
                                    if(JSON.stringify(ed) != JSON.stringify(errorSchedules)){
                                        setErrorSchedules(ed)
                                    }
                                    if((contractsStore.current.multicharge==undefined || contractsStore.current.multicharge.length==0)){
                                        setFclSchedules(d)
                                        contractsStore.current['quoteStatus'] = "uploaded"
                                        setSaveEnabled(true)
                                    }else{
                                        let k = JSON.parse(JSON.stringify(contractsStore.current))
                                        for (let i = 0; i < k.multicharge.length; i++) {
                                            let carrier = k.multicharge[i].carrier
                                            carrier = carrier.substring(carrier.lastIndexOf("(") + 1,carrier.lastIndexOf(")")).trim()
                                            for (let j = 0; j < d.length; j++) {
                                                if(carrier==d[j]['cscac']){
                                                    k.multicharge[i]['quoteStatus'] = "uploaded"
                                                    k.multicharge[i]['hasToShowDummySchedule'] = false
                                                }
                                            }
                                        }
                                        setFclSchedules(d)
                                        contractsStore.current = JSON.parse(JSON.stringify(k))
                                    }
                                }else{
                                    if(JSON.stringify(data) != JSON.stringify(errorSchedules)){
                                        setErrorSchedules(data)
                                    }
                                }
                            }
                        }
                    }
                    document.getElementById(parent).getElementsByTagName('input')[0].value = ""
                }
            })
            .catch(error => {
                console.error(error)
            })
    }

    const scheduleerror = (schedule,contract) =>{
        if (!schedule['POR Code']) schedule['POR Code'] = schedule['ocode']
            const rec = contract
            const check = (rec,schedule,validUpto,carrier) => {
                let l = []
              let m = [
                'POR Code',
                "oname", 
                "ocode", 
                "oetd", 
                "r1code", 
                "v1name", 
                "s1voy", 
                "dname", 
                "dcode", 
                'deta',
                "cscac" 
                ]
              for (let i = 0; i < m.length; i++) {
                if(schedule[m[i]].trim().length==0){
                l.push("Required "+m[i])
                }
              }
              let scac = carrier.substring(carrier.lastIndexOf("(") + 1,carrier.lastIndexOf(")")).trim()
              if(scac.toLowerCase() != schedule['cscac'].toLowerCase()) l.push("carrier not mached")
              let etd = schedule['oetd']
              let etdDate = Utils.newDate(etd)
              let porCode = schedule['POR Code']
              let polCode = schedule['ocode']
              let startDate = Utils.newDate(rec.mailedOn)
              startDate = Utils.addDays(startDate, 3)
              let currentDate = new Date()
              let cargoReadinessDate = (rec.cargoReadinessDate && rec.cargoReadinessDate.length >= 10) ?
                new Date(rec.cargoReadinessDate) : currentDate
              if (cargoReadinessDate < currentDate) cargoReadinessDate = currentDate
              if (isNaN(cargoReadinessDate)) cargoReadinessDate = currentDate
              let startDays = (porCode === polCode) ? 2 : 8 // the logic in mail is >= 4/13, equivalent to this > 3/12
              startDate = Utils.addDays(cargoReadinessDate, startDays)
              if (etdDate < startDate  ){
                l.push(`POR = ${porCode}, POL = ${polCode}, CRD = ${cargoReadinessDate.toISOString().substring(0, 10)}`)
                l.push(`ETD = ${etdDate.toISOString().substring(0, 10)}`)
                l.push(`Start Date as calculated = ${startDate.toISOString().substring(0, 10)}`)
                l.push(`CONDITION FAILED = ETD less than Start`)
              }
              let endDate = Utils.newDate(validUpto)
              if (etdDate > endDate  ){
                if(l.indexOf(`POR = ${porCode}, POL = ${polCode}, CRD = ${cargoReadinessDate.toISOString().substring(0, 10)}`)==-1){
                    l.push(`POR = ${porCode}, POL = ${polCode}, CRD = ${cargoReadinessDate.toISOString().substring(0, 10)}`)
                }
                if(l.indexOf(`ETD = ${etdDate.toISOString().substring(0, 10)}`)==-1){
                    l.push(`ETD = ${etdDate.toISOString().substring(0, 10)}`)
                }
                l.push(`Start Date as calculated = ${endDate.toISOString().substring(0, 10)}`)
                l.push(`CONDITION FAILED = ETD greater than END`)
              }
              return l;
            }
            if(rec.multicharge==undefined || rec.multicharge.length==0){
              return check(rec,schedule,rec.validUpto,rec.carrier)
            }else{
                
              let ch = []
              for (let i = 0; i < rec.multicharge.length; i++) {
                let s = check(rec,schedule,rec.multicharge[i].validUpto,rec.multicharge[i].carrier)
                if(s.length==0){
                  i = rec.multicharge.length+1
                }else{
                    ch.push("____________________________________________")
                    ch.push(rec.multicharge[i].carrier)
                    ch = ch.concat(s)
                    ch.push("____________________________________________")
                }
              }
              return ch
            }
    }

    const getschedule = (e)=>{
        let l = []
        let carrier = e.carrier
        carrier = carrier.substring(carrier.lastIndexOf("(") + 1,carrier.lastIndexOf(")")).trim()
        let d = fclSchedules
        for (let j = 0; j < d.length; j++) {
            if(carrier==d[j]['cscac']){
                l.push(d[j])
            }
        }
        return l
    }

    console.log(activeProduct, 'activeProduct');
    
    return (
        <>
        {
            show && <>

            <EnquiryConfirmationv3 />
            <div style={{display:"flex",flexDirection:"row",gap:1,alignItems:"center"}}>
            <h5 className="title is-4" style={{ color: '#2c358a', margin: '0 0 15px 15px' , fontFamily: 'product_sans_bold' }}>
                {title}
            </h5>
            </div>
            {
                (!props.viewonly) ?
                    <Agproducttab productList={productList}
                        activeProduct={activeProduct} setActiveProduct={setActiveProduct} />
                    : <></>
            }
            <hr style={{marginTop:"0px"}} />
            <div className="columns">
                <div className="column is-12 m-0 p-0">
                <div className="w-full flex flex-col gap-[8px]">
       <form noValidate  style={{width:"100%",display:"flex",flexDirection:"column"}}>
       <div className="flex flex-row gap-[10px]">
          
       <Route form={cargoForm} viewonly={props.viewonly=='1'} callback={updatecusref}/>
       <Details form={cargoForm} viewonly={props.viewonly=='1'}/> 
    
       </div>
       </form>
       </div>
                </div>
                
                
            </div>
            <hr />
            {
                (!loginStore.isClient() && ['lcl', 'fcl'].includes(activeProduct.toLowerCase())) ?
                    <div className="columns">
                        <div className="column is-2">
                        </div>
                        <div className="column is-10">
                            <BookingLocationsv3 />
                        </div>
                    </div> : <></>
            }
            <div className="columns">
                <div className="column is-12">
                    {
                        (props.viewonly && loginStore.isClient()) ?
                            <button style={{ float: 'left' }}
                                className="button is-rounded is-small islink" onClick={() => {
                                    let customTC = []
                                    if (rec['customTC']) {
                                        customTC = rec['customTC'].split(/\r?\n/)
                                    }
                                    rec['tc'].map(e => customTC.push(e))
                                    configStore.setModalMessage("", [], false, customTC)
                                }}>Terms and Conditions</button> :
                            <></>


                    }
                    {
                        (props.viewonly && rec.quoteStatus != 'ACCEPTED' && configStore.displaySchedules && rec.quoteStatus != 'DECLINED' && (new Date(rec.validUpto + " 23:59:00").getTime() > new Date().getTime()) && (isUserAuthorized || client) && (contractsStore.current['quoteStatus'] === "saved"  || contractsStore.current?.hasToShowDummySchedule || configStore.homescreenCurrentShipmentType === 'Air' || (schedules.length>0 || fclSchedules.length>0)) && !saveEnabled && rec.status == "SCHEDULES_UPLOADED") ?
                            <button style={{ float: 'right', marginRight: "20px" }}
                                className="button is-rounded is-small islink is-danger" onClick={() => {
                                    EntityV2Api.viewEntity(rec.entityId , (data)=> {
                            if(data.status === ENTITY_STATUS.DEACTIVATED || data.status === ENTITY_STATUS.PENDING){
                            configStore.setModalMessage(`Entity ${data.entityName} is Deactivated , please Activate it for Declining the quotation `)
                            }else {
                                configStore.setModalDecline(rec._id)
                            }  
                                 } )
                                  
                                }}>Decline</button> :
                            <></>


                    }
                    {
                        (props.viewonly && rec.quoteStatus != 'ACCEPTED' && configStore.displaySchedules && rec.quoteStatus != 'DECLINED' && (new Date(rec.validUpto + " 23:59:00").getTime() > new Date().getTime()) && (isUserAuthorized || client) && ((contractsStore.current['quoteStatus'] === "saved"  || contractsStore.current?.hasToShowDummySchedule) || (configStore.homescreenCurrentShipmentType === 'Air' && contractsStore.current['quoteStatus'] === "FINAL") || (schedules.length>0 || fclSchedules.length>0)) && !saveEnabled && rec.status == "SCHEDULES_UPLOADED") ?
                            <button style={{ float: 'right', marginRight: "20px" }}
                                className="button is-rounded is-small islink is-success" onClick={() => {
                                    console.log('fvgf', rec)
                                    EntityV2Api.viewEntity(rec.entityId , (data)=> {
                                        if(data.status === ENTITY_STATUS.DEACTIVATED){
                            configStore.setModalMessage(`Entity ${data.entityName} is Deactivated , please Activate it for Accepting the quotation`)
                            }else {   
                                    Api.createContractFromQuote(rec._id)
                                    let k = JSON.parse(JSON.stringify(contractsStore.current))
                                    k['quoteStatus'] = "ACCEPTED"
                                    if(k.multicharge!=undefined && k.multicharge.length>0){
                                        for (let i = 0; i < k.multicharge.length; i++) {
                                            k.multicharge[i]['quoteStatus'] = "ACCEPTED"
                                        }
                                    }
                                    contractsStore.current = JSON.parse(JSON.stringify(k))
                                    configStore.setModalMessage("Quotation is accepted.")

                            }
                                    }
                                    )
                                }}>Accept</button> :
                            <></>
                    }

                    {/* {
                        (!props.viewonly && displayMoreOptions && enquiriesValidationStoreSnap.enqRoute.cargoReadinessDate && enquiriesValidationStoreSnap.enqRoute.origin && enquiriesValidationStoreSnap.enqRoute.destination) ?
                            <button style={{ float: 'right' }} onClick={() => {
                                NmO_onClick()
                            }}
                                className="button is-small is-rounded islink">
                                Need more Options
                            </button> :
                            <></>

                    } */}

                    {
                        (!props.viewonly) ?
                            <button
                                style={{ float: 'right' }}
                                className="button is-rounded is-small islink"
                                onClick={() => {
                                    // if (configStore.addressvalidation) {
                                    onClick();
                                    if (enquiriesValidationStoreSnap.enqRoute.cargoReadinessDate && enquiriesValidationStoreSnap.enqRoute.origin && enquiriesValidationStoreSnap.enqRoute.destination) {
                                        NmO_onClick()
                                    }
                                    // } else {
                                    //     configStore.setModalMessage('Please check input fields');

                                    // };

                                }}
                            >
                                Submit
                            </button> :
                            <>
                                {/* {
                                    (!configStore.displaySchedules) ?
                                        <button style={{ float: 'right' }}
                                            className="button is-rounded is-small islink" onClick={() => {
                                                onClick()
                                            }}>Re-calulate</button> :
                                        <></>
                                } */}
                            </>
                    }

                </div>
            </div >
            {
                (loginStore.userRec.aguserRec.role.indexOf('CLIENT') < 0 && 
                    rec.status === "UPLOAD_SCHEDULES" &&
                    configStore.homescreenCurrentShipmentType !== 'Air'
                    && configStore.scheduleOrCarrierform === 'schedule') ?
                    <div className="columns" style={{ border: '2px solid blue' }} id="agi4">
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }} id="agi4">
                            <label style={{ float: 'right' }} for="schedulefile">Load Schedules, verify and Save</label>
                            {(contractsStore.current['quoteStatus'] === "FINAL") ?
                            <input id="schedulefile" type="file" accept=".csv" onChange={(e1) => {
                                const pattern = /^\S[\s\S]*\S$|^\S$/;
                                if (pattern.test(e1.target.files[0]['name'])) {
                                    change(e1.target.files)
                                } else {
                                    contractsStore.current['quoteStatus'] = ""
                                    configStore.setModalMessage("Please Check File Name and Format")
                                    return e1.target.value = ""
                                }
                            }} />:<></>
                        }
                            {
                                (activeProduct === 'LCL' ?
                                    <><a style={{ fontSize: "12px", textDecoration: "underline" }} className="is-link" href="./LCL_Template.csv" download="LCL_Template.csv">Download LCL Template</a></> :

                                    <><a style={{ fontSize: "12px", textDecoration: "underline" }} className="is-link" href="./FCL_Template.csv" download="FCL_Template.csv">Download FCL Template</a></>)
                            }
                            {
                                (contractsStore.current.multicharge==undefined || contractsStore.current.multicharge.length==0)?<>
                                {
                                ((activeProduct === 'LCL' ? schedules : fclSchedules)
                                    .every(schedule => !ScheduleUtils.isValidSchedule(activeProduct.toLowerCase(), schedule, contractsStore.current)) &&
                                    !contractsStore.current?.hasToShowDummySchedule &&
                                    contractsStore.current.validUpto >= Utils.getCurrentDateString() && (isUserAuthorized || isUserAuthorizedForSaveOnly || client)) && contractsStore.current['quoteStatus'] === "FINAL"
                                && <button className="button is-info is-small" onClick={() => {
                                    setSaveEnabled(true)
                                    contractsStore.current['quoteStatus'] = "dummy"
                                    setSchedules([]);
                                    setFclSchedules([]);
                                    contractsStore.current.hasToShowDummySchedule = true;
                                }}>Create Dummy</button>
                            }
                            {
                                (saveEnabled && (isUserAuthorized || client) && (contractsStore.current['quoteStatus'] === "dummy" || contractsStore.current['quoteStatus'] === "uploaded")) ?
                                    <button className="button is-danger is-small" onClick={async () => {
                                        loader.show();
                                        (activeProduct === 'LCL') ?
                                            SchApi.saveSchedules(rec._id, schedules) :
                                            SchApi.saveSchedules(rec._id, fclSchedules)
                                        await Newapi.releaseContractLock(rec._id)
                                        rec.status = "SCHEDULES_UPLOADED"
                                        // contractsStore.current['quoteStatus'] = "saved"
                                        setSaveEnabled(false)
                                        // configStore.homescreenCurrentView = 'list'
                                        configStore.scheduleOrCarrierform = 'schedule'
                                        loader.hide();
                                    }}>
                                        Save Schedules
                                    </button>
                                    : <></>
                            }
                                </>:<>
                                {
                                    (selectscacList.length>0 && activeProduct=="FCL" && (isUserAuthorized || isUserAuthorizedForSaveOnly || client))?<>
                                    <Select
                            value={selectscacList}
                            options={scacList}
                            onChange={(e) => {
                                if(e.length!=0){
                                    setSelectScacList(e)
                                }
                            }}
                            isSearchable={true}
                            isClearable={selectscacList.length==1?false:true}
                            placeholder={null}
                            isMulti={true}
                            styles={customStyles1}
                        />
                        <button className="button is-info is-small" onClick={() => {
                            let k = JSON.parse(JSON.stringify(contractsStore.current))
                            for (let i = 0; i < k.multicharge.length; i++) {
                                let carrier = k.multicharge[i].carrier
                                carrier = carrier.substring(carrier.lastIndexOf("(") + 1,carrier.lastIndexOf(")")).trim()
                                let d = selectscacList
                                for (let j = 0; j < d.length; j++) {
                                    if(carrier==d[j]['value']){
                                        k.multicharge[i]['quoteStatus'] = "dummy"
                                        k.multicharge[i]['hasToShowDummySchedule'] = true
                                    }
                                }
                            }
                            contractsStore.current = JSON.parse(JSON.stringify(k))
                                }}>Create Dummy</button>
                                    </>:""
                                }
                            {
                                (selectscacList.length == 0 && saveEnabled && (isUserAuthorized || client))?
                                <button className="button is-danger is-small" onClick={async () => {
                                    loader.show();
                                    rec.status = "SCHEDULES_UPLOADED"
                                    SchApi.savemultiSchedules(rec._id,fclSchedules,rec)
                                    await Newapi.releaseContractLock(rec._id)
                                    setSaveEnabled(false)
                                    configStore.scheduleOrCarrierform = 'schedule'
                                    loader.hide();
                                }}>
                                    Save Schedules
                                </button>:""
                            }
                                
                                </>
                            }
                        </div>
                    </div> : <></>
            }
            {
                (errorSchedules.length==0)?<>
                {
                                (contractsStore.current.multicharge==undefined || contractsStore.current.multicharge.length==0)?<>
                                 {
                (configStore.displaySchedules) ?
                    <div className="columns">
                        <div className="column is-12">
                            {
                                (() => {
                                    if (configStore.scheduleOrCarrierform === 'schedule') {
                                        if (contractsStore.current?.hasToShowDummySchedule && contractsStore.current.validUpto >= Utils.getCurrentDateString()) return <DummySchedulev3 stype={activeProduct} />;
                                        if (configStore.homescreenCurrentShipmentType === 'Air' && contractsStore.current.validUpto >= Utils.getCurrentDateString()) return <AirScheduleCompv3 />;
                                        else return <LCLSchedulesv3 data={activeProduct === 'LCL' ? schedules : fclSchedules} />;
                                    } else return <CarrierWebFormv3 />;
                                })()
                            }
                        </div>
                    </div>
                    : <></>
            }
                                </>:<>
                                {
                (configStore.displaySchedules) ?
                    <div className="columns">
                        <div className="column is-12">
                            {
                                (() => {
                                    if (configStore.scheduleOrCarrierform === 'schedule') {
                                        return <>
                                        {
                                            listContract.map((e)=>{
                                                if (e?.hasToShowDummySchedule && e.validUpto >= Utils.getCurrentDateString()) return <><hr/><DummySchedulev3 stype={activeProduct} dummyContract={e}/></>;
                                                if (configStore.homescreenCurrentShipmentType === 'Air' && e.validUpto >= Utils.getCurrentDateString()) return <><hr/><AirScheduleCompv3 dummyContract={e}/></>;
                                                else return <>{getschedule(e).length!=0?<>{(getschedule(e).length>0)?<hr/>:""}<LCLSchedulesv3 data={getschedule(e)} dummyContract={e}/></>:""}</>;
                                            })
                                        }
                                        </>
                                    } else return <CarrierWebFormv3 />;
                                })()
                            }
                        </div>
                    </div>
                    : <></>
            }
                                </>
                    }
               
                </>:<>
                {
                    errorSchedules.map((e,i)=>{
                        let s = scheduleerror(e,contractsStore.current)
                        if(s.length!=0){
                            return <>
                            <div>
                                <p>Schedule - {i+1}</p>
                                {
                                    s.map(e1=>{
                                        return <p>{e1}</p>
                                    })
                                }
                            </div>
                            <hr />
                            </>
                        }
                    })
                }
                </>
            }
         </>
        }
        </>

    )
}